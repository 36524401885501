import React from "react";
import { Link } from "react-router-dom";

import "./MTradersTable.scss";

export const MTradersTable = props => {
    const { sortMTable, sortedByMTable, m_traders, checkStatus, location } = props;
    return (
        <div className="m_traders_table">
            <div className="table_container transactions_columns">
                <div className="table_header">
                    <div className="table_row">
                        <div className="row_item" onClick={sortMTable}>
                            trader <span className={sortedByMTable === "-trader" ? "up" : "down"} />
                        </div>
                    </div>
                </div>
                <div className="tab_all">
                    <div className="table_body">
                        {m_traders.map((el, idx) => {
                            return (
                                <div className="table_row" key={idx}>
                                    <div className="row_item ">
                                        <Link
                                            to={{
                                                pathname: `/main/m-trader/${el.id}`,
                                                state: { previousQueries: location.search }
                                            }}
                                            className="status"
                                        >
                                            <span className={checkStatus(el.status)} />
                                            {el.name}
                                        </Link>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};
