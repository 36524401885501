import React, { Component, Fragment } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import Head from "../../components/Head/Head";

import { getTags } from "../../actions/trkActions";

import Dashboard from "../../components/Pages/Dashboard/Dashboard";
import DashboardTrader from "../../components/Pages/Dashboard/DashboardTrader/DashboardTrader";
import Split from "../../components/Pages/Split/Split";
import SplitTrader from "../../components/Pages/Split/SplitTrader/SplitTrader";
import IP from "../../components/Pages/IP/IP";
import IPTrader from "../../components/Pages/IP/IPTrkTrader/IPTrkTrader";
import M from "../../components/Pages/M/M";
import MTrKTrader from "../../components/Pages/M/MTrkTrader/MTrkTrader";
import CreateMTrader from "../../components/Pages/M/CreateMTrader/CreateMTrader";
import MTrader from "../../components/Pages/M/MTrader/MTrader";
import Servers from "../../components/Pages/Servers/Servers";
import ItemTrader from "../../components/ItemTrader/ItemTrader";
import CreateTrader from "../../components/Pages/IP/CreateTrader/CreateTrader";

import WorkInProgressImage from "../../assets/image/work_in_progress.png";

class Container extends Component {
    componentDidMount() {
        const { getTags } = this.props;
        getTags();
    }

    renderWorkInProgress = () => (
        <div className="content_block">
            <img src={WorkInProgressImage} alt="work in progress" />
        </div>
    );

    render() {
        const { match, history, tags } = this.props;
        if (!localStorage.token) return <Redirect to="/auth" />;
        if (!tags.length) return null;
        return (
            <Fragment>
                <Head history={history} match={match} />
                <Switch>
                    <Route path={match.url} exact render={() => <Redirect to="/main/dashboard" />} />

                    <Route path={`${match.url}/dashboard`} component={Dashboard} />
                    <Route path={`${match.url}/dashboard-trader/:id`} component={DashboardTrader} />

                    <Route path={`${match.url}/split`} component={Split} />
                    <Route path={`${match.url}/split-trader/:id`} component={SplitTrader} />

                    <Route path={`${match.url}/ip`} component={IP} />
                    <Route path={`${match.url}/ip-trk-trader/:id`} component={IPTrader} />

                    <Route path={`${match.url}/m`} component={M} />
                    <Route path={`${match.url}/m-trk-trader/:id`} component={MTrKTrader} />
                    <Route path={`${match.url}/m-trader/:id`} component={MTrader} />
                    <Route path={`${match.url}/create-m-trader`} component={CreateMTrader} />

                    <Route path={`${match.url}/servers`} exact component={Servers} />

                    <Route path={`${match.url}/item-trader/:id`} component={ItemTrader} />
                    <Route path={`${match.url}/create-trader`} component={CreateTrader} />

                    <Route
                        render={() => (
                            <div className="content_block">
                                <p>Not found</p>
                            </div>
                        )}
                    />
                </Switch>
            </Fragment>
        );
    }
}

const mapStateToProps = ({ trk }) => ({
    tags: trk.tags
});

const mapDispatchToProps = {
    getTags
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
