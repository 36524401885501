import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

import { getOptions } from "../../../../actions/traderAction";
import { getMTrader, patchMTrader, startMTrader, stopMTrader } from "../../../../actions/mActions";
import Loader from "../../../HelperComponents/Loader/Loader";
import { translateCondition } from "../../../HelperComponents/functions";

import { validate } from "../CreateMTrader/validate";
import { Fields } from "../../../MTraderBlocks/Fields";
import { Quadrants } from "../../../MTraderBlocks/Quadrants/Quadrants";
import { Rules } from "../../../MTraderBlocks/Rules/Rules";

import arrow from "../../../../assets/image/arrow.png";
import back_arrow from "../../../../assets/image/back_arrow.png";

import "./MTrader.scss";

class MTrader extends Component {
    state = {
        isEdit: false,
        symbol: [],
        account: [],
        side: [
            {
                label: "buy",
                value: "buy"
            },
            {
                label: "sell",
                value: "sell"
            },
            {
                label: "exit",
                value: "exit"
            }
        ],
        signal_a_check: true,
        signal_c_check: true,
        quadrants: [],
        currentQuadrant: {},
        editQuadrant: false,
        deleteQuadrant: false,
        unableToDeleteQuadrant: false,
        rules: [],
        currentRule: {},
        editRule: false,
        deleteRule: false,
        loading: true
    };

    componentDidMount() {
        const {
            getOptions,
            getMTrader,
            match: {
                params: { id }
            }
        } = this.props;
        getMTrader(id).then(response => {
            document.title = `${response.payload.data.name}`;
            getOptions().then(res => {
                if (res.payload && res.payload.status && res.payload.status === 200) {
                    let symbol = [],
                        account = [];
                    res.payload.data.symbols.forEach(el => symbol.push({ label: el.label, value: el.value }));
                    res.payload.data.m_trk_accounts.forEach(el => account.push({ label: el.label, value: el.value }));
                    this.setState({
                        symbol,
                        account,
                        loading: false,
                        rules: response.payload.data.rules_data,
                        quadrants: response.payload.data.quadrants_data,
                        signal_a_check: !response.payload.data.signal_a,
                        signal_c_check: !response.payload.data.signal_c
                    });
                }
            });
        });
    }

    handleChangeRadio = e => {
        const {
            m_trader_info: { id },
            startMTrader,
            stopMTrader
        } = this.props;
        let value = e.target.value;
        if (value === "off") {
            startMTrader(id);
        } else {
            stopMTrader(id);
        }
    };

    changeSignalACheck = e => this.setState({ signal_a_check: e.target.value !== "On" });

    changeSignalCCheck = e => this.setState({ signal_c_check: e.target.value !== "On" });

    addQuadrant = quadrant => {
        this.setState(({ quadrants }) => ({
            quadrants: [
                ...quadrants,
                { index: quadrants.length ? quadrants[quadrants.length - 1].index + 1 : 1, ...quadrant }
            ]
        }));
    };

    handleQuadrantEditing = quadrant => {
        const { currentQuadrant, quadrants } = this.state;
        if (quadrant) {
            this.setState({
                quadrants: quadrants.map(el => {
                    if (el["index"] === currentQuadrant["index"]) {
                        return { ...quadrant, index: el["index"] };
                    } else {
                        return el;
                    }
                }),
                editQuadrant: false,
                currentQuadrant: {}
            });
        } else {
            this.setState({ editQuadrant: false, currentQuadrant: {} });
        }
    };

    handleQuadrantDeleting = quadrant => {
        const { quadrants } = this.state;
        if (quadrant) {
            this.setState({
                quadrants: quadrants.filter(el => el["index"] !== quadrant["index"]),
                deleteQuadrant: false,
                currentQuadrant: {}
            });
        } else {
            this.setState({ deleteQuadrant: false, currentQuadrant: {} });
        }
    };

    handleQuadrantUnableToDelete = () => this.setState({ unableToDeleteQuadrant: false, currentQuadrant: {} });

    addRule = rule => {
        this.setState(({ rules }) => ({
            rules: [...rules, rule]
        }));
    };

    handleRuleEditing = rule => {
        const { currentRule, rules } = this.state;
        if (rule) {
            this.setState({
                rules: rules.map((el, key) => {
                    if (key + 1 === currentRule["key"]) {
                        return rule;
                    } else {
                        return el;
                    }
                }),
                editRule: false,
                currentRule: {}
            });
        } else {
            this.setState({ editRule: false, currentRule: {} });
        }
    };

    handleRuleDeleting = rule => {
        const { rules } = this.state;
        let tempRules = [...rules];
        tempRules.splice(rule["key"] - 1, 1);
        if (rules) {
            this.setState({
                rules: tempRules,
                deleteRule: false,
                currentRule: {}
            });
        } else {
            this.setState({ deleteRule: false, currentRule: {} });
        }
    };

    submitForm = data => {
        const {
            patchMTrader,
            m_trader_info: { id }
        } = this.props;
        const { quadrants, rules } = this.state;
        let arrayData = {
            ...data,
            quadrants_data: quadrants.map(el => {
                el.to = +el.to;
                el.from = +el.from;
                return el;
            }),
            rules_data: rules.map(el => {
                if (el.q2_index) {
                    delete el.value;
                } else {
                    el.value = +el.value;
                }
                return el;
            }),
            signal_a: data.signal_a === "On",
            signal_c: data.signal_c === "On"
        };
        arrayData.symbol = !data.symbol.value ? data.symbol : data.symbol.value;
        arrayData.account = !data.account.value ? data.account : data.account.value;
        arrayData.side = !data.side.value ? data.side : data.side.value;
        patchMTrader(id, arrayData).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                this.setState({ isEdit: false });
                window.scrollTo({ top: 0, behavior: "smooth" });
            }
        });
    };

    render() {
        const { handleSubmit, location, submitting, m_trader_info } = this.props;
        const {
            symbol,
            account,
            side,
            signal_a_check,
            signal_c_check,
            loading,
            quadrants,
            currentQuadrant,
            editQuadrant,
            deleteQuadrant,
            unableToDeleteQuadrant,
            rules,
            currentRule,
            editRule,
            deleteRule,
            isEdit
        } = this.state;
        if (loading) return <Loader />;
        return (
            <div className="content_block">
                <Link to={`/main/m${location.state ? location.state.previousQueries : ""}`} className="go_back">
                    <img src={back_arrow} alt="back_arrow" />
                    back
                </Link>
                <p className="create_m_trader_title">{isEdit ? "Edit trader" : m_trader_info["name"]}</p>
                <form onSubmit={handleSubmit(this.submitForm)} className="form_create_m_trader">
                    <div className="wrapper_create_m_trader">
                        <Fields
                            changeSignalACheck={this.changeSignalACheck}
                            changeSignalCCheck={this.changeSignalCCheck}
                            symbol={symbol}
                            side={side}
                            account={account}
                            signal_a_check={signal_a_check}
                            signal_c_check={signal_c_check}
                            handleChangeRadio={this.handleChangeRadio}
                            m_trader_info={m_trader_info}
                            isEdit={isEdit}
                        />
                        <div className="col_item">
                            <div className="block_item">
                                <p>QUADRANTS</p>
                                <div className="quadrants_wrapper">
                                    <div className="quadrants_list">
                                        {quadrants.map(quadrant => {
                                            const { index, datapoint, from, to, metric } = quadrant;
                                            return (
                                                <div key={index} className="quadrant">
                                                    <div className="title_block">
                                                        <span>Q{index}</span>
                                                        {isEdit && (
                                                            <div className="controls">
                                                                <div
                                                                    className="edit"
                                                                    onClick={() =>
                                                                        this.setState({
                                                                            currentQuadrant: quadrant,
                                                                            editQuadrant: true
                                                                        })
                                                                    }
                                                                >
                                                                    edit
                                                                </div>
                                                                <div
                                                                    className="delete"
                                                                    onClick={() => {
                                                                        rules.some(
                                                                            ({ q1_index, q2_index }) =>
                                                                                index === q1_index || index === q2_index
                                                                        )
                                                                            ? this.setState({
                                                                                  unableToDeleteQuadrant: true
                                                                              })
                                                                            : this.setState({
                                                                                  currentQuadrant: quadrant,
                                                                                  deleteQuadrant: true
                                                                              });
                                                                    }}
                                                                >
                                                                    delete
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div>
                                                        <span>Datapoint</span>
                                                        <div className="fields">
                                                            <div>{datapoint || "none"}</div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span>Array</span>
                                                        <div className="fields">
                                                            <div>{from}</div>
                                                            <div>{to}</div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span>Metric</span>
                                                        <div className="fields">
                                                            <div>{metric}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <Quadrants
                                        addQuadrant={this.addQuadrant}
                                        currentQuadrant={currentQuadrant}
                                        editQuadrant={editQuadrant}
                                        deleteQuadrant={deleteQuadrant}
                                        unableToDeleteQuadrant={unableToDeleteQuadrant}
                                        handleQuadrantEditing={this.handleQuadrantEditing}
                                        handleQuadrantDeleting={this.handleQuadrantDeleting}
                                        handleQuadrantUnableToDelete={this.handleQuadrantUnableToDelete}
                                        isEdit={isEdit}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col_item">
                            <div className="block_item">
                                <p>RULES</p>
                                <div className="rules_wrapper">
                                    <div className="rules_list">
                                        {rules.map((rule, key) => {
                                            const { q1_index, q2_index, condition, value } = rule;
                                            return (
                                                <div key={key} className="rule">
                                                    <div className="title_block">
                                                        <span>Rule_{key + 1}</span>
                                                        {isEdit && (
                                                            <div className="controls">
                                                                <div
                                                                    className="edit"
                                                                    onClick={() =>
                                                                        this.setState({
                                                                            currentRule: { ...rule, key: key + 1 },
                                                                            editRule: true
                                                                        })
                                                                    }
                                                                >
                                                                    edit
                                                                </div>
                                                                <div
                                                                    className="delete"
                                                                    onClick={() =>
                                                                        this.setState({
                                                                            currentRule: { ...rule, key: key + 1 },
                                                                            deleteRule: true
                                                                        })
                                                                    }
                                                                >
                                                                    delete
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div>
                                                        <div className="q1">Q{q1_index}</div>
                                                        <div className="condition">{translateCondition(condition)}</div>
                                                        <div className="q2">
                                                            {!!value || value === 0 ? value : `Q${q2_index}`}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <Rules
                                        addRule={this.addRule}
                                        currentRule={currentRule}
                                        editRule={editRule}
                                        deleteRule={deleteRule}
                                        handleRuleEditing={this.handleRuleEditing}
                                        handleRuleDeleting={this.handleRuleDeleting}
                                        quadrants={quadrants}
                                        isEdit={isEdit}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {isEdit ? (
                        <div className="btn_wrapper">
                            <button className="create_btn" type="submit" disabled={submitting}>
                                Save
                                <img src={arrow} alt="arrow" />
                            </button>
                        </div>
                    ) : (
                        <div className="btn_wrapper">
                            <button
                                className="create_btn"
                                onClick={e => {
                                    e.preventDefault();
                                    this.setState({ isEdit: true });
                                    window.scrollTo({ top: 0, behavior: "smooth" });
                                }}
                            >
                                Edit
                            </button>
                        </div>
                    )}
                </form>
            </div>
        );
    }
}

MTrader = reduxForm({
    form: "MTraderForm",
    validate,
    enableReinitialize: true
})(MTrader);

function mapStateToProps({ m }) {
    return {
        initialValues: {
            is_stopped: m.m_trader_info.is_stopped ? "on" : "off",
            symbol: m.m_trader_info.symbol,
            account: m.m_trader_info.account,
            name: m.m_trader_info.name,
            signal_a: m.m_trader_info.signal_a ? "On" : "Off",
            a_upper_from: m.m_trader_info.a_upper_from,
            a_upper_to: m.m_trader_info.a_upper_to,
            a_real_from: m.m_trader_info.a_real_from,
            a_real_to: m.m_trader_info.a_real_to,
            a_lower_from: m.m_trader_info.a_lower_from,
            a_lower_to: m.m_trader_info.a_lower_to,
            a_hourly_from: m.m_trader_info.a_hourly_from,
            a_hourly_to: m.m_trader_info.a_hourly_to,
            a_daily_from: m.m_trader_info.a_daily_from,
            a_daily_to: m.m_trader_info.a_daily_to,
            signal_c: m.m_trader_info.signal_c ? "On" : "Off",
            c_upper_from: m.m_trader_info.c_upper_from,
            c_upper_to: m.m_trader_info.c_upper_to,
            c_real_from: m.m_trader_info.c_real_from,
            c_real_to: m.m_trader_info.c_real_to,
            c_lower_from: m.m_trader_info.c_lower_from,
            c_lower_to: m.m_trader_info.c_lower_to,
            c_hourly_from: m.m_trader_info.c_hourly_from,
            c_hourly_to: m.m_trader_info.c_hourly_to,
            c_daily_from: m.m_trader_info.c_daily_from,
            c_daily_to: m.m_trader_info.c_daily_to,
            lag_buy_1: m.m_trader_info.lag_buy_1,
            lag_buy_2: m.m_trader_info.lag_buy_2,
            lag_buy_3: m.m_trader_info.lag_buy_3,
            leverage: m.m_trader_info.leverage,
            side: m.m_trader_info.side
        },
        m_trader_info: m.m_trader_info
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getMTrader,
            patchMTrader,
            getOptions,
            startMTrader,
            stopMTrader
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(MTrader);
