import * as types from "./constants";

export function getTrKList(queries) {
    return {
        type: types.GET_TRK_LIST,
        payload: {
            client: "default",
            request: {
                url: `/trk/${queries.length ? "?" : ""}${queries.join("&")}`,
                method: "get"
            }
        }
    };
}

export const getTRKTrader = id => {
    return {
        type: types.GET_TRK_TRADER,
        payload: {
            client: "default",
            request: {
                url: `/trk/${id}/`,
                method: "get"
            }
        }
    };
};

export const getTRKTraderDetails = (id, queries) => {
    return {
        type: types.GET_TRK_TRADER_DETAILS,
        payload: {
            client: "default",
            request: {
                url: `/trk/${id}/history-data/${queries.length ? "?" : ""}${queries.join("&")}`,
                method: "get"
            }
        }
    };
};

export const getTRKTraderCreationInfo = id => {
    return {
        type: types.GET_TRK_TRADER_CREATION_INFO,
        payload: {
            client: "default",
            request: {
                url: `/trk/${id}/creation-info/`,
                method: "get"
            }
        }
    };
};

export const patchTRKTrader = (id, data) => {
    return {
        type: types.PATCH_TRK_TRADER,
        payload: {
            client: "default",
            request: {
                url: `/trk/${id}/`,
                method: "patch",
                data
            }
        }
    };
};

export const postStopTRKTrader = id => {
    return {
        type: types.POST_STOP_TRK_TRADER,
        payload: {
            client: "default",
            request: {
                url: `/trk/${id}/stop/`,
                method: "post"
            }
        }
    };
};

export const postStartTRKTrader = id => {
    return {
        type: types.POST_START_TRK_TRADER,
        payload: {
            client: "default",
            request: {
                url: `/trk/${id}/start/`,
                method: "post"
            }
        }
    };
};

export const postManualTradeTRK = (id, data) => {
    return {
        type: types.POST_MANUAL_TRADE_TRK,
        payload: {
            client: "default",
            request: {
                url: `/trk/${id}/manual-trade/`,
                method: "post",
                data
            }
        }
    };
};

export function getTags() {
    return {
        type: types.GET_TAGS,
        payload: {
            client: "default",
            request: {
                url: `/trk/tags/`,
                method: "get"
            }
        }
    };
}
