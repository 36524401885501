import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import clsx from "clsx";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import { getTrKList } from "../../../actions/trkActions";
import Loader from "../../HelperComponents/Loader/Loader";
import { TrKTable } from "../../TrKTraderBlocks/TrKTable/TrKTable";

import ArrowIcon from "../../../assets/image/arrows/right_icon.svg";
import CheckedIcon from "../../../assets/image/checked.svg";

import "../../HelperComponents/SelectComponent/SelectComponent.scss";
import "./Dashboard.scss";

class Dashboard extends Component {
    constructor(props) {
        super(props);
        const params = new URLSearchParams(decodeURIComponent(props.location.search.substring(1)));
        this.state = {
            tags: params.get("tags") ? params.get("tags").split(",") : [],
            tag: params.get("tag")
                ? params.get("tag")
                : props.tagsList
                      .filter(el => !!el.dashboard)
                      .sort((tag1, tag2) => tag1.dashboard_order - tag2.dashboard_order)[0].name,
            sortBy: params.get("sort-by") || "",
            loading: true,
            tagsSelectorIsOpen: false
        };
    }

    componentDidMount() {
        this.fetchTrKList();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            JSON.stringify(prevState.sortBy) !== JSON.stringify(this.state.sortBy) ||
            JSON.stringify(prevState.tags) !== JSON.stringify(this.state.tags) ||
            JSON.stringify(prevState.tag) !== JSON.stringify(this.state.tag)
        ) {
            this.fetchTrKList();
            this.props.history.push(
                `/main/dashboard?tags=${this.state.tags.join(",")}&sort-by=${this.state.sortBy}&tag=${this.state.tag}`
            );
        }
    }

    fetchTrKList = () => {
        const { getTrKList } = this.props;
        const { sortBy, tags, tag } = this.state;
        let queries = [];
        sortBy && queries.push(`ordering=${sortBy}`);
        tags.length && queries.push(`tags_or=${tags.join(",")}`);
        tag && queries.push(`tags_or=${tag}`);
        if (tags.length) {
            document.title = tags.join(",");
        } else if (tag) {
            document.title = tag;
        } else {
            document.title = "Dashboard";
        }
        getTrKList(queries).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                this.setState({ loading: false });
            }
        });
    };

    sortTrader = sortBy => {
        this.setState({ sortBy });
    };

    handleTagsSelection = name => {
        const { tags } = this.state;
        if (tags.includes(name)) {
            this.setState({ tags: tags.filter(tag => tag !== name), tag: "" });
        } else {
            this.setState({ tags: [...tags, name], tag: "" });
        }
    };

    render() {
        const { trk_list, trk_totals, tagsList, location } = this.props;
        const { sortBy, loading, tagsSelectorIsOpen, tags, tag } = this.state;
        if (loading) return <Loader />;
        return (
            <div className="content_block dashboard_wrapper">
                <div className="title_wrapper">
                    <div className="title">
                        Dashboard
                        <div className="trk_tabs">
                            <button
                                className={clsx(tag === "" && !tags.length && "active")}
                                onClick={() => this.setState({ tag: "", tags: [] })}
                            >
                                All
                            </button>
                            {tagsList
                                .filter(el => !!el.dashboard)
                                .sort((tag1, tag2) => tag1.dashboard_order - tag2.dashboard_order)
                                .map(({ name }) => (
                                    <button
                                        key={name}
                                        className={clsx(tag === name && "active")}
                                        onClick={() => this.setState({ tag: name, tags: [] })}
                                    >
                                        {name}
                                    </button>
                                ))}
                        </div>
                    </div>
                    <div className="tags_wrapper hide_on_mob">
                        <div
                            className="tags_select"
                            onClick={() => this.setState({ tagsSelectorIsOpen: !tagsSelectorIsOpen })}
                        >
                            <span className={clsx(tags.length && "full_opacity")}>
                                {tags.length ? tags.join(", ") : "Select..."}
                            </span>
                            <img src={ArrowIcon} alt="tags" className={clsx(tagsSelectorIsOpen && "rotated")} />
                        </div>
                        <ClickAwayListener
                            onClickAway={() =>
                                setTimeout(() => {
                                    tagsSelectorIsOpen && this.setState({ tagsSelectorIsOpen: false });
                                }, 0)
                            }
                        >
                            <div className={clsx("tags_selector", tagsSelectorIsOpen && "is_open")}>
                                {tagsList.map(({ name }) => {
                                    const isSelected = tags.includes(name);
                                    return (
                                        <div key={name} onClick={() => this.handleTagsSelection(name)}>
                                            {isSelected ? (
                                                <img src={CheckedIcon} className="checked" alt="checked" />
                                            ) : (
                                                <div className="unchecked" />
                                            )}
                                            <p className={clsx("tag_name", isSelected && "selected")}>{name}</p>
                                        </div>
                                    );
                                })}
                            </div>
                        </ClickAwayListener>
                    </div>
                </div>
                <TrKTable
                    sortTrader={this.sortTrader}
                    sortBy={sortBy}
                    trk_list={trk_list}
                    pathname={"dashboard-trader"}
                    location={location}
                    trk_totals={trk_totals}
                />
            </div>
        );
    }
}

const mapStateToProps = ({ trk }) => {
    return {
        trk_list: trk.trk_list.list,
        trk_totals: trk.trk_list.totals,
        tagsList: trk.tags
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getTrKList
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
