export const validate = values => {
    const errors = {};

    /*--------------Order_type-------------*/
    if (!values.buy_order_type) {
        errors.buy_order_type = "Required";
    }

    /*---------------Symbol--------------*/
    if (!values.symbol) {
        errors.symbol = "Required";
    }

    /*---------------Server_type--------------*/
    if (!values.server_type) {
        errors.server_type = "Required";
    }

    /*---------------Priority--------------*/
    if (!values.priority) {
        errors.priority = "Required";
    }

    /*---------------Name--------------*/
    if (!values.name) {
        errors.name = "Required";
    }

    if (values.formula_check === "On") {
        if (!values.x_offset) {
            errors.x_offset = "Required";
        } else if (values.x_offset <= 0) {
            errors.x_offset = "Число должно быть больше нуля";
        }
        if (!values.y_offset) {
            errors.y_offset = "Required";
        } else if (values.y_offset <= 0) {
            errors.y_offset = "Число должно быть больше нуля";
        }
        if (!values.z_offset) {
            errors.z_offset = "Required";
        } else if (values.z_offset <= 0) {
            errors.z_offset = "Число должно быть больше нуля";
        }
        if (!values.x_ohlc) {
            errors.x_ohlc = "Required";
        }
        if (!values.y_ohlc) {
            errors.y_ohlc = "Required";
        }
        if (!values.z_ohlc) {
            errors.z_ohlc = "Required";
        }
        if (!values.x_met) {
            errors.x_met = "Required";
        }
        if (!values.y_met) {
            errors.y_met = "Required";
        }
        if (!values.z_met) {
            errors.z_met = "Required";
        }
        if (!values.formula_from || !values.formula_to) {
            errors.formula_from = "Required";
            errors.formula_to = "Required";
        } else if (+values.formula_from > +values.formula_to) {
            errors.formula_from = "Число не может быть больше";
            errors.formula_to = "Число не может быть больше";
        }
    }

    if (values.signal_a === "On") {
        /*--------------Daily-------------*/

        if (!values.daily_from || !values.daily_to) {
            errors.daily_from = "Required";
            errors.daily_to = "Required";
        } else if (+values.daily_from > +values.daily_to) {
            errors.daily_from = "Число не может быть больше";
            errors.daily_to = "Число не может быть больше";
        }

        /*---------------Upper--------------*/
        if (!values.upper_from || !values.upper_to) {
            errors.upper_from = "Required";
            errors.upper_to = "Required";
        } else if (+values.upper_from > +values.upper_to) {
            errors.upper_from = "Число не может быть больше";
            errors.upper_to = "Число не может быть больше";
        }

        /*---------------Real--------------*/
        if (!values.real_from || !values.real_to) {
            errors.real_from = "Required";
            errors.real_to = "Required";
        } else if (+values.real_from > +values.real_to) {
            errors.real_from = "Число не может быть больше";
            errors.real_to = "Число не может быть больше";
        }

        /*---------------Lower--------------*/
        if (!values.lower_from || !values.lower_to) {
            errors.lower_from = "Required";
            errors.lower_to = "Required";
        } else if (+values.lower_from > +values.lower_to) {
            errors.lower_from = "Число не может быть больше";
            errors.lower_to = "Число не может быть больше";
        }

        /*---------------Hourly--------------*/
        if (!values.hourly_from || !values.hourly_to) {
            errors.hourly_from = "Required";
            errors.hourly_to = "Required";
        } else if (+values.hourly_from > +values.hourly_to) {
            errors.hourly_from = "Число не может быть больше";
            errors.hourly_to = "Число не может быть больше";
        }
    }

    if (values.signal_b === "On") {
        /*---------------Momentum_value--------------*/
        if (!values.momentum_price_from || !values.momentum_price_to) {
            errors.momentum_price_from = "Required";
            errors.momentum_price_to = "Required";
        } else if (+values.momentum_price_from > +values.momentum_price_to) {
            errors.momentum_price_from = "Число не может быть больше";
            errors.momentum_price_to = "Число не может быть больше";
        }
        /*---------------Momentum_minutes--------------*/

        if (!values.momentum_price_minutes || values.momentum_price_minutes < 1) {
            errors.momentum_price_minutes = "Required";
        }

        //-------------------Upper Momentum

        if (!values.momentum_upper_from || !values.momentum_upper_to) {
            errors.momentum_upper_from = "Required";
            errors.momentum_upper_to = "Required";
        } else if (+values.momentum_upper_from > +values.momentum_upper_to) {
            errors.momentum_upper_from = "Число не может быть больше";
            errors.momentum_upper_to = "Число не может быть больше";
        }

        if (!values.momentum_upper_minutes || values.momentum_upper_minutes < 1) {
            errors.momentum_upper_minutes = "Required";
        }

        //-------------------Lower Momentum

        if (!values.momentum_lower_from || !values.momentum_lower_to) {
            errors.momentum_lower_from = "Required";
            errors.momentum_lower_to = "Required";
        } else if (+values.momentum_lower_from > +values.momentum_lower_to) {
            errors.momentum_lower_from = "Число не может быть больше";
            errors.momentum_lower_to = "Число не может быть больше";
        }

        if (!values.momentum_lower_minutes || values.momentum_lower_minutes < 1) {
            errors.momentum_lower_minutes = "Required";
        }

        //-------------------Hourly Momentum

        if (!values.momentum_hourly_from || !values.momentum_hourly_to) {
            errors.momentum_hourly_from = "Required";
            errors.momentum_hourly_to = "Required";
        } else if (+values.momentum_hourly_from > +values.momentum_hourly_to) {
            errors.momentum_hourly_from = "Число не может быть больше";
            errors.momentum_hourly_to = "Число не может быть больше";
        }

        if (!values.momentum_hourly_minutes || values.momentum_hourly_minutes < 1) {
            errors.momentum_hourly_minutes = "Required";
        }

        //-------------------Daily Momentum

        if (!values.momentum_daily_from || !values.momentum_daily_to) {
            errors.momentum_daily_from = "Required";
            errors.momentum_daily_to = "Required";
        } else if (+values.momentum_daily_from > +values.momentum_daily_to) {
            errors.momentum_daily_from = "Число не может быть больше";
            errors.momentum_daily_to = "Число не может быть больше";
        }

        if (!values.momentum_daily_minutes || values.momentum_daily_minutes < 1) {
            errors.momentum_daily_minutes = "Required";
        }
    }

    if (values.signal_c === "On") {
        /*--------------Daily-------------*/

        if (!values.daily_from_2 || !values.daily_to_2) {
            errors.daily_from_2 = "Required";
            errors.daily_to_2 = "Required";
        } else if (+values.daily_from_2 > +values.daily_to_2) {
            errors.daily_from_2 = "Число не может быть больше";
            errors.daily_to_2 = "Число не может быть больше";
        }

        /*---------------Upper--------------*/
        if (!values.upper_from_2 || !values.upper_to_2) {
            errors.upper_from_2 = "Required";
            errors.upper_to_2 = "Required";
        } else if (+values.upper_from_2 > +values.upper_to_2) {
            errors.upper_from_2 = "Число не может быть больше";
            errors.upper_to_2 = "Число не может быть больше";
        }

        /*---------------Real--------------*/
        if (!values.real_from_2 || !values.real_to_2) {
            errors.real_from_2 = "Required";
            errors.real_to_2 = "Required";
        } else if (+values.real_from_2 > +values.real_to_2) {
            errors.real_from_2 = "Число не может быть больше";
            errors.real_to_2 = "Число не может быть больше";
        }

        /*---------------Lower--------------*/
        if (!values.lower_from_2 || !values.lower_to_2) {
            errors.lower_from_2 = "Required";
            errors.lower_to_2 = "Required";
        } else if (+values.lower_from_2 > +values.lower_to_2) {
            errors.lower_from_2 = "Число не может быть больше";
            errors.lower_to_2 = "Число не может быть больше";
        }

        /*---------------Hourly--------------*/
        if (!values.hourly_from_2 || !values.hourly_to_2) {
            errors.hourly_from_2 = "Required";
            errors.hourly_to_2 = "Required";
        } else if (+values.hourly_from_2 > +values.hourly_to_2) {
            errors.hourly_from_2 = "Число не может быть больше";
            errors.hourly_to_2 = "Число не может быть больше";
        }
    }

    if (values.signal_d === "On") {
        /*---------------Momentum_value--------------*/
        if (!values.momentum_price_from_2 || !values.momentum_price_to_2) {
            errors.momentum_price_from_2 = "Required";
            errors.momentum_price_to_2 = "Required";
        } else if (+values.momentum_price_from_2 > +values.momentum_price_to_2) {
            errors.momentum_price_from_2 = "Число не может быть больше";
            errors.momentum_price_to_2 = "Число не может быть больше";
        }
        /*---------------Momentum_minutes--------------*/

        if (!values.momentum_price_minutes_2 || values.momentum_price_minutes_2 < 1) {
            errors.momentum_price_minutes_2 = "Required";
        }

        //-------------------Upper Momentum

        if (!values.momentum_upper_from_2 || !values.momentum_upper_to_2) {
            errors.momentum_upper_from_2 = "Required";
            errors.momentum_upper_to_2 = "Required";
        } else if (+values.momentum_upper_from_2 > +values.momentum_upper_to_2) {
            errors.momentum_upper_from_2 = "Число не может быть больше";
            errors.momentum_upper_to_2 = "Число не может быть больше";
        }

        if (!values.momentum_upper_minutes_2 || values.momentum_upper_minutes_2 < 1) {
            errors.momentum_upper_minutes_2 = "Required";
        }

        //-------------------Lower Momentum

        if (!values.momentum_lower_from_2 || !values.momentum_lower_to_2) {
            errors.momentum_lower_from_2 = "Required";
            errors.momentum_lower_to_2 = "Required";
        } else if (+values.momentum_lower_from_2 > +values.momentum_lower_to_2) {
            errors.momentum_lower_from_2 = "Число не может быть больше";
            errors.momentum_lower_to_2 = "Число не может быть больше";
        }

        if (!values.momentum_lower_minutes_2 || values.momentum_lower_minutes_2 < 1) {
            errors.momentum_lower_minutes_2 = "Required";
        }

        //-------------------Hourly Momentum

        if (!values.momentum_hourly_from_2 || !values.momentum_hourly_to_2) {
            errors.momentum_hourly_from_2 = "Required";
            errors.momentum_hourly_to_2 = "Required";
        } else if (+values.momentum_hourly_from_2 > +values.momentum_hourly_to_2) {
            errors.momentum_hourly_from_2 = "Число не может быть больше";
            errors.momentum_hourly_to_2 = "Число не может быть больше";
        }

        if (!values.momentum_hourly_minutes_2 || values.momentum_hourly_minutes_2 < 1) {
            errors.momentum_hourly_minutes_2 = "Required";
        }

        //-------------------Daily Momentum

        if (!values.momentum_daily_from_2 || !values.momentum_daily_to_2) {
            errors.momentum_daily_from_2 = "Required";
            errors.momentum_daily_to_2 = "Required";
        } else if (+values.momentum_daily_from_2 > +values.momentum_daily_to_2) {
            errors.momentum_daily_from_2 = "Число не может быть больше";
            errors.momentum_daily_to_2 = "Число не может быть больше";
        }

        if (!values.momentum_daily_minutes_2 || values.momentum_daily_minutes_2 < 1) {
            errors.momentum_daily_minutes_2 = "Required";
        }
    }

    /*---------------Frequency--------------*/
    if (!values.max_trades && values.max_trades !== 0) {
        errors.max_trades = "Required";
    } else if (values.max_trades < 0) {
        errors.max_trades = "Число не может быть меньше нуля";
    }

    if (!values.frequency && values.frequency !== 0) {
        errors.frequency = "Required";
    } else if (values.frequency < 0) {
        errors.frequency = "Число не может быть меньше нуля";
    }

    if (!values.multi_entry && values.multi_entry !== 0) {
        errors.multi_entry = "Required";
    } else if (+values.multi_entry < 0) {
        errors.multi_entry = "Число не может быть меньше";
    }

    if (!values.cooldown_int && values.cooldown_int !== 0) {
        errors.cooldown_int = "Required";
    } else if (values.cooldown_int < 0) {
        errors.cooldown_int = "Число не может быть меньше нуля";
    }

    if (!values.cooldown_num && values.cooldown_num !== 0) {
        errors.cooldown_num = "Required";
    } else if (values.cooldown_num < 0) {
        errors.cooldown_num = "Число не может быть меньше нуля";
    }

    /*-------------------LAGS------------------*/
    if (!values.lag_fund && values.lag_fund !== 0) {
        errors.lag_fund = "Required";
    } else if (values.lag_fund < 0) {
        errors.lag_fund = "The number cannot be less than zero";
    }
    if (!values.lag_buy_1 && values.lag_buy_1 !== 0) {
        errors.lag_buy_1 = "Required";
    } else if (values.lag_buy_1 <= 0) {
        errors.lag_buy_1 = "The number should be greater than zero";
    }
    if (!values.lag_buy_2 && values.lag_buy_2 !== 0) {
        errors.lag_buy_2 = "Required";
    } else if (values.lag_buy_2 < 0) {
        errors.lag_buy_2 = "The number cannot be less than zero";
    }
    if (!values.lag_buy_3 && values.lag_buy_3 !== 0) {
        errors.lag_buy_3 = "Required";
    } else if (values.lag_buy_3 < 0) {
        errors.lag_buy_3 = "The number cannot be less than zero";
    }
    if (!values.lag_sl_1 && values.lag_sl_1 !== 0) {
        errors.lag_sl_1 = "Required";
    } else if (values.lag_sl_1 < 0) {
        errors.lag_sl_1 = "The number cannot be less than zero";
    }
    if (!values.lag_sl_2 && values.lag_sl_2 !== 0) {
        errors.lag_sl_2 = "Required";
    } else if (values.lag_sl_2 < 0) {
        errors.lag_sl_2 = "The number cannot be less than zero";
    }
    if (!values.lag_sl_3 && values.lag_sl_3 !== 0) {
        errors.lag_sl_3 = "Required";
    } else if (values.lag_sl_3 < 0) {
        errors.lag_sl_3 = "The number cannot be less than zero";
    }
    if (!values.lag_sell_1 && values.lag_sell_1 !== 0) {
        errors.lag_sell_1 = "Required";
    } else if (values.lag_sell_1 < 0) {
        errors.lag_sell_1 = "The number cannot be less than zero";
    }
    if (!values.lag_sell_2 && values.lag_sell_2 !== 0) {
        errors.lag_sell_2 = "Required";
    } else if (values.lag_sell_2 < 0) {
        errors.lag_sell_2 = "The number cannot be less than zero";
    }
    if (!values.lag_sell_3 && values.lag_sell_3 !== 0) {
        errors.lag_sell_3 = "Required";
    } else if (values.lag_sell_3 < 0) {
        errors.lag_sell_3 = "The number cannot be less than zero";
    }

    /*---------------Stop_loss_fix--------------*/

    if (values.stop_loss_fix_value === "stop_loss_fix") {
        if (!values.stop_loss_fix || +values.stop_loss_fix === 0) {
            errors.stop_loss_fix = "Обязательное поле";
        }
        if (+values.stop_loss_fix > 0) {
            errors.stop_loss_fix = "Число не может быть больше нуля";
        }
    } else if (values.stop_loss_fix_value === "stop_loss_factor") {
        if (!values.stop_loss_factor || +values.stop_loss_factor === 0) {
            errors.stop_loss_factor = "Обязательное поле";
        }
        if (+values.stop_loss_factor < 0) {
            errors.stop_loss_factor = "Число не может быть меньше нуля";
        }
    }

    /*-------------Take_profit_fix------------*/

    if (values.take_profit_fix_value === "take_profit_fix") {
        if (!values.take_profit_fix || +values.take_profit_fix === 0) {
            errors.take_profit_fix = "Обязательное поле";
        }
        if (+values.take_profit_fix < 0) {
            errors.take_profit_fix = "Число не может быть меньше нуля";
        }
    } else if (values.take_profit_fix_value === "take_profit_factor") {
        if (!values.take_profit_factor || +values.take_profit_factor === 0) {
            errors.take_profit_factor = "Обязательное поле";
        }
        if (+values.take_profit_factor < 0) {
            errors.take_profit_factor = "Число не может быть меньше нуля";
        }
    }
    /*---------------Timeframe--------------*/

    if (!values.time_frame) {
        errors.time_frame = "Required";
    } else if (values.time_frame <= 0) {
        errors.time_frame = "The number cannot be less than zero";
    }
    if (!values.exchange) {
        errors.exchange = "Required";
    }
    if (!values.pullback_base) {
        errors.pullback_base = "Required";
    }
    if (!values.orderQty) {
        errors.orderQty = "Required";
    } else if (values.orderQty <= 0) {
        errors.orderQty = "The number cannot be less than zero";
    }
    if (!values.comp && values.comp !== false) {
        errors.comp = "Required";
    }
    if (!values.side) {
        errors.side = "Required";
    }
    if (!values.net) {
        errors.net = "Required";
    }

    if (!values.pullback && values.pullback !== 0) {
        errors.pullback = "error";
    } else if (values.pullback < 0) {
        errors.pullback = "Число не может быть меньше нуля";
    }

    return errors;
};
