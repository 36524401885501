import React from "react";
import Select, { components } from "react-select";
import FormControl from "@material-ui/core/FormControl";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./SelectComponent.scss";

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <div
                    className={props.selectProps.menuIsOpen ? "select_indicator indicator_active" : "select_indicator"}
                >
                    <ExpandMoreIcon />
                </div>
            </components.DropdownIndicator>
        )
    );
};

const SelectComponent = ({
    input,
    options,
    value,
    loading,
    defaultValue,
    placeholder,
    isClearable,
    isSearchable = false,
    isDisabled = false,
    symbol,
    placement = "end",
    meta: { touched, error }
}) => {
    return (
        <FormControl
            classes={{
                root: "select_wrapper"
            }}
        >
            <Select
                {...input}
                onChange={value => input.onChange(value)}
                onBlur={() => input.onBlur(input.value.value)}
                options={options}
                defaultValue={defaultValue}
                className="select_component"
                classNamePrefix="select"
                name="color"
                isOptionDisabled={option => option.disabled}
                value={value}
                onBlurResetsInput={true}
                loadingMessage={() => "Loading filters…"}
                isLoading={loading}
                isClearable={isClearable}
                isSearchable={isSearchable}
                isDisabled={isDisabled}
                placeholder={placeholder}
                components={{ DropdownIndicator }}
                styles={{
                    control: styles => {
                        return {
                            ...styles,
                            borderBottom: touched && !!error ? "1px solid red !important" : null
                            // cursor: error ? 'not-allowed' : 'default'
                        };
                    },
                    option: (styles, { data, isOptionDisabled, isFocused, isSelected }) => {
                        return {
                            ...styles,
                            opacity: data.disabled ? 0.5 : 1,
                            color: "#FFF",
                            cursor: data.disabled ? "auto !important" : "default"
                        };
                    }
                }}
            />
        </FormControl>
    );
};

export default SelectComponent;
