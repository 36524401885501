export const LOGIN = "LOGIN";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const POST_LOGOUT = "POST_LOGOUT";

export const GET_TAGS = "GET_TAGS";
export const GET_TAGS_SUCCESS = "GET_TAGS_SUCCESS";

export const GET_TRADERS = "GET_TRADERS";
export const GET_TRADERS_SUCCESS = "GET_TRADERS_SUCCESS";
export const GET_TRADERS_FAIL = "GET_TRADERS_FAIL";

export const GET_TRADERS_ARCHIVED = "GET_TRADERS_ARCHIVED";

export const GET_TRADER = "GET_TRADER";
export const GET_TRADER_SUCCESS = "GET_TRADER_SUCCESS";

export const TRADER_ARCHIVE = "TRADER_ARCHIVE";
export const TRADER_ARCHIVE_SUCCESS = "TRADER_ARCHIVE_SUCCESS";

export const TRADER_UN_ARCHIVE = "TRADER_UN_ARCHIVE";
export const TRADER_UN_ARCHIVE_SUCCESS = "TRADER_UN_ARCHIVE_SUCCESS";

export const STOP_TRADER = "STOP_TRADER";
export const STOP_TRADER_SUCCESS = "STOP_TRADER_SUCCESS";

export const START_TRADER = "START_TRADER";
export const START_TRADER_SUCCESS = "START_TRADER_SUCCESS";

export const GET_SERVERS = "GET_SERVERS";
export const GET_SERVERS_SUCCESS = "GET_SERVERS_SUCCESS";

export const PATCH_CHANGE_TRADER = "PATCH_CHANGE_TRADER";
export const PATCH_CHANGE_TRADER_SUCCESS = "PATCH_CHANGE_TRADER_SUCCESS";

export const STOP_TRADERS_ALL = "STOP_TRADERS_ALL";

export const CREATE_TRADERS = "CREATE_TRADERS";

export const SORT_TRADERS = "SORT_TRADERS";

export const GET_OPTIONS = "GET_OPTIONS";

export const GET_TRK_LIST = "GET_TRK_LIST";
export const GET_TRK_LIST_SUCCESS = "GET_TRK_LIST_SUCCESS";

export const GET_TRK_TRADER = "GET_TRK_TRADER";
export const GET_TRK_TRADER_SUCCESS = "GET_TRK_TRADER_SUCCESS";

export const GET_TRK_TRADER_DETAILS = "GET_TRK_TRADER_DETAILS";
export const GET_TRK_TRADER_DETAILS_SUCCESS = "GET_TRK_TRADER_DETAILS_SUCCESS";

export const GET_TRK_TRADER_CREATION_INFO = "GET_TRK_TRADER_CREATION_INFO";
export const GET_TRK_TRADER_CREATION_INFO_SUCCESS = "GET_TRK_TRADER_CREATION_INFO_SUCCESS";

export const PATCH_TRK_TRADER = "PATCH_TRK_TRADER";

export const POST_STOP_TRK_TRADER = "POST_STOP_TRK_TRADER";
export const POST_START_TRK_TRADER = "POST_START_TRK_TRADER";
export const POST_MANUAL_TRADE_TRK = "POST_MANUAL_TRADE_TRK";

export const GET_M_TRADERS = "GET_M_TRADERS";
export const GET_M_TRADERS_SUCCESS = "GET_M_TRADERS_SUCCESS";

export const POST_STOP_M_TRADERS = "POST_STOP_M_TRADERS";

export const POST_CREATE_M_TRADER = "POST_CREATE_M_TRADER";

export const GET_M_TRADER = "GET_M_TRADER";
export const GET_M_TRADER_SUCCESS = "GET_M_TRADER_SUCCESS";

export const STOP_M_TRADER = "STOP_M_TRADER";

export const START_M_TRADER = "START_M_TRADER";

export const PATCH_M_TRADER = "PATCH_M_TRADER";
export const PATCH_M_TRADER_SUCCESS = "PATCH_M_TRADER_SUCCESS";
