import React from "react";
import clsx from "clsx";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import ArrowIcon from "../../../assets/image/arrows/right_icon.svg";

import "./ManualSelectComponent.scss";

const ManualSelectComponent = ({ items, item, selectorIsOpen, handleSelector, selectItem }) => {
    return (
        <div className="manual_select_wrapper">
            <div className="manual_select" onClick={() => handleSelector(!selectorIsOpen)}>
                <span className={clsx(item && "full_opacity")}>{item ? item : "Select…"}</span>
                <img src={ArrowIcon} alt="tags" className={clsx(selectorIsOpen && "rotated")} />
            </div>
            <ClickAwayListener
                onClickAway={() =>
                    setTimeout(() => {
                        selectorIsOpen && handleSelector(false);
                    }, 0)
                }
            >
                <div className={clsx("manual_selector", selectorIsOpen && "is_open")}>
                    {items.map(({ name, value, icon }) => {
                        const isSelected = item === value;
                        return (
                            <div
                                key={value}
                                onClick={() => {
                                    selectItem(value);
                                    handleSelector(false);
                                }}
                            >
                                {icon && <img src={icon} alt={name} />}
                                <p className={clsx("tag_name", isSelected && "selected")}>{name}</p>
                            </div>
                        );
                    })}
                </div>
            </ClickAwayListener>
        </div>
    );
};

export default ManualSelectComponent;
