import React from "react";
import { Link } from "react-router-dom";
import archieve from "../../../../assets/image/archieve.png";

import "./IPTradersTable.scss";
import { roundNumber } from "../../../HelperComponents/functions";

export const IPTradersTable = props => {
    const {
        sortTrader,
        sortArr,
        type,
        trade_lists,
        total,
        checkStatus,
        handleArchive,
        handleisArchive,
        location
    } = props;
    return (
        <div className="ip_traders_table">
            <div className="table_container transactions_columns">
                <div className="table_header">
                    <div className="table_row">
                        <div className="row_item" onClick={sortTrader}>
                            trader <span className={sortArr.includes("trader") ? "up" : "down"} />
                        </div>
                        <div className="row_item" onClick={sortTrader}>
                            open <span className={sortArr.includes("open") ? "up" : "down"} />
                        </div>
                        <div className="row_item" onClick={sortTrader}>
                            net <span className={sortArr.includes("net") ? "up" : "down"} />
                        </div>
                        <div className="row_item" onClick={sortTrader}>
                            winrate <span className={sortArr.includes("winrate") ? "up" : "down"} />
                        </div>
                        <div className="row_item" onClick={sortTrader}>
                            trades <span className={sortArr.includes("trades") ? "up" : "down"} />
                        </div>
                        <div className="row_item" onClick={sortTrader}>
                            return <span className={sortArr.includes("return") ? "up" : "down"} />
                        </div>
                        <div className="row_item" onClick={sortTrader}>
                            average <span className={sortArr.includes("average") ? "up" : "down"} />
                        </div>
                        <div className="row_item" onClick={sortTrader}>
                            tp <span className={sortArr.includes("tp") ? "up" : "down"} />
                        </div>
                        <div className="row_item" onClick={sortTrader}>
                            u_won <span className={sortArr.includes("u_won") ? "up" : "down"} />
                        </div>
                        <div className="row_item" onClick={sortTrader}>
                            u_lost <span className={sortArr.includes("u_lost") ? "up" : "down"} />
                        </div>
                        <div className="row_item" onClick={sortTrader}>
                            sl <span className={sortArr.includes("sl") ? "up" : "down"} />
                        </div>
                        <div className="row_item" />
                    </div>
                </div>
                {type === "all" ? (
                    <div className="tab_all">
                        <div className="table_body">
                            {trade_lists.map((el, idx) => {
                                if (el.is_archived !== true) {
                                    return (
                                        <div className="table_row" key={idx}>
                                            <div className="row_item ">
                                                <Link
                                                    to={{
                                                        pathname: `/main/item-trader/${el.id}`,
                                                        state: { previousQueries: location.search }
                                                    }}
                                                    className="status"
                                                >
                                                    <span className={checkStatus(el.status)} />
                                                    {el.name}
                                                </Link>
                                            </div>
                                            <div className="row_item">{el.open}</div>
                                            <div className="row_item">{el.net}</div>
                                            {el.win_rate ? (
                                                <div className="row_item">{roundNumber(el.win_rate)}%</div>
                                            ) : (
                                                <div className="row_item">-</div>
                                            )}
                                            <div className="row_item">{el.trades}</div>
                                            <div className="row_item">
                                                <div className="block_row">
                                                    <span>{el.return_dollar ? el.return_dollar.toFixed(2) : 0}$</span>
                                                    <p>{el.return_percent ? el.return_percent.toFixed(2) : 0}%</p>
                                                </div>
                                            </div>
                                            <div className="row_item">
                                                <div className="block_row">
                                                    <span>{el.average_dollar ? el.average_dollar.toFixed(2) : 0}$</span>
                                                    <p>
                                                        {el.average_percent
                                                            ? el.average_percent.toFixed(2) + "%"
                                                            : 0 + "%"}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row_item">
                                                <div className="block_row">
                                                    <span>{el.tp.count}</span>
                                                    <p>{el.tp.percent ? el.tp.percent.toFixed(2) + "%" : 0 + "%"}</p>
                                                </div>
                                            </div>
                                            <div className="row_item">
                                                <div className="block_row">
                                                    <span>{el.u_won.count}</span>
                                                    <p>
                                                        {el.u_won.percent ? el.u_won.percent.toFixed(2) + "%" : 0 + "%"}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row_item">
                                                <div className="block_row">
                                                    <span>{el.u_lost.count}</span>
                                                    <p>
                                                        {el.u_lost.percent
                                                            ? el.u_lost.percent.toFixed(2) + "%"
                                                            : 0 + "%"}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row_item">
                                                <div className="block_row">
                                                    <span>{el.sl.count}</span>
                                                    <p>{el.sl.percent ? el.sl.percent.toFixed(2) + "%" : 0 + "%"}</p>
                                                </div>
                                            </div>
                                            <div className="row_item">
                                                <button onClick={() => handleArchive(el.id)}>
                                                    <img src={archieve} alt="archieve" />
                                                </button>
                                            </div>
                                        </div>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </div>
                        <div className="table_body table_total">
                            <div className="table_row">
                                <div className="row_item">Total:</div>
                                <div className="row_item" />
                                <div className="row_item" />
                                <div className="row_item" />
                                <div className="row_item">{total.trades}</div>
                                <div className="row_item">{total.return ? total.return.toFixed(2) : 0}$</div>
                                <div className="row_item" />
                                <div className="row_item">{total.tp}</div>
                                <div className="row_item">{total.u_won}</div>
                                <div className="row_item">{total.u_lost}</div>
                                <div className="row_item">{total.sl}</div>
                                <div className="row_item" />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="tab-archived">
                        <div className="table_body">
                            {trade_lists.map((el, idx) => {
                                if (el.is_archived) {
                                    return (
                                        <div className="table_row" key={idx}>
                                            <div className="row_item ">
                                                <Link
                                                    to={{
                                                        pathname: `/main/item-trader/${el.id}`,
                                                        state: { previousQueries: location.search }
                                                    }}
                                                    className="status"
                                                >
                                                    <span className={checkStatus(el.status)} />
                                                    {el.name}
                                                </Link>
                                            </div>
                                            <div className="row_item">{el.open}</div>
                                            <div className="row_item">{el.net}</div>
                                            {el.win_rate ? (
                                                <div className="row_item">{el.win_rate.toFixed(2)}%</div>
                                            ) : (
                                                <div className="row_item">-</div>
                                            )}
                                            <div className="row_item">{el.trades}</div>
                                            <div className="row_item">
                                                <div className="block_row">
                                                    <span>${el.return_dollar.toFixed(2)}</span>
                                                    <p>{el.return_percent.toFixed(2)}%</p>
                                                </div>
                                            </div>
                                            <div className="row_item">
                                                <div className="block_row">
                                                    <span>${el.return_dollar.toFixed(2)}</span>
                                                    <p>
                                                        {el.average_percent
                                                            ? el.average_percent.toFixed(2) + "%"
                                                            : 0 + "%"}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row_item">
                                                <div className="block_row">
                                                    <span>{el.tp.count}</span>
                                                    <p>{el.tp.percent ? el.tp.percent.toFixed(2) + "%" : 0 + "%"}</p>
                                                </div>
                                            </div>
                                            <div className="row_item">
                                                <div className="block_row">
                                                    <span>{el.u_won.count}</span>
                                                    <p>
                                                        {el.u_won.percent ? el.u_won.percent.toFixed(2) + "%" : 0 + "%"}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row_item">
                                                <div className="block_row">
                                                    <span>{el.u_lost.count}</span>
                                                    <p>
                                                        {el.u_lost.percent
                                                            ? el.u_lost.percent.toFixed(2) + "%"
                                                            : 0 + "%"}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row_item">
                                                <div className="block_row">
                                                    <span>{el.sl.count}</span>
                                                    <p>{el.sl.percent ? el.sl.percent.toFixed(2) + "%" : 0 + "%"}</p>
                                                </div>
                                            </div>
                                            <div className="row_item">
                                                <button onClick={() => handleisArchive(el.id)}>
                                                    <img
                                                        src={archieve}
                                                        alt="archieve"
                                                        style={{ transform: "rotateX(180deg)" }}
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
