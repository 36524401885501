import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "./TraderDetails.scss";

class TraderDetails extends Component {
    checkStatus = status => {
        switch (status) {
            case "on":
                return "status done";
            case "off":
                return "status awaiting";
            default:
                return "status canceled";
        }
    };

    render() {
        const {
            trade: { details, creation_info }
        } = this.props;
        return (
            <div className="trader_details">
                <div className="trader_table">
                    <div className="table_container transactions_columns">
                        <div className="table_header">
                            <div className="table_row">
                                <div className="row_item">trade results</div>
                                <div className="row_item">trades</div>
                                <div className="row_item">total $</div>
                                <div className="row_item">total %</div>
                                <div className="row_item">average $</div>
                                <div className="row_item">average %</div>
                            </div>
                        </div>
                        <div className="table_body">
                            <div className="table_row">
                                <div className="row_item">tp</div>
                                <div className="row_item">{details.trade_results.tp.count}</div>
                                <div className="row_item">
                                    {details.trade_results.tp.dollar_total
                                        ? details.trade_results.tp.dollar_total.toFixed(2)
                                        : 0}
                                    $
                                </div>
                                <div className="row_item">
                                    {details.trade_results.tp.percent_total
                                        ? details.trade_results.tp.percent_total.toFixed(2)
                                        : 0}
                                    %
                                </div>
                                <div className="row_item">
                                    {details.trade_results.tp.dollar_average
                                        ? details.trade_results.tp.dollar_average.toFixed(2)
                                        : 0}
                                    $
                                </div>
                                <div className="row_item">
                                    {details.trade_results.tp.percent_average
                                        ? details.trade_results.tp.percent_average.toFixed(2)
                                        : 0}
                                    %
                                </div>
                            </div>
                        </div>
                        <div className="table_body">
                            <div className="table_row">
                                <div className="row_item">u_won</div>
                                <div className="row_item">{details.trade_results.u_won.count}</div>
                                <div className="row_item">
                                    {details.trade_results.u_won.dollar_total
                                        ? details.trade_results.u_won.dollar_total.toFixed(2)
                                        : 0}
                                    $
                                </div>
                                <div className="row_item">
                                    {details.trade_results.u_won.percent_total
                                        ? details.trade_results.u_won.percent_total.toFixed(2)
                                        : 0}
                                    %
                                </div>
                                <div className="row_item">
                                    {details.trade_results.u_won.dollar_average
                                        ? details.trade_results.u_won.dollar_average.toFixed(2)
                                        : 0}
                                    $
                                </div>
                                <div className="row_item">
                                    {details.trade_results.u_won.percent_average
                                        ? details.trade_results.u_won.percent_average.toFixed(2)
                                        : 0}
                                    %
                                </div>
                            </div>
                        </div>
                        <div className="table_body">
                            <div className="table_row">
                                <div className="row_item">u_lost</div>
                                <div className="row_item">{details.trade_results.u_lost.count}</div>
                                <div className="row_item">
                                    {details.trade_results.u_lost.dollar_total
                                        ? details.trade_results.u_lost.dollar_total.toFixed(2)
                                        : 0}
                                    $
                                </div>
                                <div className="row_item">
                                    {details.trade_results.u_lost.percent_total
                                        ? details.trade_results.u_lost.percent_total.toFixed(2)
                                        : 0}
                                    %
                                </div>
                                <div className="row_item">
                                    {details.trade_results.u_lost.dollar_average
                                        ? details.trade_results.u_lost.dollar_average.toFixed(2)
                                        : 0}
                                    $
                                </div>
                                <div className="row_item">
                                    {details.trade_results.u_lost.percent_average
                                        ? details.trade_results.u_lost.percent_average.toFixed(2)
                                        : 0}
                                    %
                                </div>
                            </div>
                        </div>
                        <div className="table_body">
                            <div className="table_row">
                                <div className="row_item">sl</div>
                                <div className="row_item">{details.trade_results.sl.count}</div>
                                <div className="row_item">
                                    {details.trade_results.sl.dollar_total
                                        ? details.trade_results.sl.dollar_total.toFixed(2)
                                        : 0}
                                    $
                                </div>
                                <div className="row_item">
                                    {details.trade_results.sl.percent_total
                                        ? details.trade_results.sl.percent_total.toFixed(2)
                                        : 0}
                                    %
                                </div>
                                <div className="row_item">
                                    {details.trade_results.sl.dollar_average
                                        ? details.trade_results.sl.dollar_average.toFixed(2)
                                        : 0}
                                    $
                                </div>
                                <div className="row_item">
                                    {details.trade_results.sl.percent_average
                                        ? details.trade_results.sl.percent_average.toFixed(2)
                                        : 0}
                                    %
                                </div>
                            </div>
                        </div>
                        <div className="table_body table_total">
                            <div className="table_row">
                                <div className="row_item">Total:</div>
                                <div className="row_item">{details.total.count}</div>
                                <div className="row_item">
                                    {details.total.dollar_total ? details.total.dollar_total.toFixed(2) : 0}$
                                </div>
                                <div className="row_item">
                                    {details.total.percent_total ? details.total.percent_total.toFixed(2) : 0}%
                                </div>
                                <div className="row_item">
                                    {details.total.dollar_average ? details.total.dollar_average.toFixed(2) : 0}$
                                </div>
                                <div className="row_item">
                                    {details.total.percent_average ? details.total.percent_average.toFixed(2) : 0}%
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="info_components">
                    <div className="info_col">
                        <div>
                            <div className="title">Strategy</div>
                            <div>
                                <span>Order_type</span>
                                <p>{creation_info.strategy.buy_order_type_display}</p>
                            </div>
                            <div>
                                <span>Symbol</span>
                                <p>{details.strategy.symbol}</p>
                            </div>
                            <div>
                                <span>Trader_name</span>
                                <p>{details.strategy.name}</p>
                            </div>
                            <div>
                                <span>Server_type</span>
                                <p>{creation_info.strategy.server_type_display}</p>
                            </div>
                        </div>
                        <div>
                            <div className="title">Exit_Stage</div>
                            <div>
                                <span>step_5_1</span>
                                <p>{details.stop_reasons_stat.exit_stage.step_5_1}</p>
                            </div>
                            <div>
                                <span>step_5_3</span>
                                <p>{details.stop_reasons_stat.exit_stage.step_5_3}</p>
                            </div>
                            <div>
                                <span>step_6_1</span>
                                <p>{details.stop_reasons_stat.exit_stage.step_6_1}</p>
                            </div>
                            <div>
                                <span>step_6_3</span>
                                <p>{details.stop_reasons_stat.exit_stage.step_6_3}</p>
                            </div>
                            <div>
                                <span>step_7_1</span>
                                <p>{details.stop_reasons_stat.exit_stage.step_7_1}</p>
                            </div>
                        </div>
                    </div>
                    <div className="info_col">
                        <div>
                            <div className="title">Status</div>
                            <div>
                                <span>Alert_name</span>
                                <p>{details.status.alert_name.join(", ")}</p>
                            </div>
                            <div>
                                <span>Open_now</span>
                                <p>{details.status.open_now}</p>
                            </div>
                            <div>
                                <span>Status</span>
                                <span className={this.checkStatus(details.status.status)}></span>
                            </div>
                        </div>
                        <div>
                            <div className="title">Exit_lag</div>
                            <div>
                                <span>step_6_5</span>
                                <p>{details.exit_lag.is_step_6_5_executed}</p>
                            </div>
                            <div>
                                <span>step_6_6</span>
                                <p>{details.exit_lag.is_step_6_6_executed}</p>
                            </div>
                            <div>
                                <span>step_6_7</span>
                                <p>{details.stop_reasons_stat.exit_market.step_6_7}</p>
                            </div>
                            <div>
                                <span>step_7_2</span>
                                <p>{details.exit_lag.is_step_7_2_executed}</p>
                            </div>
                            <div>
                                <span>step_7_3</span>
                                <p>{details.exit_lag.is_step_7_3_executed}</p>
                            </div>
                            <div>
                                <span>step_7_4</span>
                                <p>{details.stop_reasons_stat.exit_market.step_7_4}</p>
                            </div>
                        </div>
                    </div>
                    <div className="info_col">
                        <div>
                            <div className="title">Analytics</div>
                            <div>
                                <span>Winrate</span>
                                <p>
                                    {details.analytics.win_rate !== null
                                        ? details.analytics.win_rate.toFixed(2) + "%"
                                        : ""}
                                </p>
                            </div>
                            <div>
                                <span>OutOfLimit</span>
                                <p>{details.analytics.out_of_limit}</p>
                            </div>
                            <div>
                                <span>Canceled</span>
                                <p>{details.analytics.cancelled}</p>
                            </div>
                            <div>
                                <span>PartiallyFilled</span>
                                <p>{details.analytics.partially_filled}</p>
                            </div>
                            <div>
                                <span>Bounce</span>
                                <p>{details.analytics.bounce}</p>
                            </div>
                            <div>
                                <span>pdni_1</span>
                                <p>{details.analytics.pdni_1}</p>
                            </div>
                            <div>
                                <span>pdni_2</span>
                                <p>{details.analytics.pdni_2}</p>
                            </div>
                            <div>
                                <span>Doublespend</span>
                                <p>{details.analytics.doublespend}</p>
                            </div>
                            <div>
                                <span>5хх/4хх</span>
                                <p>{details.analytics["5xx"]}</p>
                            </div>
                            <div>
                                <span>Bitmex</span>
                                <p>{details.analytics.bitmex}</p>
                            </div>
                            <div>
                                <span>duration_buy_1w</span>
                                <p>{details.analytics.duration_buy_1w ? details.analytics.duration_buy_1w : "-"}</p>
                            </div>
                            <div>
                                <span>duration_buy_1m</span>
                                <p>{details.analytics.duration_buy_1m ? details.analytics.duration_buy_1m : "-"}</p>
                            </div>
                            <div>
                                <span>duration_trade_1w</span>
                                <p>{details.analytics.duration_trade_1w ? details.analytics.duration_trade_1w : "-"}</p>
                            </div>
                            <div>
                                <span>duration_trade_1m</span>
                                <p>{details.analytics.duration_trade_1m ? details.analytics.duration_trade_1m : "-"}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps({ trade }) {
    return {};
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(TraderDetails);
