import React, { Component } from "react";
import { connect } from "react-redux";
import Clipboard from "react-clipboard.js";

import { getServers } from "../../../actions/serverAction";
import Loader from "../../HelperComponents/Loader/Loader";

import CopyIcon from "../../../assets/image/copy.svg";

import "./Servers.scss";

class DashboardServers extends Component {
    state = {
        loading: true
    };

    componentDidMount() {
        const { getServers } = this.props;
        document.title = "Servers";
        getServers().then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                this.setState({ loading: false });
            }
        });
    }

    checkStatus = status => {
        switch (status) {
            case "creating":
                return "creating";
            case "created":
                return "created";
            case "error":
                return "error";
            case "alert":
                return "alert";
            default:
                return "deleting";
        }
    };

    render() {
        const { server_list } = this.props;
        const { loading } = this.state;
        if (loading) return <Loader />;
        return (
            <div className="content_block servers_wrapper">
                <div className="panel_dashboard">
                    <div className="title">Servers</div>
                    <div className="menu" />
                </div>
                <div className="no_items">No items.</div>
                <div className="dashboard_table dashboard_table">
                    <div className="table_container transactions_columns">
                        <div className="table_header">
                            <div className="table_row table_row_server">
                                <div className="row_item">name</div>
                                <div className="row_item">IP_address</div>
                                <div className="row_item">server_type</div>
                                <div className="row_item">mainnet</div>
                                <div className="row_item">lev_main</div>
                                <div className="row_item">bal_main</div>
                                <div className="row_item">testnet</div>
                                <div className="row_item">lev_test</div>
                                <div className="row_item">bal_test</div>
                                <div className="row_item" />
                            </div>
                        </div>
                        <div className="tab_all">
                            <div className="table_body">
                                {server_list.data.map((server, index) => {
                                    return (
                                        <div className="table_row table_row_server" key={index}>
                                            <div className="row_item">
                                                <p className="server_status">
                                                    <span className={this.checkStatus(server.status)} />
                                                    {server.hostname}
                                                </p>
                                            </div>
                                            <div className="row_item">{server.ip}</div>
                                            <div className="row_item">{server.server_type}</div>
                                            <div className="row_item">{server.active_trades_mainnet}</div>
                                            <div className="row_item">
                                                {server.lev_main ? server.lev_main.toFixed(1) + "x" : "-"}
                                            </div>
                                            <div className="row_item">
                                                {server.bal_main ? server.bal_main.toFixed(4) : "-"}
                                            </div>
                                            <div className="row_item">{server.active_trades_testnet}</div>
                                            <div className="row_item">
                                                {server.lev_test ? server.lev_test.toFixed(1) + "x" : "-"}
                                            </div>
                                            <div className="row_item">
                                                {server.bal_test ? server.bal_test.toFixed(4) : "-"}
                                            </div>
                                            <div className="row_item">
                                                <Clipboard
                                                    className="copy_icon"
                                                    component="div"
                                                    data-clipboard-text={server.proxy_string}
                                                >
                                                    <img src={CopyIcon} alt="copy" /> copy cmd
                                                </Clipboard>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="table_footer">
                            <div className="table_row table_row_server">
                                <div className="row_item" />
                                <div className="row_item" />
                                <div className="row_item" />
                                <div className="row_item" />
                                <div className="row_item" />
                                <div className="row_item bold">{server_list.bal_main}</div>
                                <div className="row_item" />
                                <div className="row_item" />
                                <div className="row_item" />
                                <div className="row_item" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps({ server_list }) {
    return server_list;
}

const mapDispatchToProps = {
    getServers
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardServers);
