import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

import { getOptions } from "../../../../actions/traderAction";
import { createMTrader } from "../../../../actions/mActions";
import Loader from "../../../HelperComponents/Loader/Loader";
import { translateCondition } from "../../../HelperComponents/functions";

import { validate } from "./validate";
import { Fields } from "../../../MTraderBlocks/Fields";
import { Quadrants } from "../../../MTraderBlocks/Quadrants/Quadrants";
import { Rules } from "../../../MTraderBlocks/Rules/Rules";

import arrow from "../../../../assets/image/arrow.png";
import back_arrow from "../../../../assets/image/back_arrow.png";

import "./CreateMTrader.scss";

class CreateMTrader extends Component {
    state = {
        symbol: [],
        account: [],
        side: [
            {
                label: "buy",
                value: "buy"
            },
            {
                label: "sell",
                value: "sell"
            },
            {
                label: "exit",
                value: "exit"
            }
        ],
        signal_a_check: true,
        signal_c_check: true,
        quadrants: [],
        currentQuadrant: {},
        editQuadrant: false,
        deleteQuadrant: false,
        unableToDeleteQuadrant: false,
        rules: [],
        currentRule: {},
        editRule: false,
        deleteRule: false,
        loading: true
    };

    componentDidMount() {
        const { getOptions } = this.props;
        document.title = "Create new trader";
        getOptions().then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                let symbol = [],
                    account = [];
                res.payload.data.symbols.forEach(el => symbol.push({ label: el.label, value: el.value }));
                res.payload.data.m_trk_accounts.forEach(el => account.push({ label: el.label, value: el.value }));
                this.setState({ symbol, account, loading: false });
            }
        });
    }

    changeSignalACheck = e => this.setState({ signal_a_check: e.target.value !== "On" });

    changeSignalCCheck = e => this.setState({ signal_c_check: e.target.value !== "On" });

    addQuadrant = quadrant => {
        this.setState(({ quadrants }) => ({
            quadrants: [
                ...quadrants,
                { index: quadrants.length ? quadrants[quadrants.length - 1].index + 1 : 1, ...quadrant }
            ]
        }));
    };

    handleQuadrantEditing = quadrant => {
        const { currentQuadrant, quadrants } = this.state;
        if (quadrant) {
            this.setState({
                quadrants: quadrants.map(el => {
                    if (el["index"] === currentQuadrant["index"]) {
                        return { ...quadrant, index: el["index"] };
                    } else {
                        return el;
                    }
                }),
                editQuadrant: false,
                currentQuadrant: {}
            });
        } else {
            this.setState({ editQuadrant: false, currentQuadrant: {} });
        }
    };

    handleQuadrantDeleting = quadrant => {
        const { quadrants } = this.state;
        if (quadrant) {
            this.setState({
                quadrants: quadrants.filter(el => el["index"] !== quadrant["index"]),
                deleteQuadrant: false,
                currentQuadrant: {}
            });
        } else {
            this.setState({ deleteQuadrant: false, currentQuadrant: {} });
        }
    };

    handleQuadrantUnableToDelete = () => this.setState({ unableToDeleteQuadrant: false, currentQuadrant: {} });

    addRule = rule => {
        this.setState(({ rules }) => ({
            rules: [...rules, rule]
        }));
    };

    handleRuleEditing = rule => {
        const { currentRule, rules } = this.state;
        if (rule) {
            this.setState({
                rules: rules.map((el, key) => {
                    if (key + 1 === currentRule["key"]) {
                        return rule;
                    } else {
                        return el;
                    }
                }),
                editRule: false,
                currentRule: {}
            });
        } else {
            this.setState({ editRule: false, currentRule: {} });
        }
    };

    handleRuleDeleting = rule => {
        const { rules } = this.state;
        let tempRules = [...rules];
        tempRules.splice(rule["key"] - 1, 1);
        if (rules) {
            this.setState({
                rules: tempRules,
                deleteRule: false,
                currentRule: {}
            });
        } else {
            this.setState({ deleteRule: false, currentRule: {} });
        }
    };

    submitForm = data => {
        const { createMTrader, reset, history, location } = this.props;
        const { quadrants, rules } = this.state;
        let arrayData = {
            ...data,
            quadrants_data: quadrants.map(el => {
                el.to = +el.to;
                el.from = +el.from;
                return el;
            }),
            rules_data: rules.map(el => {
                if (el.q2_index) {
                    delete el.value;
                } else {
                    el.value = +el.value;
                }
                return el;
            }),
            signal_a: data.signal_a === "On",
            signal_c: data.signal_c === "On"
        };
        arrayData.symbol = !data.symbol.value ? data.symbol : data.symbol.value;
        arrayData.account = !data.account.value ? data.account : data.account.value;
        arrayData.side = !data.side.value ? data.side : data.side.value;
        createMTrader(arrayData).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 201) {
                reset();
                history.push(`/main/m${location.state ? location.state.previousQueries : ""}`);
            } else {
                alert(Object.values(res.error.response.data)[0]);
            }
        });
    };

    render() {
        const { handleSubmit, location, submitting } = this.props;
        const {
            symbol,
            account,
            side,
            signal_a_check,
            signal_c_check,
            loading,
            quadrants,
            currentQuadrant,
            editQuadrant,
            deleteQuadrant,
            unableToDeleteQuadrant,
            rules,
            currentRule,
            editRule,
            deleteRule
        } = this.state;
        if (loading) return <Loader />;
        return (
            <div className="content_block">
                <Link to={`/main/m${location.state ? location.state.previousQueries : ""}`} className="go_back">
                    <img src={back_arrow} alt="back_arrow" />
                    back
                </Link>
                <p className="create_m_trader_title">Create new trader</p>
                <form onSubmit={handleSubmit(this.submitForm)} className="form_create_m_trader">
                    <div className="wrapper_create_m_trader">
                        <Fields
                            changeSignalACheck={this.changeSignalACheck}
                            changeSignalCCheck={this.changeSignalCCheck}
                            symbol={symbol}
                            side={side}
                            account={account}
                            signal_a_check={signal_a_check}
                            signal_c_check={signal_c_check}
                            isEdit={true}
                            m_trader_info={{}}
                        />
                        <div className="col_item">
                            <div className="block_item">
                                <p>QUADRANTS</p>
                                <div className="quadrants_wrapper">
                                    <div className="quadrants_list">
                                        {quadrants.map(quadrant => {
                                            const { index, datapoint, from, to, metric } = quadrant;
                                            return (
                                                <div key={index} className="quadrant">
                                                    <div className="title_block">
                                                        <span>Q{index}</span>
                                                        <div className="controls">
                                                            <div
                                                                className="edit"
                                                                onClick={() =>
                                                                    this.setState({
                                                                        currentQuadrant: quadrant,
                                                                        editQuadrant: true
                                                                    })
                                                                }
                                                            >
                                                                edit
                                                            </div>
                                                            <div
                                                                className="delete"
                                                                onClick={() => {
                                                                    rules.some(
                                                                        ({ q1_index, q2_index }) =>
                                                                            index === q1_index || index === q2_index
                                                                    )
                                                                        ? this.setState({
                                                                              unableToDeleteQuadrant: true
                                                                          })
                                                                        : this.setState({
                                                                              currentQuadrant: quadrant,
                                                                              deleteQuadrant: true
                                                                          });
                                                                }}
                                                            >
                                                                delete
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span>Datapoint</span>
                                                        <div className="fields">
                                                            <div>{datapoint || "none"}</div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span>Array</span>
                                                        <div className="fields">
                                                            <div>{from}</div>
                                                            <div>{to}</div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span>Metric</span>
                                                        <div className="fields">
                                                            <div>{metric}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <Quadrants
                                        addQuadrant={this.addQuadrant}
                                        currentQuadrant={currentQuadrant}
                                        editQuadrant={editQuadrant}
                                        deleteQuadrant={deleteQuadrant}
                                        unableToDeleteQuadrant={unableToDeleteQuadrant}
                                        handleQuadrantEditing={this.handleQuadrantEditing}
                                        handleQuadrantDeleting={this.handleQuadrantDeleting}
                                        handleQuadrantUnableToDelete={this.handleQuadrantUnableToDelete}
                                        isEdit={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col_item">
                            <div className="block_item">
                                <p>RULES</p>
                                <div className="rules_wrapper">
                                    <div className="rules_list">
                                        {rules.map((rule, key) => {
                                            const { q1_index, q2_index, condition, value } = rule;
                                            return (
                                                <div key={key} className="rule">
                                                    <div className="title_block">
                                                        <span>Rule_{key + 1}</span>
                                                        <div className="controls">
                                                            <div
                                                                className="edit"
                                                                onClick={() =>
                                                                    this.setState({
                                                                        currentRule: { ...rule, key: key + 1 },
                                                                        editRule: true
                                                                    })
                                                                }
                                                            >
                                                                edit
                                                            </div>
                                                            <div
                                                                className="delete"
                                                                onClick={() =>
                                                                    this.setState({
                                                                        currentRule: { ...rule, key: key + 1 },
                                                                        deleteRule: true
                                                                    })
                                                                }
                                                            >
                                                                delete
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="q1">Q{q1_index}</div>
                                                        <div className="condition">{translateCondition(condition)}</div>
                                                        <div className="q2">
                                                            {!!value || value === 0 ? value : `Q${q2_index}`}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <Rules
                                        addRule={this.addRule}
                                        currentRule={currentRule}
                                        editRule={editRule}
                                        deleteRule={deleteRule}
                                        handleRuleEditing={this.handleRuleEditing}
                                        handleRuleDeleting={this.handleRuleDeleting}
                                        quadrants={quadrants}
                                        isEdit={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="btn_wrapper">
                        <button className="create_btn" type="submit" disabled={submitting}>
                            create
                            <img src={arrow} alt="arrow" />
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}

CreateMTrader = reduxForm({
    form: "CreateMTraderForm",
    validate
})(CreateMTrader);

function mapStateToProps(state) {
    return {
        initialValues: {
            is_stopped: "on",
            signal_a: "Off",
            signal_c: "Off"
        }
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            createMTrader,
            getOptions
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateMTrader);
