import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getTrKList } from "../../../actions/trkActions";
import Loader from "../../HelperComponents/Loader/Loader";
import { TrKTable } from "../../TrKTraderBlocks/TrKTable/TrKTable";

import "../../HelperComponents/SelectComponent/SelectComponent.scss";
import "./Split.scss";

class Split extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sortArray: [],
            loading: true,
            tagsSelectorIsOpen: false,
            splitArray: []
        };
    }

    async componentDidMount() {
        const { tagsList } = this.props;
        document.title = "Split";
        const tempTagList = tagsList.filter(tag => tag.split).sort((tag1, tag2) => tag1.split_order - tag2.split_order);
        let splitArray = [];
        const promises = tempTagList.map(({ name }) => this.fetchTrKList(name));
        splitArray = await Promise.all(promises);
        this.setState({ splitArray, loading: false, sortArray: splitArray.map(({ name }) => ({ name, sortBy: "" })) });
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevState.reload) !== JSON.stringify(this.state.reload)) {
        }
    }

    fetchTrKList = async (tag, sortBy = undefined) => {
        const { getTrKList } = this.props;
        let queries = [];
        sortBy && queries.push(`ordering=${sortBy}`);
        queries.push(`tags_or=${tag}`);
        const res = await getTrKList(queries);
        return { name: tag, data: res.payload.data };
    };

    fetchSortedTraders = async (name, sortBy) => {
        const { splitArray } = this.state;
        const promise = await Promise.resolve(this.fetchTrKList(name, sortBy));
        let tempSplitArray = [...splitArray];
        const idOfChangedTag = tempSplitArray.findIndex(el => el.name === promise.name);
        tempSplitArray[idOfChangedTag] = promise;
        return tempSplitArray;
    };

    render() {
        const { location } = this.props;
        const { sortArray, loading, splitArray } = this.state;
        if (loading) return <Loader />;
        return (
            <div className="content_block split_wrapper">
                <div className="title">Split</div>
                {splitArray.map(({ name, data: { list, totals } }, key) => (
                    <div className="split_table" key={name}>
                        <h3>{name}</h3>
                        <TrKTable
                            sortTrader={sortBy => {
                                let tempSortArray = [...sortArray];
                                tempSortArray[key].sortBy = sortBy;
                                this.fetchSortedTraders(name, sortBy).then(tempSplitArray => {
                                    this.setState({ splitArray: tempSplitArray, sortArray: tempSortArray });
                                });
                            }}
                            sortBy={sortArray[key].sortBy}
                            trk_list={list}
                            pathname={"split-trader"}
                            location={location}
                            trk_totals={totals}
                        />
                    </div>
                ))}
            </div>
        );
    }
}

const mapStateToProps = ({ trk }) => {
    return {
        tagsList: trk.tags
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getTrKList
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Split);
