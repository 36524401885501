import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

import { getTrKList } from "../../../actions/trkActions";
import {
    getTraders,
    sortTraderAction,
    startTrader,
    stopTraderAll,
    traderArchive,
    traderUnArchive
} from "../../../actions/traderAction";

import Loader from "../../HelperComponents/Loader/Loader";
import DialogComponent from "../../HelperComponents/DialogComponent/DialogComponent";
import { IPTradersTable } from "./IPTradersTable/IPTradersTable";
import ManualSelectComponent from "../../HelperComponents/ManualSelectComponent/ManualSelectComponent";

import allIcon from "../../../assets/image/all.png";
import archivedIcon from "../../../assets/image/archieve_small.png";
import add_icon from "../../../assets/image/add_icon.png";
import stop_icon from "../../../assets/image/stop_icon.png";

import "../../HelperComponents/SelectComponent/SelectComponent.scss";
import "./IP.scss";

class IP extends Component {
    constructor(props) {
        super(props);
        const params = new URLSearchParams(decodeURIComponent(props.location.search.substring(1)));
        this.state = {
            sortBy: "",
            loading: true,
            open: false,
            type: params.get("type") || "all",
            sortArr: [],
            net: params.get("net") || "live",
            netSelectorIsOpen: false,
            typeSelectorIsOpen: false
        };
    }

    componentDidMount() {
        const { net } = this.state;
        document.title = "IP";
        this.fetchTraders(net).then(() => {
            this.setState({ loading: false });
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevState.type) !== JSON.stringify(this.state.type)) {
            this.props.history.push(`/main/ip?net=${this.state.net}&type=${this.state.type}`);
        }
    }

    fetchTrKList = async () => {
        const { getTrKList } = this.props;
        const { sortBy } = this.state;
        let queries = [];
        sortBy && queries.push(`ordering=${sortBy}`);
        await getTrKList(queries);
    };

    fetchTraders = async net => {
        const { getTraders } = this.props;
        const { type } = this.state;
        this.props.history.push(`/main/ip?net=${net}&type=${type}`);
        await getTraders(net);
    };

    toggleDialog = () => {
        this.setState(({ open }) => ({
            open: !open
        }));
    };

    stopTrade = () => {
        const { stopTraderAll, getTraders } = this.props;
        const { net } = this.state;
        stopTraderAll().then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                this.toggleDialog();
                getTraders(net);
            }
        });
    };

    checkStatus = status => {
        switch (status) {
            case "on":
                return "status done";
            case "off":
                return "status awaiting";
            default:
                return "status canceled";
        }
    };

    sortTrader = e => {
        const { sortTraderAction } = this.props;
        if (this.state.sortArr.includes(e.target.innerText)) {
            this.setState({ sortArr: this.state.sortArr.filter(el => el !== e.target.innerText) });
        } else {
            this.setState({ sortArr: [...this.state.sortArr, e.target.innerText] });
        }
        sortTraderAction(e.target.innerText, this.state.sortArr.includes(e.target.innerText));
    };

    handleArchive = id => {
        const { traderArchive, getTraders } = this.props;
        const { net } = this.state;

        traderArchive(id).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                getTraders(net);
            }
        });
    };

    handleisArchive = id => {
        const { traderUnArchive, getTraders } = this.props;
        const { net } = this.state;
        traderUnArchive(id).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                getTraders(net);
            }
        });
    };

    selectNet = net => {
        this.setState({ net });
        this.fetchTraders(net);
    };

    render() {
        const {
            location,
            trade: { trade_lists, total }
        } = this.props;
        const { loading, open, type, sortArr, net, netSelectorIsOpen, typeSelectorIsOpen } = this.state;
        if (loading) return <Loader />;
        return (
            <div className="content_block ip_wrapper">
                <div className="title">
                    <div className="title_and_tabs">IP</div>
                    <div className="menu">
                        <ManualSelectComponent
                            items={[
                                { name: "live", value: "live" },
                                { name: "test", value: "testnet" },
                                { name: "all", value: "all" }
                            ]}
                            item={net}
                            selectorIsOpen={netSelectorIsOpen}
                            handleSelector={netSelectorIsOpen => this.setState({ netSelectorIsOpen })}
                            selectItem={this.selectNet}
                        />
                        <ManualSelectComponent
                            items={[
                                { name: "all", value: "all", icon: allIcon },
                                { name: "archived", value: "archived", icon: archivedIcon }
                            ]}
                            item={type}
                            selectorIsOpen={typeSelectorIsOpen}
                            handleSelector={typeSelectorIsOpen => this.setState({ typeSelectorIsOpen })}
                            selectItem={type => this.setState({ type })}
                        />
                        <Link
                            to={{
                                pathname: `/main/create-trader`,
                                state: { previousQueries: location.search }
                            }}
                            className="add_new"
                        >
                            <img src={add_icon} alt="add_icon" />
                            Create
                        </Link>
                        <button className="stop_block" onClick={this.toggleDialog}>
                            <img src={stop_icon} alt="stop_icon" />
                            Stop all
                        </button>
                    </div>
                </div>
                <IPTradersTable
                    sortTrader={this.sortTrader}
                    sortArr={sortArr}
                    type={type}
                    trade_lists={trade_lists}
                    total={total}
                    checkStatus={this.checkStatus}
                    handleArchive={this.handleArchive}
                    handleisArchive={this.handleisArchive}
                    location={location}
                />
                <DialogComponent open={open} onClose={this.toggleDialog}>
                    <div className="ip_stop_traders">
                        <div className="title">Stop all traders</div>
                        <div className="descriptions">Are you sure you want to stop all traders?</div>
                        <hr />
                        <div className="dialog_brn_wrapper">
                            <button className="btn_grey" onClick={this.toggleDialog}>
                                Cancel
                            </button>
                            <button className="btn_blue" onClick={this.stopTrade}>
                                Stop
                            </button>
                        </div>
                    </div>
                </DialogComponent>
            </div>
        );
    }
}

const mapStateToProps = ({ trade }) => {
    return {
        trade
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getTrKList,
            getTraders,
            stopTraderAll,
            sortTraderAction,
            traderArchive,
            traderUnArchive,
            startTrader
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(IP);
