export const validate = values => {
    const errors = {};
    if (!values.symbol) {
        errors.symbol = "Required";
    }
    if (!values.account) {
        errors.account = "Required";
    }
    if (!values.name) {
        errors.name = "Required";
    }
    if (values.signal_a === "On") {
        if (!values.a_daily_from || !values.a_daily_to) {
            errors.a_daily_from = "Required";
            errors.a_daily_to = "Required";
        } else if (+values.a_daily_from > +values.a_daily_to) {
            errors.a_daily_from = "Число не может быть больше";
            errors.a_daily_to = "Число не может быть больше";
        }
        if (!values.a_upper_from || !values.a_upper_to) {
            errors.a_upper_from = "Required";
            errors.a_upper_to = "Required";
        } else if (+values.a_upper_from > +values.a_upper_to) {
            errors.a_upper_from = "Число не может быть больше";
            errors.a_upper_to = "Число не может быть больше";
        }

        if (!values.a_real_from || !values.a_real_to) {
            errors.a_real_from = "Required";
            errors.a_real_to = "Required";
        } else if (+values.a_real_from > +values.a_real_to) {
            errors.a_real_from = "Число не может быть больше";
            errors.a_real_to = "Число не может быть больше";
        }
        if (!values.a_lower_from || !values.a_lower_to) {
            errors.a_lower_from = "Required";
            errors.a_lower_to = "Required";
        } else if (+values.a_lower_from > +values.a_lower_to) {
            errors.a_lower_from = "Число не может быть больше";
            errors.a_lower_to = "Число не может быть больше";
        }
        if (!values.a_hourly_from || !values.a_hourly_to) {
            errors.a_hourly_from = "Required";
            errors.a_hourly_to = "Required";
        } else if (+values.a_hourly_from > +values.a_hourly_to) {
            errors.a_hourly_from = "Число не может быть больше";
            errors.a_hourly_to = "Число не может быть больше";
        }
    }
    if (values.signal_c === "On") {
        if (!values.c_daily_from || !values.c_daily_to) {
            errors.c_daily_from = "Required";
            errors.c_daily_to = "Required";
        } else if (+values.c_daily_from > +values.c_daily_to) {
            errors.daily_from_2 = "Число не может быть больше";
            errors.c_daily_to = "Число не может быть больше";
        }
        if (!values.c_upper_from || !values.c_upper_to) {
            errors.c_upper_from = "Required";
            errors.c_upper_to = "Required";
        } else if (+values.c_upper_from > +values.c_upper_to) {
            errors.c_upper_from = "Число не может быть больше";
            errors.c_upper_to = "Число не может быть больше";
        }
        if (!values.c_real_from || !values.c_real_to) {
            errors.c_real_from = "Required";
            errors.c_real_to = "Required";
        } else if (+values.c_real_from > +values.c_real_to) {
            errors.c_real_from = "Число не может быть больше";
            errors.c_real_to = "Число не может быть больше";
        }
        if (!values.c_lower_from || !values.c_lower_to) {
            errors.c_lower_from = "Required";
            errors.c_lower_to = "Required";
        } else if (+values.c_lower_from > +values.c_lower_to) {
            errors.c_lower_from = "Число не может быть больше";
            errors.c_lower_to = "Число не может быть больше";
        }
        if (!values.c_hourly_from || !values.c_hourly_to) {
            errors.c_hourly_from = "Required";
            errors.c_hourly_to = "Required";
        } else if (+values.c_hourly_from > +values.c_hourly_to) {
            errors.c_hourly_from = "Число не может быть больше";
            errors.c_hourly_to = "Число не может быть больше";
        }
    }
    if (!values.lag_buy_1 && values.lag_buy_1 !== 0) {
        errors.lag_buy_1 = "Required";
    } else if (values.lag_buy_1 <= 0) {
        errors.lag_buy_1 = "Can be fractional number (positive or negative) or 0";
    }
    if (!values.lag_buy_2 && values.lag_buy_2 !== 0) {
        errors.lag_buy_2 = "Required";
    } else if (values.lag_buy_2 < 0) {
        errors.lag_buy_2 = "Can be fractional number (positive or negative) or 0";
    }
    if (!values.lag_buy_3 && values.lag_buy_3 !== 0) {
        errors.lag_buy_3 = "Required";
    } else if (values.lag_buy_3 < 0) {
        errors.lag_buy_3 = "Can be fractional number (positive or negative) or 0";
    }
    if (!values.leverage && values.leverage !== 0) {
        errors.leverage = "Required";
    }
    if (!values.side) {
        errors.side = "Required";
    }
    return errors;
};
