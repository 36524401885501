import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, NavLink } from "react-router-dom";

import { postLogout } from "../../actions/auth";

import logout_icon from "../../assets/image/logout_icon.png";

import "./Head.scss";

class Head extends Component {
    logout = () => {
        const { history, postLogout } = this.props;
        postLogout().then(() => {
            localStorage.removeItem("token");
            history.push("/auth");
        });
    };

    render() {
        return (
            <header className="header_wrapper">
                <Link to="/main/split" className="logo">
                    <div>trading</div>
                    <span>bot</span>
                </Link>
                <div className="header_wrapper_menu">
                    <NavLink
                        to="/main/dashboard"
                        isActive={(match, location) => location.pathname.includes("/dashboard")}
                    >
                        dashboard
                    </NavLink>
                    <NavLink to="/main/split" isActive={(match, location) => location.pathname.includes("/split")}>
                        split
                    </NavLink>
                    <NavLink
                        className="hide_on_mob"
                        to="/main/ip"
                        isActive={(match, location) =>
                            location.pathname.includes("/main/ip") ||
                            location.pathname.includes("/create-trader") ||
                            location.pathname.includes("/item-trader")
                        }
                    >
                        ip
                    </NavLink>
                    <NavLink
                        className="hide_on_mob"
                        to="/main/m"
                        isActive={(match, location) =>
                            location.pathname.includes("/main/m") || location.pathname.includes("/create-m-trader")
                        }
                    >
                        m
                    </NavLink>
                    <NavLink className="hide_on_mob" to="/main/servers">servers</NavLink>
                </div>
                <button className="log_out" onClick={this.logout}>
                    <img src={logout_icon} alt="logout_icon" />
                    <span>Logout</span>
                </button>
            </header>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            postLogout
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Head);
