import React, { useState, useEffect } from "react";

import DialogComponent from "../../HelperComponents/DialogComponent/DialogComponent";
import CustomSelectComponent from "../../HelperComponents/CustomSelectComponent/CustomSelectComponent";

import AddIcon from "../../../assets/image/add_icon_blue.png";

import "./Rules.scss";
import { translateCondition } from "../../HelperComponents/functions";

export const Rules = ({
    addRule,
    currentRule,
    editRule,
    deleteRule,
    handleRuleEditing,
    handleRuleDeleting,
    quadrants,
    isEdit
}) => {
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [q1_index, setQ1Index] = useState(
        currentRule["q1_index"] ? { label: `Q${currentRule["q1_index"]}`, value: currentRule["q1_index"] } : null
    );
    const [q2_index, setQ2Index] = useState(
        currentRule["q2_index"]
            ? { label: `Q${currentRule["q2_index"]}`, value: currentRule["q2_index"] }
            : currentRule["condition"]
            ? { label: "manual", value: "" }
            : null
    );
    console.log(currentRule);
    const [condition, setCondition] = useState(
        currentRule["condition"]
            ? { label: translateCondition(currentRule["condition"]), value: currentRule["condition"] }
            : { label: translateCondition("ge"), value: "ge" }
    );
    const [value, setValue] = useState(currentRule["value"] || "");
    const quadrantsList = quadrants.map(({ index }) => ({ label: `Q${index}`, value: index }));
    const conditionsList = [
        { label: translateCondition("gt"), value: "gt" },
        { label: translateCondition("ge"), value: "ge" },
        { label: translateCondition("le"), value: "le" },
        { label: translateCondition("lt"), value: "lt" }
    ];
    const buttonTitle =
        !q1_index || (!q2_index && !condition) || !condition
            ? "Fill in the fields above"
            : q2_index && q1_index["value"] === q2_index["value"]
            ? "Comparing to itself is potentially pointless"
            : "";

    useEffect(() => {
        if (currentRule.hasOwnProperty("condition")) {
            setQ1Index({ label: `Q${currentRule["q1_index"]}`, value: currentRule["q1_index"] });
            setQ2Index(
                currentRule.hasOwnProperty("value")
                    ? { label: "manual", value: "" }
                    : { label: `Q${currentRule["q2_index"]}`, value: currentRule["q2_index"] }
            );
            setCondition({ label: translateCondition(currentRule["condition"]), value: currentRule["condition"] });
            setValue(currentRule["value"]);
        }
    }, [currentRule]);

    useEffect(() => {
        if (q2_index) {
            if (q2_index["value"]) {
                setValue("");
            }
        }
    }, [q2_index]);

    const renderFields = () => {
        return (
            <div className="fields">
                <div>
                    <CustomSelectComponent
                        value={q1_index}
                        options={quadrantsList}
                        change={setQ1Index}
                        isClearable="false"
                        isSearchable={false}
                        placeholder="Select…"
                    />
                </div>
                <div>
                    <CustomSelectComponent
                        value={condition}
                        options={conditionsList}
                        change={setCondition}
                        isClearable="false"
                        isSearchable={false}
                        placeholder=">="
                    />
                </div>
                <div>
                    <CustomSelectComponent
                        value={q2_index}
                        options={[{ label: "manual", value: "" }, ...quadrantsList]}
                        change={setQ2Index}
                        isClearable="false"
                        isSearchable={false}
                        placeholder="Select…"
                    />
                </div>
                <div>
                    <span>Specific value</span>
                    <div>
                        <input
                            type="number"
                            value={value}
                            placeholder="Type…"
                            onChange={e => {
                                setValue(e.target.value);
                                setQ2Index({ label: "manual", value: "" });
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            {isEdit && (
                <div
                    className="add_rule"
                    onClick={() => {
                        setQ1Index(null);
                        setQ2Index(null);
                        setCondition({ label: translateCondition("ge"), value: "ge" });
                        setValue("");
                        setAddDialogOpen(true);
                    }}
                >
                    <img src={AddIcon} alt="add" /> add rule
                </div>
            )}
            <DialogComponent open={addDialogOpen} onClose={() => setAddDialogOpen(false)}>
                <div className="rules_dialog">
                    <div className="title">Add rule</div>
                    {renderFields()}
                    <hr />
                    <div className="dialog_brn_wrapper">
                        <button className="btn_grey" onClick={() => setAddDialogOpen(false)}>
                            Cancel
                        </button>
                        <button
                            className="btn_blue"
                            onClick={() => {
                                addRule({
                                    q1_index: q1_index["value"],
                                    q2_index: q2_index["value"],
                                    condition: condition["value"],
                                    value
                                });
                                setAddDialogOpen(false);
                            }}
                            disabled={!!buttonTitle}
                            title={buttonTitle}
                        >
                            Add
                        </button>
                    </div>
                </div>
            </DialogComponent>
            <DialogComponent open={editRule} onClose={() => handleRuleEditing(null)}>
                <div className="rules_dialog">
                    <div className="title">Edit Rule_{currentRule["key"]}</div>
                    {renderFields()}
                    <hr />
                    <div className="dialog_brn_wrapper">
                        <button className="btn_grey" onClick={() => handleRuleEditing(null)}>
                            Cancel
                        </button>
                        <button
                            className="btn_blue"
                            onClick={() =>
                                handleRuleEditing({
                                    q1_index: q1_index["value"],
                                    q2_index: q2_index["value"],
                                    condition: condition["value"],
                                    value
                                })
                            }
                            disabled={!!buttonTitle}
                            title={buttonTitle}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </DialogComponent>
            <DialogComponent open={deleteRule} onClose={() => handleRuleDeleting(null)}>
                <div className="rules_dialog">
                    <div className="title">Delete Rule_{currentRule["key"]}</div>
                    <div className="descriptions">Are you sure you want to delete this rule?</div>
                    <hr />
                    <div className="dialog_brn_wrapper">
                        <button className="btn_grey" onClick={() => handleRuleDeleting(null)}>
                            Cancel
                        </button>
                        <button
                            className="btn_blue"
                            onClick={() => handleRuleDeleting(currentRule)}
                            disabled={!!buttonTitle}
                            title={buttonTitle}
                        >
                            Delete
                        </button>
                    </div>
                </div>
            </DialogComponent>
        </>
    );
};
