import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import clsx from "clsx";

import RadioFieldBlue from "../../HelperComponents/RadioField/RadioFieldBlue/RadioFieldBlue";
import {
    getTRKTraderCreationInfo,
    patchTRKTrader,
    postManualTradeTRK,
    postStartTRKTrader,
    postStopTRKTrader
} from "../../../actions/trkActions";
import SelectComponent from "../../HelperComponents/SelectComponent/SelectComponent";
import RenderField from "../../HelperComponents/RenderField/RenderField";
import TooltipMessage from "../../HelperComponents/TooltipMessage/TooltipMessage";
import { normalizeNumber } from "../../HelperComponents/functions";
import Loader from "../../HelperComponents/Loader/Loader";

import arrow from "../../../assets/image/arrow.png";
import errorIcon from "../../../assets/image/error.svg";

import "./CreationInfo.scss";

class CreationInfo extends Component {
    state = {
        isEdit: false,
        loading: true,
        symbolOptions: [
            {
                label: "BTC",
                value: "BTC"
            },
            {
                label: "ETH",
                value: "ETH"
            },
            {
                label: "XRP",
                value: "XRP"
            }
        ],
        amount: ""
    };

    customReturnDeultValue = (option, value) => {
        let defaultValue = null;
        for (let i = 0; i < option.length; i++) {
            if (typeof value === "boolean") {
                if (value === true) {
                    defaultValue = option[0];
                } else {
                    defaultValue = option[1];
                }
            } else if (option[i].label.toLowerCase() === value.toLowerCase()) {
                defaultValue = option[i];
            }
        }
        return defaultValue;
    };

    handleChangeRadio = e => {
        const {
            trader_creation_info: { id },
            postStartTRKTrader,
            postStopTRKTrader
        } = this.props;
        let value = e.target.value;
        if (value === "on") {
            postStartTRKTrader(id);
        } else {
            postStopTRKTrader(id);
        }
    };

    handleEdit = e => {
        const { toggleEdit } = this.props;
        let evt = e || window.event;
        if (evt.preventDefault) {
            evt.preventDefault();
        } else {
            evt.returnValue = false;
            evt.cancelBubble = true;
        }
        this.setState({ isEdit: !this.state.isEdit });
        toggleEdit(true);
    };

    handleManualTrade = () => {
        const {
            trader_creation_info: { id },
            postManualTradeTRK
        } = this.props;
        const { amount } = this.state;
        postManualTradeTRK(id, { value: amount });
    };

    submitForm = data => {
        const {
            patchTRKTrader,
            getTRKTraderCreationInfo,
            trader_creation_info: { id },
            toggleEdit
        } = this.props;
        patchTRKTrader(id, data).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                getTRKTraderCreationInfo(id).then(res => {
                    if (res.payload && res.payload.status && res.payload.status === 200) {
                        this.handleEdit();
                        toggleEdit(false);
                    }
                });
            }
        });
    };

    // handleManualTrade = () => {
    //     const { trader_creation_info: { id, manual_trade_url }, amount } = this.props;
    //     this.sendRequest(`${manual_trade_url}`, "post", { value: amount })
    //         .then((response) => response.json())
    //         .then((data) => {
    //             console.log(data);
    //         });
    // };
    //
    // sendRequest = (url, method, body) => {
    //     const options = {
    //         method: method,
    //         headers: {
    //             Authorization: "Token " + localStorage.getItem("token"),
    //             "Content-Type": "application/json"
    //         },
    //         body: JSON.stringify(body)
    //     };
    //     return fetch(url, options);
    // };

    render() {
        const {
            trader_creation_info: {
                // is_master,
                name,
                TrK_adj,
                master_name,
                exchange,
                CurrentQty,
                CurrentQty_options,
                symbol,
                depo,
                master_2_name,
                before,
                after,
                up_lev_1,
                up_lev_2,
                down_lev_1,
                down_lev_2,
                zero_rounding,
                slave_rounding,
                show_manual_trade,
                master_accounts
            },
            handleSubmit
        } = this.props;
        const { isEdit, symbolOptions, amount } = this.state;
        if (!name) return <Loader />;
        const CurrentQtyOptions = CurrentQty_options.map(({ name, value }) => ({ label: name, value }));
        const MasterOptions = master_accounts.map(({ id, name }) => ({ label: name, value: id }));
        let amountIsValid = false;
        if (exchange === "bitmex") {
            amountIsValid =
                (amount || +amount === 0) &&
                ((!!+amount && Math.abs(amount) <= 4) ||
                    +amount === 0 ||
                    amount.toLowerCase() === "x" ||
                    amount.toLowerCase() === "х");
        } else {
            amountIsValid = (amount || +amount === 0) && ((!!+amount && Math.abs(amount) <= 4) || +amount === 0);
        }
        return (
            <div className="trk_creation_info">
                <form onSubmit={handleSubmit(this.submitForm)}>
                    <div>
                        <div>
                            <div className="title">General</div>
                            <div>
                                <span>Name</span>
                                {isEdit ? (
                                    <Field name="name" component={RenderField} placeholder="Type here" />
                                ) : (
                                    <p>{name}</p>
                                )}
                            </div>
                            <div>
                                <span>Master</span>
                                {isEdit ? (
                                    <Field
                                        name="master"
                                        component={SelectComponent}
                                        options={[{ label: "-", value: null }, ...MasterOptions]}
                                        defaultValue={this.customReturnDeultValue(MasterOptions, master_name || "")}
                                    />
                                ) : (
                                    <p>{master_name || "-"}</p>
                                )}
                            </div>
                            <div>
                                <span>Symbol</span>
                                {isEdit ? (
                                    <Field
                                        name="symbol"
                                        component={SelectComponent}
                                        options={symbolOptions}
                                        defaultValue={this.customReturnDeultValue(symbolOptions, symbol)}
                                    />
                                ) : (
                                    <p>{symbol}</p>
                                )}
                            </div>
                            <div>
                                <span>Exchange</span>
                                <p>{exchange || "-"}</p>
                            </div>
                            <div>
                                <span>Depo</span>
                                {isEdit ? (
                                    <Field
                                        name="depo"
                                        type="text"
                                        component={RenderField}
                                        normalize={normalizeNumber}
                                    />
                                ) : (
                                    <p>{depo}</p>
                                )}
                            </div>
                            <div>
                                <span>CurrentQty</span>
                                {isEdit ? (
                                    <Field
                                        name="CurrentQty"
                                        component={SelectComponent}
                                        options={CurrentQtyOptions}
                                        defaultValue={this.customReturnDeultValue(CurrentQtyOptions, CurrentQty)}
                                    />
                                ) : (
                                    <p>{CurrentQty}</p>
                                )}
                            </div>
                            <div>
                                <span>TrK_adj</span>
                                {isEdit ? (
                                    <Field
                                        name="TrK_adj"
                                        type="text"
                                        component={RenderField}
                                        normalize={normalizeNumber}
                                    />
                                ) : (
                                    <p>{TrK_adj}</p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div className="title">15M</div>
                            <div>
                                <span>Master2</span>
                                {isEdit ? (
                                    <Field
                                        name="master_2"
                                        component={SelectComponent}
                                        options={[{ label: "-", value: null }, ...MasterOptions]}
                                        defaultValue={this.customReturnDeultValue(MasterOptions, master_2_name || "")}
                                    />
                                ) : (
                                    <p>{master_2_name || "-"}</p>
                                )}
                            </div>
                            <div>
                                <span>Before</span>
                                {isEdit ? (
                                    <Field
                                        name="before"
                                        type="text"
                                        component={RenderField}
                                        normalize={normalizeNumber}
                                    />
                                ) : (
                                    <p>{before}</p>
                                )}
                            </div>
                            <div>
                                <span>After</span>
                                {isEdit ? (
                                    <Field
                                        name="after"
                                        type="text"
                                        component={RenderField}
                                        normalize={normalizeNumber}
                                    />
                                ) : (
                                    <p>{after}</p>
                                )}
                            </div>
                            <div>
                                <span>Up_lev1</span>
                                {isEdit ? (
                                    <Field
                                        name="up_lev_1"
                                        type="text"
                                        component={RenderField}
                                        normalize={normalizeNumber}
                                    />
                                ) : (
                                    <p>{up_lev_1}</p>
                                )}
                            </div>
                            <div>
                                <span>Up_lev2</span>
                                {isEdit ? (
                                    <Field
                                        name="up_lev_2"
                                        type="text"
                                        component={RenderField}
                                        normalize={normalizeNumber}
                                    />
                                ) : (
                                    <p>{up_lev_2}</p>
                                )}
                            </div>
                            <div>
                                <span>Down_lev1</span>
                                {isEdit ? (
                                    <Field
                                        name="down_lev_1"
                                        type="text"
                                        component={RenderField}
                                        normalize={normalizeNumber}
                                    />
                                ) : (
                                    <p>{down_lev_1}</p>
                                )}
                            </div>
                            <div>
                                <span>Down_lev2</span>
                                {isEdit ? (
                                    <Field
                                        name="down_lev_2"
                                        type="text"
                                        component={RenderField}
                                        normalize={normalizeNumber}
                                    />
                                ) : (
                                    <p>{down_lev_2}</p>
                                )}
                            </div>
                            <div>
                                <span>Zero_rounding</span>
                                {isEdit ? (
                                    <Field
                                        name="zero_rounding"
                                        type="text"
                                        component={RenderField}
                                        normalize={normalizeNumber}
                                    />
                                ) : (
                                    <p>{zero_rounding}</p>
                                )}
                            </div>
                            <div>
                                <span>Slave_rounding</span>
                                {isEdit ? (
                                    <Field
                                        name="slave_rounding"
                                        type="text"
                                        component={RenderField}
                                        normalize={normalizeNumber}
                                    />
                                ) : (
                                    <p>{slave_rounding}</p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div className="title">Status</div>
                            <div className="switch_block">
                                <Field
                                    name="status"
                                    component={RadioFieldBlue}
                                    arrRadio={[
                                        { value: "on", label: "On" },
                                        { value: "off", label: "Off" }
                                    ]}
                                    change={this.handleChangeRadio}
                                />
                            </div>
                            <br />
                            {show_manual_trade && (
                                <div>
                                    <span>Manual trade</span>
                                    <div className="manual_trade_input">
                                        <input
                                            type="text"
                                            value={amount}
                                            onChange={e => this.setState({ amount: e.target.value })}
                                        />
                                        {(amount || +amount === 0) && !amountIsValid && (
                                            <TooltipMessage
                                                text={
                                                    exchange === "bitmex"
                                                        ? "Float (мах 4) or X/x RU & ENG"
                                                        : "Float only (мах 4)"
                                                }
                                                delay={200}
                                                error
                                            >
                                                <img src={errorIcon} alt="error" />
                                            </TooltipMessage>
                                        )}
                                    </div>
                                    <div
                                        className={clsx("execute_button", !amountIsValid && "inactive")}
                                        onClick={amountIsValid ? this.handleManualTrade : () => console.log("nope")}
                                    >
                                        Execute
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    {/*{!is_master && (*/}
                    {/*    <Fragment>*/}
                    {!isEdit ? (
                        <button className="creation_info_btn" onClick={this.handleEdit}>
                            EDIT
                        </button>
                    ) : (
                        <button className="creation_info_btn" onClick={isEdit ? null : this.handleEdit} type="submit">
                            SAVE
                            <img src={arrow} alt="arrow" />
                        </button>
                    )}
                    {/*</Fragment>*/}
                    {/*)}*/}
                </form>
            </div>
        );
    }
}

export const validate = values => {
    const errors = {};

    if (!values.name) {
        errors.name = "Required";
    }
    if (!values.TrK_adj) {
        errors.TrK_adj = "Required";
    }
    if (!values.CurrentQty) {
        errors.CurrentQty = "Required";
    }

    return errors;
};

CreationInfo = reduxForm({
    form: "TRKCreationInfoForm",
    validate,
    enableReinitialize: true
})(CreationInfo);

function mapStateToProps({ trk }) {
    return {
        initialValues: {
            status: trk.trader_creation_info && trk.trader_creation_info.is_stopped ? "off" : "on",
            name: trk.trader_creation_info.name || "",
            TrK_adj: trk.trader_creation_info.TrK_adj,
            CurrentQty: trk.trader_creation_info.CurrentQty,
            symbol: trk.trader_creation_info.symbol,
            depo: trk.trader_creation_info.depo,
            master_2_name: trk.trader_creation_info.master_2_name,
            before: trk.trader_creation_info.before,
            after: trk.trader_creation_info.after,
            up_lev_1: trk.trader_creation_info.up_lev_1,
            up_lev_2: trk.trader_creation_info.up_lev_2,
            down_lev_1: trk.trader_creation_info.down_lev_1,
            down_lev_2: trk.trader_creation_info.down_lev_2,
            zero_rounding: trk.trader_creation_info.zero_rounding,
            slave_rounding: trk.trader_creation_info.slave_rounding
        },
        trader_creation_info: trk.trader_creation_info
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            patchTRKTrader,
            postManualTradeTRK,
            postStartTRKTrader,
            postStopTRKTrader,
            getTRKTraderCreationInfo
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreationInfo));
