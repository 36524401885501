export const normalizeNumber = value => {
    if (!value) {
        return value;
    }
    let newVal = value
        .replace(/^[^\d-]|(-)\./g, "$1")
        .replace(/[^\d.-]/g, "")
        .replace(/(.+)-/g, "$1")
        .replace(",", ".")
        .replace(/^([^.]*\.)|\./g, "$1");

    if (newVal.charAt(0) === "0" && newVal.charAt(1) !== "." && newVal.length >= 2) {
        return newVal.replace("0", "");
    } else {
        return newVal;
    }
};

export const normalizeTextNumber = value => value.toString();

export const translateCondition = condition => {
    switch (condition) {
        case "gt":
            return ">";
        case "ge":
            return ">=";
        case "le":
            return "<=";
        case "lt":
            return "<";
        default:
            return "";
    }
};

export const roundNumber = unrounded_number => {
    let roundedNumber;
    roundedNumber = unrounded_number.toFixed(2);
    if (roundedNumber === "-0.00") {
        roundedNumber = "0.00";
    }
    return roundedNumber;
};

export const roundNumberToFixedOne = unrounded_number => {
    let roundedNumber;
    roundedNumber = unrounded_number.toFixed(1);
    if (roundedNumber === "-0.00") {
        roundedNumber = "0.00";
    }
    return roundedNumber;
};

export const splitByCommas = data => data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const formatPrice = (price, symbol) => {
    if (!price && price !== 0) return "-";
    if (symbol === "XRP") return splitByCommas(price.toFixed(3));
    if (symbol === "BTC" || symbol === "ETH") return splitByCommas(price.toFixed(0));
    return splitByCommas(price.toFixed(2));
};
