import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm, change } from "redux-form";
import RadioFieldBlue from "../../HelperComponents/RadioField/RadioFieldBlue/RadioFieldBlue";
import { stopTrader, startTrader, getOptions, changeTrader, getTrader } from "../../../actions/traderAction";
import SelectComponent from "../../HelperComponents/SelectComponent/SelectComponent";
import RenderField from "../../HelperComponents/RenderField/RenderField";
import RadioFieldGrey from "../../HelperComponents/RadioField/RadioFieldGrey/RadioFieldGrey";
import { normalizeNumber, normalizeTextNumber } from "../../HelperComponents/functions";
import { validate } from "../../Pages/IP/CreateTrader/validate";
import arrow from "../../../assets/image/arrow.png";
import "./CreationInfo.scss";

class CreationInfo extends Component {
    state = {
        isEdit: false,
        order_type: [
            {
                label: "Market",
                value: "m"
            },
            {
                label: "Limit",
                value: "l"
            }
        ],
        symbol: [],
        server_type: [],
        comp: [
            {
                label: "yes",
                value: true
            },
            {
                label: "no",
                value: false
            }
        ],
        side: [
            {
                label: "buy",
                value: "Buy"
            },
            {
                label: "sell",
                value: "Sell"
            }
        ],
        projectsList: [
            {
                label: "Bitmex",
                value: "BitMex"
            }
        ],
        pullbackBaseList: [
            {
                label: "bprice",
                value: "bprice"
            },
            {
                label: "close",
                value: "close"
            }
        ],
        net: [
            {
                label: "live",
                value: "live"
            },
            {
                label: "testnet",
                value: "testnet"
            }
        ],
        funding: [
            {
                label: "On",
                value: "On"
            },
            {
                label: "Off",
                value: "Off"
            }
        ],
        priority: [
            {
                label: "On",
                value: "On"
            },
            {
                label: "Off",
                value: "Off"
            }
        ],
        ohlc: [
            {
                label: "open",
                value: "o"
            },
            {
                label: "low",
                value: "l"
            },
            {
                label: "high",
                value: "h"
            },
            {
                label: "close",
                value: "c"
            }
        ],
        met: [
            {
                label: "avg",
                value: "avg"
            },
            {
                label: "max",
                value: "max"
            },
            {
                label: "min",
                value: "min"
            }
        ],
        take_profit_fix: true,
        stop_loss_fix: true,
        sideChange: "",
        formula_switch: true,
        signal_a_switch: true,
        signal_b_switch: true,
        signal_c_switch: true,
        signal_d_switch: true
    };

    componentDidMount() {
        const {
            getOptions,
            initialValues: { take_profit_fix, stop_loss_fix, signal_a, signal_b, signal_c, signal_d, formula }
        } = this.props;
        getOptions().then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                let symbol = [];
                let server_type = [];
                res.payload.data.symbols.forEach(el => symbol.push({ label: el.label, value: el.value }));
                res.payload.data.server_types.forEach(el => server_type.push({ label: el.label, value: el.value }));
                this.setState({ server_type, symbol, loading: false });
            }
        });
        this.setState({
            take_profit_fix: take_profit_fix ? true : false,
            stop_loss_fix: stop_loss_fix ? true : false,
            formula_switch: formula === "On" ? false : true,
            signal_a_switch: signal_a === "On" ? false : true,
            signal_b_switch: signal_b === "On" ? false : true,
            signal_c_switch: signal_c === "On" ? false : true,
            signal_d_switch: signal_d === "On" ? false : true
        });
    }

    handleChangeRadio = e => {
        const {
            trade: { id },
            stopTrader,
            startTrader
        } = this.props;
        let value = e.target.value;
        if (value === "on") {
            startTrader(id);
        } else {
            stopTrader(id);
        }
    };

    handleEdit = e => {
        const { toggleEdit } = this.props;
        let evt = e || window.event;
        if (evt.preventDefault) {
            evt.preventDefault();
        } else {
            evt.returnValue = false;
            evt.cancelBubble = true;
        }
        this.setState({ isEdit: !this.state.isEdit });
        toggleEdit(true);
    };

    submitForm = data => {
        const {
            changeTrader,
            getTrader,
            trade: { id },
            toggleEdit
        } = this.props;
        if (data.funding === "On") {
            data.funding = true;
        } else {
            data.funding = false;
        }

        changeTrader(id, data).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                getTrader(id).then(res => {
                    if (res.payload && res.payload.status && res.payload.status === 200) {
                        this.handleEdit();
                        toggleEdit(false);
                    }
                });
            }
        });
    };

    customReturnDeultValue = (option, value) => {
        let defaultValue = null;
        for (let i = 0; i < option.length; i++) {
            if (typeof value === "boolean") {
                if (value === true) {
                    defaultValue = option[0];
                } else {
                    defaultValue = option[1];
                }
            } else if (option[i].label.toLowerCase() === value.toLowerCase()) {
                defaultValue = option[i];
            }
        }
        return defaultValue;
    };

    profitFixHandle = e => {
        e.stopPropagation();
        if (e.target.value === "take_profit_fix") {
            this.setState({
                take_profit_fix: true
            });
            this.props.dispatch(change("CreationInfoForm", "take_profit_factor", null));
        } else {
            this.setState({
                take_profit_fix: false
            });
            this.props.dispatch(change("CreationInfoForm", "take_profit_fix", null));
        }
    };

    lossFix = e => {
        e.stopPropagation();
        if (e.target.value === "stop_loss_fix") {
            this.setState({ stop_loss_fix: true });
            this.props.dispatch(change("CreationInfoForm", "stop_loss_factor", null));
        } else {
            this.setState({ stop_loss_fix: false });
            this.props.dispatch(change("CreationInfoForm", "stop_loss_fix", null));
        }
    };

    handleChange = selectedOption => {
        this.setState({ sideChange: selectedOption });
    };

    checkStatus = () => {
        const {
            initialValues: { formula }
        } = this.props;
        // let status = name === 'Signal_a' ? signal_a : name === 'Signal_b' ? signal_b : name === 'Signal_c' ? signal_c : signal_d;
        return formula === "On";
    };

    changeFormulaCheck = e => {
        let value = e.target.value;
        if (value === "On") {
            this.setState({ formula_switch: false });
        } else {
            this.setState({ formula_switch: true });
            // this.props.dispatch(change('CreationInfoForm', 'x_offset', null));
            // this.props.dispatch(change('CreationInfoForm', 'x_ohlc', null));
            // this.props.dispatch(change('CreationInfoForm', 'x_met', null));
            // this.props.dispatch(change('CreationInfoForm', 'y_offset', null));
            // this.props.dispatch(change('CreationInfoForm', 'y_ohlc', null));
            // this.props.dispatch(change('CreationInfoForm', 'y_met', null));
            // this.props.dispatch(change('CreationInfoForm', 'z_offset', null));
            // this.props.dispatch(change('CreationInfoForm', 'z_ohlc', null));
            // this.props.dispatch(change('CreationInfoForm', 'z_met', null));
            // this.props.dispatch(change('CreationInfoForm', 'formula_from', null));
            // this.props.dispatch(change('CreationInfoForm', 'formula_to', null));
        }
    };

    renderOHLC = ohlc => {
        switch (ohlc) {
            case "o":
                return "open";
            case "l":
                return "low";
            case "h":
                return "high";
            case "c":
                return "close";
            default:
                return "-";
        }
    };

    renderMet = met => {
        switch (met) {
            case "avg":
                return "avg";
            case "max":
                return "max";
            case "min":
                return "min";
            default:
                return "-";
        }
    };

    render() {
        const {
            trade: { creation_info },
            handleSubmit
        } = this.props;
        const {
            isEdit,
            order_type,
            symbol,
            server_type,
            comp,
            side,
            projectsList,
            pullbackBaseList,
            net,
            funding,
            priority,
            ohlc,
            met,
            take_profit_fix,
            stop_loss_fix,
            sideChange,
            formula_switch
        } = this.state;
        return (
            <div className="creation_info">
                <form onSubmit={handleSubmit(this.submitForm)}>
                    <div>
                        <div>
                            <div className="title">Strategy</div>
                            <div>
                                <span>Order_type</span>
                                {/*<p>{creation_info.strategy.strategy}</p>*/}

                                {isEdit ? (
                                    <Field
                                        name="buy_order_type"
                                        component={SelectComponent}
                                        options={order_type}
                                        defaultValue={this.customReturnDeultValue(
                                            order_type,
                                            creation_info.strategy.buy_order_type_display
                                        )}
                                    />
                                ) : (
                                    <p>{creation_info.strategy.buy_order_type_display}</p>
                                )}
                            </div>
                            <div>
                                <span>Symbol</span>
                                {/*<p>{creation_info.strategy.symbol}</p>*/}
                                {isEdit ? (
                                    <Field
                                        name="symbol"
                                        component={SelectComponent}
                                        options={symbol}
                                        defaultValue={this.customReturnDeultValue(
                                            symbol,
                                            creation_info.strategy.symbol
                                        )}
                                    />
                                ) : (
                                    <p>{creation_info.strategy.symbol}</p>
                                )}
                            </div>
                            <div>
                                <span>Trader_name</span>
                                {isEdit ? (
                                    <Field name="name" component={RenderField} placeholder="Type here" />
                                ) : (
                                    <p>{creation_info.strategy.trader_name}</p>
                                )}
                            </div>
                            <div>
                                <span>Server_type</span>
                                {isEdit ? (
                                    <Field
                                        name="server_type"
                                        component={SelectComponent}
                                        options={server_type}
                                        defaultValue={this.customReturnDeultValue(
                                            server_type,
                                            creation_info.strategy.server_type_display
                                        )}
                                    />
                                ) : (
                                    <p>{creation_info.strategy.server_type_display}</p>
                                )}
                            </div>
                            <div>
                                <span>Priority</span>
                                {isEdit ? (
                                    <Field
                                        name="priority"
                                        component={SelectComponent}
                                        options={priority}
                                        defaultValue={this.customReturnDeultValue(
                                            priority,
                                            creation_info.strategy.priority
                                        )}
                                    />
                                ) : (
                                    <p>{creation_info.strategy.priority ? "On" : "Off"}</p>
                                )}
                            </div>
                        </div>
                        <div>
                            {isEdit ? (
                                <div className="signal_wrapper">
                                    <div className="title">Formula</div>
                                    <div className="switch_signal">
                                        <Field
                                            name="formula"
                                            component={RadioFieldGrey}
                                            arrRadio={[{ value: "On" }, { value: "Off" }]}
                                            change={this.changeFormulaCheck}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="signal_wrapper">
                                    <div className="title">Formula</div>
                                    <span>{this.checkStatus() ? "On" : "Off"}</span>
                                </div>
                            )}
                            <div>
                                <span>x_offset</span>
                                {isEdit ? (
                                    <Field
                                        name="x_offset"
                                        type="number"
                                        placeholder="Set value here"
                                        parse={val => (!val ? "" : parseInt(val, 10))}
                                        component={RenderField}
                                        isDisabled={formula_switch}
                                    />
                                ) : (
                                    <p>{creation_info.formula.formula ? creation_info.formula.x_offset : ""}</p>
                                )}
                            </div>
                            <div>
                                <span>x_ohlc</span>
                                {isEdit ? (
                                    <Field
                                        name="x_ohlc"
                                        component={SelectComponent}
                                        options={ohlc}
                                        defaultValue={this.customReturnDeultValue(
                                            ohlc,
                                            this.renderOHLC(creation_info.formula.x_ohlc)
                                        )}
                                        isDisabled={formula_switch}
                                    />
                                ) : (
                                    <p>
                                        {creation_info.formula.formula
                                            ? this.renderOHLC(creation_info.formula.x_ohlc)
                                            : ""}
                                    </p>
                                )}
                            </div>
                            <div>
                                <span>x_met</span>
                                {isEdit ? (
                                    <Field
                                        name="x_met"
                                        component={SelectComponent}
                                        options={met}
                                        defaultValue={this.customReturnDeultValue(
                                            met,
                                            this.renderMet(creation_info.formula.x_met)
                                        )}
                                        isDisabled={formula_switch}
                                    />
                                ) : (
                                    <p>
                                        {creation_info.formula.formula
                                            ? this.renderMet(creation_info.formula.x_met)
                                            : ""}
                                    </p>
                                )}
                            </div>
                            <div>
                                <span>y_offset</span>
                                {isEdit ? (
                                    <Field
                                        name="y_offset"
                                        type="number"
                                        placeholder="Set value here"
                                        parse={val => (!val ? "" : parseInt(val, 10))}
                                        component={RenderField}
                                        isDisabled={formula_switch}
                                    />
                                ) : (
                                    <p>{creation_info.formula.formula ? creation_info.formula.y_offset : ""}</p>
                                )}
                            </div>
                            <div>
                                <span>y_ohlc</span>
                                {isEdit ? (
                                    <Field
                                        name="y_ohlc"
                                        component={SelectComponent}
                                        options={ohlc}
                                        defaultValue={this.customReturnDeultValue(
                                            ohlc,
                                            this.renderOHLC(creation_info.formula.y_ohlc)
                                        )}
                                        isDisabled={formula_switch}
                                    />
                                ) : (
                                    <p>
                                        {creation_info.formula.formula
                                            ? this.renderOHLC(creation_info.formula.y_ohlc)
                                            : ""}
                                    </p>
                                )}
                            </div>
                            <div>
                                <span>y_met</span>
                                {isEdit ? (
                                    <Field
                                        name="y_met"
                                        component={SelectComponent}
                                        options={met}
                                        defaultValue={this.customReturnDeultValue(
                                            met,
                                            this.renderMet(creation_info.formula.y_met)
                                        )}
                                        isDisabled={formula_switch}
                                    />
                                ) : (
                                    <p>
                                        {creation_info.formula.formula
                                            ? this.renderMet(creation_info.formula.y_met)
                                            : ""}
                                    </p>
                                )}
                            </div>
                            <div>
                                <span>z_offset</span>
                                {isEdit ? (
                                    <Field
                                        name="z_offset"
                                        type="number"
                                        placeholder="Set value here"
                                        parse={val => (!val ? "" : parseInt(val, 10))}
                                        component={RenderField}
                                        isDisabled={formula_switch}
                                    />
                                ) : (
                                    <p>{creation_info.formula.formula ? creation_info.formula.z_offset : ""}</p>
                                )}
                            </div>
                            <div>
                                <span>z_ohlc</span>
                                {isEdit ? (
                                    <Field
                                        name="z_ohlc"
                                        component={SelectComponent}
                                        options={ohlc}
                                        defaultValue={this.customReturnDeultValue(
                                            ohlc,
                                            this.renderOHLC(creation_info.formula.z_ohlc)
                                        )}
                                        isDisabled={formula_switch}
                                    />
                                ) : (
                                    <p>
                                        {creation_info.formula.formula
                                            ? this.renderOHLC(creation_info.formula.z_ohlc)
                                            : ""}
                                    </p>
                                )}
                            </div>
                            <div>
                                <span>z_met</span>
                                {isEdit ? (
                                    <Field
                                        name="z_met"
                                        component={SelectComponent}
                                        options={met}
                                        defaultValue={this.customReturnDeultValue(
                                            met,
                                            this.renderMet(creation_info.formula.z_met)
                                        )}
                                        isDisabled={formula_switch}
                                    />
                                ) : (
                                    <p>
                                        {creation_info.formula.formula
                                            ? this.renderMet(creation_info.formula.z_met)
                                            : ""}
                                    </p>
                                )}
                            </div>
                            <div>
                                <span>Formula</span>
                                {isEdit ? (
                                    <div>
                                        <Field
                                            name="formula_from"
                                            type="text"
                                            component={RenderField}
                                            placeholder="from"
                                            normalize={normalizeNumber}
                                            isDisabled={formula_switch}
                                        />
                                        <Field
                                            name="formula_to"
                                            type="text"
                                            component={RenderField}
                                            placeholder="to"
                                            normalize={normalizeNumber}
                                            isDisabled={formula_switch}
                                        />
                                    </div>
                                ) : (
                                    <div>
                                        <p>{creation_info.formula.formula ? creation_info.formula.formula_from : ""}</p>
                                        <p>{creation_info.formula.formula ? creation_info.formula.formula_to : ""}</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div className="title">Trigger_select</div>
                            <div>
                                <span>Max_trades</span>
                                {isEdit ? (
                                    <Field
                                        name="max_trades"
                                        type="number"
                                        parse={val => (!val ? "" : parseInt(val, 10))}
                                        component={RenderField}
                                    />
                                ) : (
                                    <p>{creation_info.trigger_select.max_trades}</p>
                                )}
                            </div>
                            <div>
                                <span>Frequency</span>
                                {isEdit ? (
                                    <Field
                                        name="frequency"
                                        type="number"
                                        parse={val => (!val ? "" : parseInt(val, 10))}
                                        component={RenderField}
                                    />
                                ) : (
                                    <p>{creation_info.trigger_select.frequency}</p>
                                )}
                            </div>
                            <div>
                                <span>Funding</span>
                                {isEdit ? (
                                    <Field
                                        name="timing"
                                        component={SelectComponent}
                                        options={funding}
                                        defaultValue={this.customReturnDeultValue(
                                            funding,
                                            creation_info.trigger_select.timing
                                        )}
                                    />
                                ) : (
                                    <p className="later">{creation_info.trigger_select.timing ? "On" : "Off"}</p>
                                )}
                            </div>
                            <div>
                                <span>Multi_entry</span>
                                {isEdit ? (
                                    <Field
                                        name="multi_entry"
                                        type="number"
                                        parse={val => (!val ? "" : parseInt(val, 10))}
                                        component={RenderField}
                                    />
                                ) : (
                                    <p className="later">{creation_info.trigger_select.multi_entry}</p>
                                )}
                            </div>
                            <div>
                                <span>Cooldown_int</span>
                                {isEdit ? (
                                    <Field
                                        name="cooldown_int"
                                        type="number"
                                        parse={val => (!val ? "" : parseInt(val, 10))}
                                        component={RenderField}
                                    />
                                ) : (
                                    <p>{creation_info.trigger_select.cooldown_int}</p>
                                )}
                            </div>
                            <div>
                                <span>Cooldown_num</span>
                                {isEdit ? (
                                    <Field
                                        name="cooldown_num"
                                        type="number"
                                        parse={val => (!val ? "" : parseInt(val, 10))}
                                        component={RenderField}
                                    />
                                ) : (
                                    <p>{creation_info.trigger_select.cooldown_num}</p>
                                )}
                            </div>
                            {/*  <div>
                                <span>Other_rules</span>
                                <p className="later">{creation_info.trigger_select.other_rules}</p>
                            </div>*/}
                        </div>
                        <div>
                            <div className="title">Status</div>
                            <div className="switch_block">
                                {/*<label>
                                <RadioFieldBlue name="dzen" value="off"/>
                                on
                            </label>
                            <label>
                                <RadioFieldBlue name="dzen" value="on" />
                                off
                            </label>*/}
                                <Field
                                    name="status"
                                    component={RadioFieldBlue}
                                    arrRadio={[
                                        { value: "on", label: "on" },
                                        { value: "off", label: "off" }
                                    ]}
                                    change={this.handleChangeRadio}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div className="title">Trader_setup</div>
                            {isEdit ? (
                                <div className="testClass">
                                    <Field
                                        name="take_profit_fix_value"
                                        component={RadioFieldGrey}
                                        arrRadio={[{ value: "take_profit_fix" }, { value: "take_profit_factor" }]}
                                        change={this.profitFixHandle}
                                    />
                                    <div className="arrInput">
                                        <Field
                                            name="take_profit_fix"
                                            type="text"
                                            component={RenderField}
                                            isCheck={!take_profit_fix}
                                            placeholder="Set value here"
                                            isDisabled={!take_profit_fix}
                                            normalize={normalizeNumber}
                                        />
                                        <Field
                                            name="take_profit_factor"
                                            type="text"
                                            component={RenderField}
                                            isCheck={take_profit_fix}
                                            placeholder="Set value here"
                                            isDisabled={take_profit_fix}
                                            normalize={normalizeNumber}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <span>
                                        {creation_info.trader_setup.take_profit_factor
                                            ? "Take_profit_factor"
                                            : "Take_profit_fix"}
                                    </span>
                                    <p>
                                        {creation_info.trader_setup.take_profit_factor
                                            ? creation_info.trader_setup.take_profit_factor
                                            : creation_info.trader_setup.take_profit_fix}
                                    </p>
                                </div>
                            )}
                            {/*<div>*/}
                            {/*    <span>Take_profit_custom</span>*/}
                            {/*    <p className="later">{creation_info.trader_setup.take_profit_custom}</p>*/}
                            {/*</div>*/}
                            {isEdit ? (
                                <div className="testClass">
                                    <Field
                                        name="stop_loss_fix_value"
                                        component={RadioFieldGrey}
                                        arrRadio={[{ value: "stop_loss_fix" }, { value: "stop_loss_factor" }]}
                                        change={this.lossFix}
                                    />
                                    <div className="arrInput">
                                        <Field
                                            name="stop_loss_fix"
                                            type="text"
                                            component={RenderField}
                                            isCheck={!stop_loss_fix}
                                            placeholder="Set value here"
                                            isDisabled={!stop_loss_fix}
                                            normalize={normalizeNumber}
                                        />
                                        <Field
                                            name="stop_loss_factor"
                                            type="text"
                                            component={RenderField}
                                            isCheck={stop_loss_fix}
                                            placeholder="Set value here"
                                            isDisabled={stop_loss_fix}
                                            normalize={normalizeNumber}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <span>
                                        {creation_info.trader_setup.stop_loss_factor
                                            ? "Stop_loss_factor"
                                            : "Stop_loss_fix"}
                                    </span>
                                    <p>
                                        {creation_info.trader_setup.stop_loss_factor
                                            ? creation_info.trader_setup.stop_loss_factor
                                            : creation_info.trader_setup.stop_loss_fix}
                                    </p>
                                </div>
                            )}
                            {/*<div>*/}
                            {/*    <span>Stop_loss_custom</span>*/}
                            {/*    <p className="later">{creation_info.trader_setup.stop_loss_custom}</p>*/}
                            {/*</div>*/}
                        </div>
                        <div>
                            <div className="title">Lags</div>
                            <div>
                                <span>lag_fund</span>
                                {isEdit ? (
                                    <Field
                                        name="lag_fund"
                                        type="number"
                                        component={RenderField}
                                        parse={val => (!val ? "" : parseInt(val, 10))}
                                        placeholder="Set value here"
                                        normalize={normalizeTextNumber}
                                    />
                                ) : (
                                    <p>{creation_info.lags.lag_fund}</p>
                                )}
                            </div>
                            <div>
                                <span>lag_buy_1</span>
                                {isEdit ? (
                                    <Field
                                        name="lag_buy_1"
                                        type="number"
                                        component={RenderField}
                                        placeholder="Set value here"
                                        normalize={normalizeTextNumber}
                                    />
                                ) : (
                                    <p>{creation_info.lags.lag_buy_1}</p>
                                )}
                            </div>
                            <div>
                                <span>lag_buy_2</span>
                                {isEdit ? (
                                    <Field
                                        name="lag_buy_2"
                                        type="number"
                                        component={RenderField}
                                        placeholder="Set value here"
                                        normalize={normalizeTextNumber}
                                    />
                                ) : (
                                    <p>{creation_info.lags.lag_buy_2}</p>
                                )}
                            </div>
                            <div>
                                <span>lag_buy_3</span>
                                {isEdit ? (
                                    <Field
                                        name="lag_buy_3"
                                        type="number"
                                        component={RenderField}
                                        placeholder="Set value here"
                                        normalize={normalizeTextNumber}
                                    />
                                ) : (
                                    <p>{creation_info.lags.lag_buy_3}</p>
                                )}
                            </div>
                            <div>
                                <span>lag_sell_1</span>
                                {isEdit ? (
                                    <Field
                                        name="lag_sell_1"
                                        type="number"
                                        component={RenderField}
                                        parse={val => (!val ? "" : parseInt(val, 10))}
                                        placeholder="Set value here"
                                        normalize={normalizeTextNumber}
                                    />
                                ) : (
                                    <p>{creation_info.lags.lag_sell_1}</p>
                                )}
                            </div>
                            <div>
                                <span>lag_sell_2</span>
                                {isEdit ? (
                                    <Field
                                        name="lag_sell_2"
                                        type="number"
                                        component={RenderField}
                                        parse={val => (!val ? "" : parseInt(val, 10))}
                                        placeholder="Set value here"
                                        normalize={normalizeTextNumber}
                                    />
                                ) : (
                                    <p>{creation_info.lags.lag_sell_2}</p>
                                )}
                            </div>
                            <div>
                                <span>lag_sell_3</span>
                                {isEdit ? (
                                    <Field
                                        name="lag_sell_3"
                                        type="number"
                                        component={RenderField}
                                        parse={val => (!val ? "" : parseInt(val, 10))}
                                        placeholder="Set value here"
                                        normalize={normalizeTextNumber}
                                    />
                                ) : (
                                    <p>{creation_info.lags.lag_sell_3}</p>
                                )}
                            </div>
                            <div>
                                <span>lag_sl_1</span>
                                {isEdit ? (
                                    <Field
                                        name="lag_sl_1"
                                        type="number"
                                        component={RenderField}
                                        parse={val => (!val ? "" : parseInt(val, 10))}
                                        placeholder="Set value here"
                                        normalize={normalizeTextNumber}
                                    />
                                ) : (
                                    <p>{creation_info.lags.lag_sl_1}</p>
                                )}
                            </div>
                            <div>
                                <span>lag_sl_2</span>
                                {isEdit ? (
                                    <Field
                                        name="lag_sl_2"
                                        type="number"
                                        component={RenderField}
                                        parse={val => (!val ? "" : parseInt(val, 10))}
                                        placeholder="Set value here"
                                        normalize={normalizeTextNumber}
                                    />
                                ) : (
                                    <p>{creation_info.lags.lag_sl_2}</p>
                                )}
                            </div>
                            <div>
                                <span>lag_sl_3</span>
                                {isEdit ? (
                                    <Field
                                        name="lag_sl_3"
                                        type="number"
                                        component={RenderField}
                                        parse={val => (!val ? "" : parseInt(val, 10))}
                                        placeholder="Set value here"
                                        normalize={normalizeTextNumber}
                                    />
                                ) : (
                                    <p>{creation_info.lags.lag_sl_3}</p>
                                )}
                            </div>
                        </div>

                        <div>
                            <div className="title">Trader_inputs</div>
                            <div>
                                <span>Timeframe</span>
                                {isEdit ? (
                                    <Field
                                        name="time_frame"
                                        type="number"
                                        component={RenderField}
                                        parse={val => (!val ? "" : parseInt(val, 10))}
                                        placeholder="Set value here"
                                        normalize={normalizeTextNumber}
                                    />
                                ) : (
                                    <p className="later">{creation_info.trader_inputs.time_frame}</p>
                                )}
                            </div>
                            <div>
                                <span>Exchange</span>
                                {isEdit ? (
                                    <Field
                                        name="exchange"
                                        component={SelectComponent}
                                        options={projectsList}
                                        defaultValue={this.customReturnDeultValue(
                                            projectsList,
                                            creation_info.trader_inputs.exchange
                                        )}
                                    />
                                ) : (
                                    <p>{creation_info.trader_inputs.exchange}</p>
                                )}
                            </div>
                            <div>
                                <span>Pullback_base</span>
                                {isEdit ? (
                                    <Field
                                        name="pullback_base"
                                        component={SelectComponent}
                                        options={pullbackBaseList}
                                        defaultValue={this.customReturnDeultValue(
                                            pullbackBaseList,
                                            creation_info.trader_inputs.pullback_base
                                        )}
                                    />
                                ) : (
                                    <p>{creation_info.trader_inputs.pullback_base}</p>
                                )}
                            </div>
                            <div>
                                <span>Pullback</span>
                                {isEdit ? (
                                    <Field
                                        name="pullback"
                                        type="text"
                                        component={RenderField}
                                        placeholder="Set value here"
                                        normalize={normalizeNumber}
                                    />
                                ) : (
                                    <p className="later">{creation_info.trader_inputs.pullback}</p>
                                )}
                            </div>
                            <div>
                                <span>comp</span>
                                {isEdit ? (
                                    <Field
                                        name="comp"
                                        component={SelectComponent}
                                        options={comp}
                                        onChange={e => this.handleChange(e.value)}
                                        defaultValue={this.customReturnDeultValue(
                                            comp,
                                            creation_info.trader_inputs.comp
                                        )}
                                    />
                                ) : (
                                    <p className="">{creation_info.trader_inputs.comp ? "yes" : "no"}</p>
                                )}
                            </div>
                            <div>
                                <span>OrderQty</span>
                                {isEdit ? (
                                    <Field
                                        name="orderQty"
                                        type="text"
                                        component={RenderField}
                                        placeholder="Set value here"
                                        normalize={normalizeNumber}
                                    />
                                ) : (
                                    <p className="later">{creation_info.trader_inputs.orderQty}</p>
                                )}
                            </div>
                            <div>
                                <span>Side</span>
                                {isEdit ? (
                                    <Field
                                        name="side"
                                        component={SelectComponent}
                                        options={side}
                                        onChange={e => this.handleChange(e.value)}
                                        defaultValue={this.customReturnDeultValue(
                                            side,
                                            creation_info.trader_inputs.side
                                        )}
                                    />
                                ) : (
                                    <p className="">{creation_info.trader_inputs.side.toLowerCase()}</p>
                                )}
                            </div>
                            <div>
                                <span>Net</span>
                                {isEdit ? (
                                    <Field
                                        name="net"
                                        component={SelectComponent}
                                        options={net}
                                        defaultValue={this.customReturnDeultValue(net, creation_info.trader_inputs.net)}
                                    />
                                ) : (
                                    <p className="">{creation_info.trader_inputs.net}</p>
                                )}
                            </div>
                            <div>
                                <span>Reverse_side</span>
                                {isEdit ? (
                                    <Field
                                        name="reverse_side"
                                        type="text"
                                        component={RenderField}
                                        placeholder="sell"
                                        defaultValue={
                                            sideChange === "Buy" ? "sell" : sideChange === "Sell" ? "buy" : ""
                                        }
                                        isDisabled={true}
                                    />
                                ) : (
                                    <p className="later">{creation_info.trader_inputs.reverse_side}</p>
                                )}
                            </div>
                        </div>
                    </div>
                    {!isEdit ? (
                        <button className="creation_info_btn" onClick={this.handleEdit}>
                            EDIT
                        </button>
                    ) : (
                        <button className="creation_info_btn" onClick={isEdit ? null : this.handleEdit} type="submit">
                            SAVE
                            <img src={arrow} alt="arrow" />
                        </button>
                    )}
                </form>
            </div>
        );
    }
}

CreationInfo = reduxForm({
    form: "CreationInfoForm",
    validate
})(CreationInfo);

function mapStateToProps({ trade }) {
    return {
        initialValues: {
            status:
                trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.status.status
                    ? "on"
                    : "off",
            name: trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.strategy.trader_name,
            symbol: trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.strategy.symbol,
            server_type:
                trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.strategy.server_type,
            priority:
                trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.strategy.priority
                    ? "on"
                    : "off",
            buy_order_type:
                trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.strategy.buy_order_type,
            daily_from:
                trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.signal_a.daily_from,
            daily_to: trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.signal_a.daily_to,
            upper_from:
                trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.signal_a.upper_from,
            upper_to: trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.signal_a.upper_to,
            real_from:
                trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.signal_a.real_from,
            real_to: trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.signal_a.real_to,
            lower_from:
                trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.signal_a.lower_from,
            lower_to: trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.signal_a.lower_to,
            hourly_from:
                trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.signal_a.hourly_from,
            hourly_to:
                trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.signal_a.hourly_to,

            formula:
                trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.formula.formula
                    ? "On"
                    : "Off",
            x_offset: trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.formula.x_offset,
            x_ohlc: trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.formula.x_ohlc,
            x_met: trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.formula.x_met,
            y_offset: trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.formula.y_offset,
            y_ohlc: trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.formula.y_ohlc,
            y_met: trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.formula.y_met,
            z_offset: trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.formula.z_offset,
            z_ohlc: trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.formula.z_ohlc,
            z_met: trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.formula.z_met,
            formula_from:
                trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.formula.formula_from,
            formula_to:
                trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.formula.formula_to,

            signal_a:
                trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.signal_a.signal_a
                    ? "On"
                    : "Off",
            momentum_price_from:
                trade &&
                trade.trade &&
                trade.trade.creation_info &&
                trade.trade.creation_info.signal_b.momentum_price_from,
            momentum_price_to:
                trade &&
                trade.trade &&
                trade.trade.creation_info &&
                trade.trade.creation_info.signal_b.momentum_price_to,
            momentum_price_minutes:
                trade &&
                trade.trade &&
                trade.trade.creation_info &&
                trade.trade.creation_info.signal_b.momentum_price_minutes,

            momentum_upper_from:
                trade &&
                trade.trade &&
                trade.trade.creation_info &&
                trade.trade.creation_info.signal_b.momentum_upper_from,
            momentum_upper_to:
                trade &&
                trade.trade &&
                trade.trade.creation_info &&
                trade.trade.creation_info.signal_b.momentum_upper_to,
            momentum_upper_minutes:
                trade &&
                trade.trade &&
                trade.trade.creation_info &&
                trade.trade.creation_info.signal_b.momentum_upper_minutes,

            momentum_lower_from:
                trade &&
                trade.trade &&
                trade.trade.creation_info &&
                trade.trade.creation_info.signal_b.momentum_lower_from,
            momentum_lower_to:
                trade &&
                trade.trade &&
                trade.trade.creation_info &&
                trade.trade.creation_info.signal_b.momentum_lower_to,
            momentum_lower_minutes:
                trade &&
                trade.trade &&
                trade.trade.creation_info &&
                trade.trade.creation_info.signal_b.momentum_lower_minutes,

            momentum_hourly_from:
                trade &&
                trade.trade &&
                trade.trade.creation_info &&
                trade.trade.creation_info.signal_b.momentum_hourly_from,
            momentum_hourly_to:
                trade &&
                trade.trade &&
                trade.trade.creation_info &&
                trade.trade.creation_info.signal_b.momentum_hourly_to,
            momentum_hourly_minutes:
                trade &&
                trade.trade &&
                trade.trade.creation_info &&
                trade.trade.creation_info.signal_b.momentum_hourly_minutes,

            momentum_daily_from:
                trade &&
                trade.trade &&
                trade.trade.creation_info &&
                trade.trade.creation_info.signal_b.momentum_daily_from,
            momentum_daily_to:
                trade &&
                trade.trade &&
                trade.trade.creation_info &&
                trade.trade.creation_info.signal_b.momentum_daily_to,
            momentum_daily_minutes:
                trade &&
                trade.trade &&
                trade.trade.creation_info &&
                trade.trade.creation_info.signal_b.momentum_daily_minutes,

            signal_b:
                trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.signal_b.signal_b
                    ? "On"
                    : "Off",

            daily_from_2:
                trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.signal_c.daily_from_2,
            daily_to_2:
                trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.signal_c.daily_to_2,
            upper_from_2:
                trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.signal_c.upper_from_2,
            upper_to_2:
                trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.signal_c.upper_to_2,
            real_from_2:
                trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.signal_c.real_from_2,
            real_to_2:
                trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.signal_c.real_to_2,
            lower_from_2:
                trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.signal_c.lower_from_2,
            lower_to_2:
                trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.signal_c.lower_to_2,
            hourly_from_2:
                trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.signal_c.hourly_from_2,
            hourly_to_2:
                trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.signal_c.hourly_to_2,
            signal_c:
                trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.signal_c.signal_c
                    ? "On"
                    : "Off",

            momentum_price_from_2:
                trade &&
                trade.trade &&
                trade.trade.creation_info &&
                trade.trade.creation_info.signal_d.momentum_price_from_2,
            momentum_price_to_2:
                trade &&
                trade.trade &&
                trade.trade.creation_info &&
                trade.trade.creation_info.signal_d.momentum_price_to_2,
            momentum_price_minutes_2:
                trade &&
                trade.trade &&
                trade.trade.creation_info &&
                trade.trade.creation_info.signal_d.momentum_price_minutes_2,

            momentum_upper_from_2:
                trade &&
                trade.trade &&
                trade.trade.creation_info &&
                trade.trade.creation_info.signal_d.momentum_upper_from_2,
            momentum_upper_to_2:
                trade &&
                trade.trade &&
                trade.trade.creation_info &&
                trade.trade.creation_info.signal_d.momentum_upper_to_2,
            momentum_upper_minutes_2:
                trade &&
                trade.trade &&
                trade.trade.creation_info &&
                trade.trade.creation_info.signal_d.momentum_upper_minutes_2,

            momentum_lower_from_2:
                trade &&
                trade.trade &&
                trade.trade.creation_info &&
                trade.trade.creation_info.signal_d.momentum_lower_from_2,
            momentum_lower_to_2:
                trade &&
                trade.trade &&
                trade.trade.creation_info &&
                trade.trade.creation_info.signal_d.momentum_lower_to_2,
            momentum_lower_minutes_2:
                trade &&
                trade.trade &&
                trade.trade.creation_info &&
                trade.trade.creation_info.signal_d.momentum_lower_minutes_2,

            momentum_hourly_from_2:
                trade &&
                trade.trade &&
                trade.trade.creation_info &&
                trade.trade.creation_info.signal_d.momentum_hourly_from_2,
            momentum_hourly_to_2:
                trade &&
                trade.trade &&
                trade.trade.creation_info &&
                trade.trade.creation_info.signal_d.momentum_hourly_to_2,
            momentum_hourly_minutes_2:
                trade &&
                trade.trade &&
                trade.trade.creation_info &&
                trade.trade.creation_info.signal_d.momentum_hourly_minutes_2,

            momentum_daily_from_2:
                trade &&
                trade.trade &&
                trade.trade.creation_info &&
                trade.trade.creation_info.signal_d.momentum_daily_from_2,
            momentum_daily_to_2:
                trade &&
                trade.trade &&
                trade.trade.creation_info &&
                trade.trade.creation_info.signal_d.momentum_daily_to_2,
            momentum_daily_minutes_2:
                trade &&
                trade.trade &&
                trade.trade.creation_info &&
                trade.trade.creation_info.signal_d.momentum_daily_minutes_2,
            signal_d:
                trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.signal_d.signal_d
                    ? "On"
                    : "Off",

            max_trades:
                trade &&
                trade.trade &&
                trade.trade.creation_info &&
                trade.trade.creation_info.trigger_select.max_trades,
            frequency:
                trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.trigger_select.frequency,
            multi_entry:
                trade &&
                trade.trade &&
                trade.trade.creation_info &&
                trade.trade.creation_info.trigger_select.multi_entry,
            cooldown_int:
                trade &&
                trade.trade &&
                trade.trade.creation_info &&
                trade.trade.creation_info.trigger_select.cooldown_int,
            cooldown_num:
                trade &&
                trade.trade &&
                trade.trade.creation_info &&
                trade.trade.creation_info.trigger_select.cooldown_num,
            lag_fund: trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.lags.lag_fund,
            lag_buy_1: trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.lags.lag_buy_1,
            lag_buy_2: trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.lags.lag_buy_2,
            lag_buy_3: trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.lags.lag_buy_3,
            lag_sell_1: trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.lags.lag_sell_1,
            lag_sell_2: trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.lags.lag_sell_2,
            lag_sell_3: trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.lags.lag_sell_3,
            lag_sl_1: trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.lags.lag_sl_1,
            lag_sl_2: trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.lags.lag_sl_2,
            lag_sl_3: trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.lags.lag_sl_3,
            time_frame:
                trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.trader_inputs.time_frame,
            pullback:
                trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.trader_inputs.pullback,
            orderQty:
                trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.trader_inputs.orderQty,
            exchange:
                trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.trader_inputs.exchange,
            pullback_base:
                trade &&
                trade.trade &&
                trade.trade.creation_info &&
                trade.trade.creation_info.trader_inputs.pullback_base,
            comp: trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.trader_inputs.comp,
            side: trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.trader_inputs.side,
            net: trade && trade.trade && trade.trade.creation_info && trade.trade.creation_info.trader_inputs.net,
            reverse_side:
                trade &&
                trade.trade &&
                trade.trade.creation_info &&
                trade.trade.creation_info.trader_inputs.reverse_side,

            take_profit_fix_value:
                trade &&
                trade.trade &&
                trade.trade.creation_info &&
                trade.trade.creation_info.trader_setup.take_profit_fix
                    ? "take_profit_fix"
                    : "take_profit_factor",
            stop_loss_fix_value:
                trade &&
                trade.trade &&
                trade.trade.creation_info &&
                trade.trade.creation_info.trader_setup.stop_loss_fix
                    ? "stop_loss_fix"
                    : "stop_loss_factor",

            take_profit_fix:
                trade &&
                trade.trade &&
                trade.trade.creation_info &&
                trade.trade.creation_info.trader_setup.take_profit_fix,
            take_profit_factor:
                trade &&
                trade.trade &&
                trade.trade.creation_info &&
                trade.trade.creation_info.trader_setup.take_profit_factor,
            stop_loss_fix:
                trade &&
                trade.trade &&
                trade.trade.creation_info &&
                trade.trade.creation_info.trader_setup.stop_loss_fix,
            stop_loss_factor:
                trade &&
                trade.trade &&
                trade.trade.creation_info &&
                trade.trade.creation_info.trader_setup.stop_loss_factor
        }
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            stopTrader,
            startTrader,
            getOptions,
            changeTrader,
            getTrader
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(CreationInfo);
