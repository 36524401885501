import React, { Component, Fragment } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import RenderField from "../../HelperComponents/RenderField/RenderField";
import arrow from "../../../assets/image/arrow.png";
import { postLogin } from "../../../actions/auth";

class Login extends Component {
    state = {
        loading: false,
        isAuth: true
    };

    submitForm = data => {
        const { postLogin, history } = this.props;
        this.setState({ loading: true, isAuth: true });
        let obj = { ...data };
        postLogin(obj).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                localStorage.token = res.payload.data.token;
                history.push("/main");
            } else {
                this.setState({ loading: false, isAuth: false });
            }
        });
    };

    render() {
        const { handleSubmit, submitting, pristine, valid } = this.props;
        const { isAuth } = this.state;
        return (
            <Fragment>
                <div className="auth_wrapper">
                    <div className="auth-block_head">
                        Welcome to <br />
                        <div>
                            <span className="blue_text">trading</span>
                            <p>bot</p>
                        </div>
                    </div>
                    <div className="blue_line">
                        <hr />
                    </div>
                    <form className="form_start" onSubmit={handleSubmit(this.submitForm)}>
                        <Field name="email" type="text" component={RenderField} label="Email" />
                        <Field name="password" type="password" component={RenderField} label="Password" password />
                        <div className="btn_wrapper">
                            <button className="auth_btn" type="submit" disabled={submitting || pristine || !valid}>
                                log in
                                <img src={arrow} alt="arrow" />
                            </button>
                        </div>
                        {!isAuth ? <p className="output_error">Неправильный логин или пароль</p> : null}
                    </form>
                </div>
            </Fragment>
        );
    }
}

/*const validate = values => {
    const errors = {};
    if (!values.email) {
        errors.email = 'Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(values.email)) {
        errors.email = 'Invalid email'
    }
    if (!values.password) {
        errors.password = 'Required'
    } else if (values.password < 8) {
        errors.password = 'Must be 8 characters or more'
    }

    return errors
};*/

Login = reduxForm({
    form: "LoginForm"
    // validate
})(Login);

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            postLogin
        },
        dispatch
    );
}

export default connect(null, mapDispatchToProps)(Login);
