import * as types from "./constants";

export function getServers() {
    return {
        type: types.GET_SERVERS,
        payload: {
            client: "default",
            request: {
                url: "/servers/",
                method: "get"
            }
        }
    };
}
