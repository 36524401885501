import React from "react";
import Dialog from "@material-ui/core/Dialog";
import "./DialogComponent.scss";
const DialogComponent = ({ open, onClose, children, longDialog }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            classes={{
                root: "default_dialog_root",
                paper: "paper_custom_dialog"
            }}
        >
            <div className={longDialog ? "dialog_wrapper dialog_wrapper_long" : "dialog_wrapper"}>{children}</div>
        </Dialog>
    );
};

export default DialogComponent;
