import * as types from "../actions/constants";

const INITIAL_STATE = {
    trade_lists: [],
    trade_archived: [],
    total: {},
    trade: {},
    loading: true,
    error: null
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.GET_TRADERS:
            return { ...state, loading: true };
        case types.GET_TRADERS_SUCCESS:
            return {
                ...state,
                trade_lists: action.payload.data.traders.sort((a, b) => {
                    if (
                        b.name
                            .charAt(0)
                            .toLowerCase()
                            .charCodeAt() -
                            a.name
                                .charAt(0)
                                .toLowerCase()
                                .charCodeAt() ===
                        0
                    ) {
                        return (
                            a.name
                                .charAt(1)
                                .toLowerCase()
                                .charCodeAt() -
                            b.name
                                .charAt(1)
                                .toLowerCase()
                                .charCodeAt()
                        );
                    } else {
                        return (
                            a.name
                                .charAt(0)
                                .toLowerCase()
                                .charCodeAt() -
                            b.name
                                .charAt(0)
                                .toLowerCase()
                                .charCodeAt()
                        );
                    }
                }),
                total: action.payload.data.total,
                loading: false
            };
        case types.GET_TRADER_SUCCESS:
            return { ...state, trade: action.payload.data, loading: false };
        case types.TRADER_ARCHIVE_SUCCESS:
            return {
                ...state,
                trade_lists: state.trade_lists.map(el =>
                    el.id === action.payload.data.id ? { ...el, is_archived: true, status: "off" } : el
                )
            };
        case types.TRADER_UN_ARCHIVE_SUCCESS:
            return {
                ...state,
                trade_lists: state.trade_lists.map(el =>
                    el.id === action.payload.data.id ? { ...el, is_archived: false, status: "on" } : el
                )
            };
        case types.START_TRADER_SUCCESS:
            state.trade.details.status.status = "on";
            state.trade.creation_info.status.status = true;
            return {
                ...state,
                trade_lists: state.trade_lists.map(el =>
                    el.id === action.payload.data.id ? { ...el, status: action.payload.data.details.status.status } : el
                )
            };
        case types.STOP_TRADER_SUCCESS:
            state.trade.details.status.status = "off";
            state.trade.creation_info.status.status = false;
            return {
                ...state,
                trade_lists: state.trade_lists.map(el =>
                    el.id === action.payload.data.id ? { ...el, status: action.payload.data.details.status.status } : el
                )
            };
        /*       case types.STOP_TRADER_SUCCESS:
                           state.trade.details.status.status = 'off';
                           state.trade.creation_info.status.status = false;
                           return state;*/
        case types.SORT_TRADERS:
            let tempArr;
            switch (action.paramsSort) {
                case "winrate":
                    tempArr = action.isSorted
                        ? state.trade_lists.sort((a, b) => a.win_rate - b.win_rate)
                        : state.trade_lists.sort((a, b) => b.win_rate - a.win_rate);
                    break;
                case "trades":
                    tempArr = action.isSorted
                        ? state.trade_lists.sort((a, b) => a.trades - b.trades)
                        : state.trade_lists.sort((a, b) => b.trades - a.trades);
                    break;
                case "return":
                    tempArr = action.isSorted
                        ? state.trade_lists.sort((a, b) => a.return_percent - b.return_percent)
                        : state.trade_lists.sort((a, b) => b.return_percent - a.return_percent);
                    break;
                case "average":
                    tempArr = action.isSorted
                        ? state.trade_lists.sort((a, b) => a.average_percent - b.average_percent)
                        : state.trade_lists.sort((a, b) => b.average_percent - a.average_percent);
                    break;
                case "tp":
                    tempArr = action.isSorted
                        ? state.trade_lists.sort((a, b) => a.tp.percent - b.tp.percent)
                        : state.trade_lists.sort((a, b) => b.tp.percent - a.tp.percent);
                    break;
                case "u_won":
                    tempArr = action.isSorted
                        ? state.trade_lists.sort((a, b) => a.u_won.percent - b.u_won.percent)
                        : state.trade_lists.sort((a, b) => b.u_won.percent - a.u_won.percent);
                    break;
                case "u_lost":
                    tempArr = action.isSorted
                        ? state.trade_lists.sort((a, b) => a.u_lost.percent - b.u_lost.percent)
                        : state.trade_lists.sort((a, b) => b.u_lost.percent - a.u_lost.percent);
                    break;
                case "sl":
                    tempArr = action.isSorted
                        ? state.trade_lists.sort((a, b) => a.sl.percent - b.sl.percent)
                        : state.trade_lists.sort((a, b) => b.sl.percent - a.sl.percent);
                    break;
                case "net":
                    tempArr = action.isSorted
                        ? state.trade_lists.sort((a, b) => {
                              return (
                                  a.net
                                      .charAt(0)
                                      .toLowerCase()
                                      .charCodeAt() -
                                  b.net
                                      .charAt(0)
                                      .toLowerCase()
                                      .charCodeAt()
                              );
                          })
                        : state.trade_lists.sort((a, b) => {
                              return (
                                  b.net
                                      .charAt(0)
                                      .toLowerCase()
                                      .charCodeAt() -
                                  a.net
                                      .charAt(0)
                                      .toLowerCase()
                                      .charCodeAt()
                              );
                          });
                    break;
                case "open":
                    tempArr = action.isSorted
                        ? state.trade_lists.sort((a, b) => a.open - b.open)
                        : state.trade_lists.sort((a, b) => b.open - a.open);
                    break;
                case "trader":
                    tempArr = action.isSorted
                        ? state.trade_lists.sort((a, b) => {
                              if (
                                  a.name
                                      .charAt(0)
                                      .toLowerCase()
                                      .charCodeAt() -
                                      b.name
                                          .charAt(0)
                                          .toLowerCase()
                                          .charCodeAt() ===
                                  0
                              ) {
                                  return (
                                      b.name
                                          .charAt(1)
                                          .toLowerCase()
                                          .charCodeAt() -
                                      a.name
                                          .charAt(1)
                                          .toLowerCase()
                                          .charCodeAt()
                                  );
                              } else {
                                  return (
                                      a.name
                                          .charAt(0)
                                          .toLowerCase()
                                          .charCodeAt() -
                                      b.name
                                          .charAt(0)
                                          .toLowerCase()
                                          .charCodeAt()
                                  );
                              }
                          })
                        : state.trade_lists.sort((a, b) => {
                              console.log(
                                  b.name
                                      .charAt(1)
                                      .toLowerCase()
                                      .charCodeAt(),
                                  a.name
                                      .charAt(1)
                                      .toLowerCase()
                                      .charCodeAt()
                              );
                              if (
                                  b.name
                                      .charAt(0)
                                      .toLowerCase()
                                      .charCodeAt() -
                                      a.name
                                          .charAt(0)
                                          .toLowerCase()
                                          .charCodeAt() ===
                                  0
                              ) {
                                  return (
                                      a.name
                                          .charAt(1)
                                          .toLowerCase()
                                          .charCodeAt() -
                                      b.name
                                          .charAt(1)
                                          .toLowerCase()
                                          .charCodeAt()
                                  );
                              } else {
                                  return (
                                      b.name
                                          .charAt(0)
                                          .toLowerCase()
                                          .charCodeAt() -
                                      a.name
                                          .charAt(0)
                                          .toLowerCase()
                                          .charCodeAt()
                                  );
                              }
                          });
                    break;

                default:
                    return { ...state };
            }
            return { ...state, trade_lists: tempArr };
        case types.PATCH_CHANGE_TRADER_SUCCESS:
            return { ...state };
        case types.GET_TRADERS_FAIL:
            return { ...state, error: action.error.data };
        default:
            return state;
    }
}
