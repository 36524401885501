import * as types from "../actions/constants";

const INITIAL_STATE = {
    trk_list: { list: [], totals: {} },
    trk_trader: {},
    tags: [],
    trader_creation_info: {},
    trader_details: {}
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.GET_TRK_LIST_SUCCESS:
            return { ...state, trk_list: action.payload.data };
        case types.GET_TRK_TRADER_SUCCESS:
            return { ...state, trk_trader: action.payload.data };
        case types.GET_TRK_TRADER_CREATION_INFO_SUCCESS:
            return { ...state, trader_creation_info: action.payload.data };
        case types.GET_TRK_TRADER_DETAILS_SUCCESS:
            return { ...state, trader_details: action.payload.data };
        case types.GET_TAGS_SUCCESS:
            return { ...state, tags: action.payload.data };
        default:
            return state;
    }
}
