import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { reducer as formReducer } from "redux-form";
import reduceAuth from "./reduceAuth";
import reduceTrade from "./reduceTrade";
import reduceServers from "./reduceServers";
import TrKReducer from "./reduceTrK";
import MReducer from "./reduceM";

const rootReducer = history =>
    combineReducers({
        form: formReducer,
        router: connectRouter(history),
        auth: reduceAuth,
        trade: reduceTrade,
        server_list: reduceServers,
        trk: TrKReducer,
        m: MReducer
    });

export default rootReducer;
