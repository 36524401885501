let BASE_URL, SECOND_URL;
const host = window && window.location && window.location.host ? window.location.host : "";

if (process.env.REACT_APP_ENV === "production" || host.includes("localhost")) {
    BASE_URL = "https://api.prod.trdb.tech/api/v0";
} else if (process.env.REACT_APP_ENV === "sandbox") {
    BASE_URL = "https://api.sandbox.trdb.tech/api/v0";
} else {
    BASE_URL = "https://api.dev.trdb.tech/api/v0";
}

SECOND_URL = "";
export const API_BASE_URL = BASE_URL;
export const API_SECOND_URL = SECOND_URL;
