import React from "react";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import RadioOn from "../../../../assets/image/active_black.png";
import RadioOff from "../../../../assets/image/inactive.png";
import "./RadioFiledGrey.scss";

const RadioFieldGrey = ({ input, value, change, arrRadio, ...rest }) => {
    return (
        <FormControl
            classes={{
                root: "section_arr_input"
            }}
        >
            <RadioGroup {...input} {...rest} classes={{ root: "custom_radio_group" }}>
                {arrRadio.map((el, index) => {
                    return (
                        <FormControlLabel
                            key={index}
                            value={el.value}
                            checked={el.value}
                            control={
                                <Radio
                                    checkedIcon={<img src={RadioOn} alt="radio on" />}
                                    icon={<img src={RadioOff} alt="radio off" />}
                                />
                            }
                            label={el.value}
                            onChange={change}
                        />
                    );
                })}
            </RadioGroup>
        </FormControl>
    );
};

export default RadioFieldGrey;
