import * as types from "./constants";

export function getTraders(status) {
    return {
        type: types.GET_TRADERS,
        payload: {
            client: "default",
            request: {
                url: status === "all" ? `/dashboard/` : `/dashboard/?net=${status}`,
                method: "get"
            }
        }
    };
}

export function getOptions() {
    return {
        type: types.GET_OPTIONS,
        payload: {
            client: "default",
            request: {
                url: `/trades/creation-choices/`,
                method: "get"
            }
        }
    };
}

export function getTradersArchived() {
    return {
        type: types.GET_TRADERS_ARCHIVED,
        payload: {
            client: "default",
            request: {
                url: `dashboard/?is_archived=true/`,
                method: "get"
            }
        }
    };
}

export function stopTraderAll(data) {
    return {
        type: types.STOP_TRADERS_ALL,
        payload: {
            client: "default",
            request: {
                url: `/traders/stop/`,
                method: "post"
            }
        }
    };
}

export function createTraders(data) {
    return {
        type: types.CREATE_TRADERS,
        payload: {
            client: "default",
            request: {
                url: `/traders/create/`,
                method: "post",
                data
            }
        }
    };
}

export const getTrader = id => {
    return {
        type: types.GET_TRADER,
        payload: {
            client: "default",
            request: {
                url: `/trader/${id}/`,
                method: "get"
            }
        }
    };
};

export const traderArchive = id => {
    return {
        type: types.TRADER_ARCHIVE,
        payload: {
            client: "default",
            request: {
                url: `/trader/${id}/archive/`,
                method: "post"
            }
        }
    };
};

export const traderUnArchive = id => {
    return {
        type: types.TRADER_UN_ARCHIVE,
        payload: {
            client: "default",
            request: {
                url: `/trader/${id}/unarchive/`,
                method: "post"
            }
        }
    };
};

export const sortTraderAction = (paramsSort, isSorted) => {
    return {
        type: types.SORT_TRADERS,
        paramsSort,
        isSorted
    };
};

export const stopTrader = id => {
    return {
        type: types.STOP_TRADER,
        payload: {
            client: "default",
            request: {
                url: `/trader/${id}/stop/`,
                method: "post"
            }
        }
    };
};

export const startTrader = id => {
    return {
        type: types.START_TRADER,
        payload: {
            client: "default",
            request: {
                url: `/trader/${id}/start/`,
                method: "post"
            }
        }
    };
};

export const changeTrader = (id, data) => {
    return {
        type: types.PATCH_CHANGE_TRADER,
        payload: {
            client: "default",
            request: {
                url: `/trader/${id}/`,
                method: "patch",
                data
            }
        }
    };
};
