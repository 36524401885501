import React, { useState, useEffect } from "react";

import DialogComponent from "../../HelperComponents/DialogComponent/DialogComponent";
import CustomSelectComponent from "../../HelperComponents/CustomSelectComponent/CustomSelectComponent";

import AddIcon from "../../../assets/image/add_icon_blue.png";

import "./Quadrants.scss";

export const Quadrants = ({
    addQuadrant,
    currentQuadrant,
    editQuadrant,
    deleteQuadrant,
    unableToDeleteQuadrant,
    handleQuadrantEditing,
    handleQuadrantDeleting,
    handleQuadrantUnableToDelete,
    isEdit
}) => {
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [datapoint, setDatapoint] = useState(
        currentQuadrant["datapoint"]
            ? { label: currentQuadrant["datapoint"], value: currentQuadrant["datapoint"] }
            : { label: "none", value: "" }
    );
    const [from, setFrom] = useState(currentQuadrant["from"] || "");
    const [to, setTo] = useState(currentQuadrant["to"] || "");
    const [metric, setMetric] = useState(
        currentQuadrant["metric"] ? { label: currentQuadrant["metric"], value: currentQuadrant["metric"] } : null
    );
    const datapointsList = [
        { label: "none", value: "" },
        { label: "hourly", value: "hourly" },
        { label: "daily", value: "daily" },
        { label: "real", value: "real" },
        { label: "upper", value: "upper" },
        { label: "lower", value: "lower" }
    ];
    const metricsList = [
        { label: "max", value: "max" },
        { label: "min", value: "min" },
        { label: "avg", value: "avg" }
    ];
    const buttonTitle =
        from === "" || to === "" || !datapoint || !metric
            ? "Fill in the fields above"
            : +from < 0
            ? '"From" can be positive integer or 0'
            : +to < 0
            ? '"To" can be positive integer or 0'
            : +from > +to
            ? '"From" must be less than or equal to "To"'
            : "";

    useEffect(() => {
        if (currentQuadrant.hasOwnProperty("index")) {
            setDatapoint(
                currentQuadrant["datapoint"]
                    ? { label: currentQuadrant["datapoint"], value: currentQuadrant["datapoint"] }
                    : { label: "none", value: "" }
            );
            setFrom(currentQuadrant["from"]);
            setTo(currentQuadrant["to"]);
            setMetric({ label: currentQuadrant["metric"], value: currentQuadrant["metric"] });
        }
    }, [currentQuadrant]);

    const renderFields = () => {
        return (
            <div className="fields">
                <div>
                    <p>Datapoint</p>
                    <CustomSelectComponent
                        value={datapoint}
                        options={datapointsList}
                        change={setDatapoint}
                        isClearable="false"
                        isSearchable={false}
                        placeholder="Select…"
                    />
                </div>
                <div>
                    <p>Array</p>
                    <div>
                        <input type="number" value={from} placeholder="from" onChange={e => setFrom(e.target.value)} />
                        <input type="number" value={to} placeholder="to" onChange={e => setTo(e.target.value)} />
                    </div>
                </div>
                <div>
                    <p>Metric</p>
                    <CustomSelectComponent
                        value={metric}
                        options={metricsList}
                        change={setMetric}
                        isClearable="false"
                        isSearchable={false}
                        placeholder="Select…"
                    />
                </div>
            </div>
        );
    };

    return (
        <>
            {isEdit && (
                <div
                    className="add_quadrant"
                    onClick={() => {
                        setDatapoint({ label: "none", value: "" });
                        setFrom("");
                        setTo("");
                        setMetric(null);
                        setAddDialogOpen(true);
                    }}
                >
                    <img src={AddIcon} alt="add" /> add quadrant
                </div>
            )}
            <DialogComponent open={addDialogOpen} onClose={() => setAddDialogOpen(false)}>
                <div className="quadrants_dialog">
                    <div className="title">Add quadrant</div>
                    {renderFields()}
                    <hr />
                    <div className="dialog_brn_wrapper">
                        <button className="btn_grey" onClick={() => setAddDialogOpen(false)}>
                            Cancel
                        </button>
                        <button
                            className="btn_blue"
                            onClick={() => {
                                addQuadrant({
                                    datapoint: datapoint["value"],
                                    from,
                                    to,
                                    metric: metric["value"]
                                });
                                setAddDialogOpen(false);
                            }}
                            disabled={!!buttonTitle}
                            title={buttonTitle}
                        >
                            Add
                        </button>
                    </div>
                </div>
            </DialogComponent>
            <DialogComponent open={editQuadrant} onClose={() => handleQuadrantEditing(null)}>
                <div className="quadrants_dialog">
                    <div className="title">Edit Q{currentQuadrant["index"]}</div>
                    {renderFields()}
                    <hr />
                    <div className="dialog_brn_wrapper">
                        <button className="btn_grey" onClick={() => handleQuadrantEditing(null)}>
                            Cancel
                        </button>
                        <button
                            className="btn_blue"
                            onClick={() =>
                                handleQuadrantEditing({
                                    datapoint: datapoint["value"],
                                    from,
                                    to,
                                    metric: metric["value"]
                                })
                            }
                            disabled={!!buttonTitle}
                            title={buttonTitle}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </DialogComponent>
            <DialogComponent open={deleteQuadrant} onClose={() => handleQuadrantDeleting(null)}>
                <div className="quadrants_dialog">
                    <div className="title">Delete Q{currentQuadrant["index"]}</div>
                    <div className="descriptions">Are you sure you want to delete this quadrant?</div>
                    <hr />
                    <div className="dialog_brn_wrapper">
                        <button className="btn_grey" onClick={() => handleQuadrantDeleting(null)}>
                            Cancel
                        </button>
                        <button
                            className="btn_blue"
                            onClick={() => handleQuadrantDeleting(currentQuadrant)}
                            disabled={!!buttonTitle}
                            title={buttonTitle}
                        >
                            Delete
                        </button>
                    </div>
                </div>
            </DialogComponent>
            <DialogComponent open={unableToDeleteQuadrant} onClose={() => handleQuadrantUnableToDelete(null)}>
                <div className="quadrants_dialog">
                    <div className="title">Delete Q{currentQuadrant["index"]}</div>
                    <div className="descriptions">
                        Sorry, you can't delete this quadrant as it is already used by one of the rules.
                    </div>
                    <hr />
                    <div className="dialog_brn_wrapper">
                        <button className="btn_blue" onClick={() => handleQuadrantUnableToDelete(null)}>
                            OK
                        </button>
                    </div>
                </div>
            </DialogComponent>
        </>
    );
};
