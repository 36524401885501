import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, change } from "redux-form";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

import RadioFieldBlue from "../../../HelperComponents/RadioField/RadioFieldBlue/RadioFieldBlue";
import RadioFieldGrey from "../../../HelperComponents/RadioField/RadioFieldGrey/RadioFieldGrey";
import SelectComponent from "../../../HelperComponents/SelectComponent/SelectComponent";
import RenderField from "../../../HelperComponents/RenderField/RenderField";
import Loader from "../../../HelperComponents/Loader/Loader";
import { createTraders, getOptions } from "../../../../actions/traderAction";
import { validate } from "./validate";
import { normalizeNumber, normalizeTextNumber } from "../../../HelperComponents/functions";

import arrow from "../../../../assets/image/arrow.png";
import back_arrow from "../../../../assets/image/back_arrow.png";

import "./CreateTrader.scss";

class CreateTrader extends Component {
    state = {
        status: "all",
        order_type: [
            {
                label: "Market",
                value: "m"
            },
            {
                label: "Limit",
                value: "l"
            }
        ],
        symbol: [],
        server_type: [],
        comp: [
            {
                label: "yes",
                value: true
            },
            {
                label: "no",
                value: false
            }
        ],
        side: [
            {
                label: "buy",
                value: "Buy"
            },
            {
                label: "sell",
                value: "Sell"
            }
        ],
        projectsList: [
            {
                label: "Bitmex",
                value: "BitMex"
            }
        ],
        pullbackBaseList: [
            {
                label: "bprice",
                value: "bprice"
            },
            {
                label: "close",
                value: "close"
            }
        ],
        net: [
            {
                label: "live",
                value: "live"
            },
            {
                label: "testnet",
                value: "testnet"
            }
        ],
        funding: [
            {
                label: "On",
                value: "On"
            },
            {
                label: "Off",
                value: "Off"
            }
        ],
        priority: [
            {
                label: "On",
                value: "On"
            },
            {
                label: "Off",
                value: "Off"
            }
        ],
        ohlc: [
            {
                label: "open",
                value: "o"
            },
            {
                label: "low",
                value: "l"
            },
            {
                label: "high",
                value: "h"
            },
            {
                label: "close",
                value: "c"
            }
        ],
        met: [
            {
                label: "avg",
                value: "avg"
            },
            {
                label: "max",
                value: "max"
            },
            {
                label: "min",
                value: "min"
            }
        ],
        take_profit_fix: true,
        stop_loss_fix: true,
        sideChange: "",
        loading: true,
        formula: false
    };

    componentDidMount() {
        const { getOptions } = this.props;
        document.title = "Create new trader";
        getOptions().then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                let symbol = [];
                let server_type = [];
                res.payload.data.symbols.forEach(el => symbol.push({ label: el.label, value: el.value }));
                res.payload.data.server_types.forEach(el => server_type.push({ label: el.label, value: el.value }));
                this.setState({ symbol, server_type, loading: false });
            }
        });
    }

    submitForm = data => {
        const { createTraders, reset, history, location } = this.props;
        delete data.stop_loss_fix_value;
        delete data.take_profit_fix_value;
        let arrayData = { ...data };
        arrayData.buy_order_type = !data.buy_order_type.value ? data.buy_order_type : data.buy_order_type.value;
        arrayData.symbol = !data.symbol.value ? data.symbol : data.symbol.value;
        arrayData.server_type = !data.server_type.value ? data.server_type : data.server_type.value;
        arrayData.priority = !data.priority.value ? data.priority : data.priority.value;
        arrayData.side = !data.side.value ? data.side : data.side.value;
        arrayData.comp = !data.comp.value ? data.comp : data.comp.value;
        arrayData.exchange = !data.exchange.value ? data.exchange : data.exchange.value;
        arrayData.pullback_base = !data.pullback_base.value ? data.pullback_base : data.pullback_base.value;
        arrayData.net = !data.net.value ? data.net : data.net.value;
        if (arrayData.x_ohlc) {
            arrayData.x_ohlc = !data.x_ohlc.value ? data.x_ohlc : data.x_ohlc.value;
        }
        if (arrayData.x_met) {
            arrayData.x_met = !data.x_met.value ? data.x_met : data.x_met.value;
        }
        if (arrayData.y_ohlc) {
            arrayData.y_ohlc = !data.y_ohlc.value ? data.y_ohlc : data.y_ohlc.value;
        }
        if (arrayData.y_met) {
            arrayData.y_met = !data.y_met.value ? data.y_met : data.y_met.value;
        }
        if (arrayData.z_ohlc) {
            arrayData.z_ohlc = !data.z_ohlc.value ? data.z_ohlc : data.z_ohlc.value;
        }
        if (arrayData.z_met) {
            arrayData.z_met = !data.z_met.value ? data.z_met : data.z_met.value;
        }
        arrayData.formula = !this.state.formula;

        createTraders(arrayData).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 201) {
                reset();
                history.push(`/main/trk${location.state ? location.state.previousQueries : ""}`);
            }
        });
    };

    /*-----------------------------------profitFixHandle--------------------------------*/

    profitFixHandle = e => {
        if (e.target.value === "take_profit_fix") {
            this.setState({
                take_profit_fix: true
            });
            this.props.dispatch(change("CreateTraderForm", "take_profit_factor", ""));
        } else {
            this.setState({
                take_profit_fix: false
            });
            this.props.dispatch(change("CreateTraderForm", "take_profit_fix", ""));
        }
    };

    /*-----------------------------------lossFix--------------------------------*/

    lossFix = e => {
        if (e.target.value === "stop_loss_fix") {
            this.setState({ stop_loss_fix: true });
            this.props.dispatch(change("CreateTraderForm", "stop_loss_factor", ""));
        } else {
            this.setState({ stop_loss_fix: false });
            this.props.dispatch(change("CreateTraderForm", "stop_loss_fix", ""));
        }
    };

    handleChange = selectedOption => {
        this.setState({ sideChange: selectedOption });
    };

    changeFormulaCheck = e => {
        let value = e.target.value;
        if (value === "On") {
            this.setState({ formula: false });
        } else {
            this.setState({ formula: true });
        }
    };

    render() {
        const { handleSubmit, location } = this.props;
        const {
            projectsList,
            pullbackBaseList,
            symbol,
            server_type,
            priority,
            order_type,
            side,
            comp,
            net,
            stop_loss_fix,
            sideChange,
            take_profit_fix,
            loading,
            funding,
            ohlc,
            met,
            formula
        } = this.state;
        if (loading) return <Loader />;
        return (
            <div className="content_block">
                <Link to={`/main/ip${location.state ? location.state.previousQueries : ""}`} className="go_back">
                    <img src={back_arrow} alt="back_arrow" />
                    back
                </Link>
                <p className="create_trader_title">Create new trader</p>
                <form onSubmit={handleSubmit(this.submitForm)} className="form_create_trader">
                    <div className="warapper_create_trader">
                        <div className="col_item">
                            <div className="block_item">
                                <p>Strategy</p>
                                <div>
                                    <label>Order_type</label>
                                    <Field name="buy_order_type" component={SelectComponent} options={order_type} />
                                </div>
                                <div>
                                    <label>Symbol</label>
                                    <Field name="symbol" component={SelectComponent} options={symbol} />
                                </div>
                                <div>
                                    <label>Trader_name</label>
                                    <Field name="name" component={RenderField} placeholder="Type here" />
                                </div>
                                <div>
                                    <label>Server_type</label>
                                    <Field name="server_type" component={SelectComponent} options={server_type} />
                                </div>
                                <div>
                                    <label>Priority</label>
                                    <Field name="priority" component={SelectComponent} options={priority} />
                                </div>
                            </div>
                            <div className="block_item">
                                <div className="switch_signal_wrapper">
                                    <p>Formula</p>
                                    <div className="switch_signal">
                                        <Field
                                            name="formula"
                                            component={RadioFieldGrey}
                                            arrRadio={[{ value: "On" }, { value: "Off" }]}
                                            change={this.changeFormulaCheck}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label>x_offset</label>
                                    <Field
                                        name="x_offset"
                                        type="number"
                                        placeholder="Set value here"
                                        parse={val => (!val ? "" : parseInt(val, 10))}
                                        isDisabled={!!formula}
                                        component={RenderField}
                                    />
                                </div>
                                <div>
                                    <label>x_ohlc</label>
                                    <Field
                                        name="x_ohlc"
                                        component={SelectComponent}
                                        options={ohlc}
                                        isDisabled={!!formula}
                                    />
                                </div>
                                <div>
                                    <label>x_met</label>
                                    <Field
                                        name="x_met"
                                        component={SelectComponent}
                                        options={met}
                                        isDisabled={!!formula}
                                    />
                                </div>
                                <div>
                                    <label>y_offset</label>
                                    <Field
                                        name="y_offset"
                                        type="number"
                                        placeholder="Set value here"
                                        parse={val => (!val ? "" : parseInt(val, 10))}
                                        component={RenderField}
                                        isDisabled={!!formula}
                                    />
                                </div>
                                <div>
                                    <label>y_ohlc</label>
                                    <Field
                                        name="y_ohlc"
                                        component={SelectComponent}
                                        options={ohlc}
                                        isDisabled={!!formula}
                                    />
                                </div>
                                <div>
                                    <label>y_met</label>
                                    <Field
                                        name="y_met"
                                        component={SelectComponent}
                                        options={met}
                                        isDisabled={!!formula}
                                    />
                                </div>
                                <div>
                                    <label>z_offset</label>
                                    <Field
                                        name="z_offset"
                                        type="number"
                                        placeholder="Set value here"
                                        parse={val => (!val ? "" : parseInt(val, 10))}
                                        component={RenderField}
                                        isDisabled={!!formula}
                                    />
                                </div>
                                <div>
                                    <label>z_ohlc</label>
                                    <Field
                                        name="z_ohlc"
                                        component={SelectComponent}
                                        options={ohlc}
                                        isDisabled={!!formula}
                                    />
                                </div>
                                <div>
                                    <label>z_met</label>
                                    <Field
                                        name="z_met"
                                        component={SelectComponent}
                                        options={met}
                                        isDisabled={!!formula}
                                    />
                                </div>
                                <div>
                                    <label>Formula</label>
                                    <div className="group_input">
                                        <Field
                                            name="formula_from"
                                            type="text"
                                            component={RenderField}
                                            placeholder="from"
                                            normalize={normalizeNumber}
                                            isDisabled={!!formula}
                                        />
                                        <Field
                                            name="formula_to"
                                            type="text"
                                            component={RenderField}
                                            placeholder="to"
                                            normalize={normalizeNumber}
                                            isDisabled={!!formula}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col_item">
                            <div className="block_item">
                                <p>Trigger_select</p>
                                <div>
                                    <label>Max_trades</label>
                                    <Field
                                        name="max_trades"
                                        type="number"
                                        parse={val => (!val ? "" : parseInt(val, 10))}
                                        component={RenderField}
                                    />
                                </div>
                                <div>
                                    <label>Frequency</label>
                                    <Field
                                        name="frequency"
                                        type="number"
                                        parse={val => (!val ? "" : parseInt(val, 10))}
                                        component={RenderField}
                                    />
                                </div>
                                <div>
                                    {/*<label>Timing</label>*/}
                                    {/*<Field name="timing" type="text" component={RenderField} isDisabled={true}/>*/}
                                    <label>Funding</label>
                                    <Field name="timing" component={SelectComponent} options={funding} />
                                </div>
                                <div>
                                    <label>Multi_entry</label>
                                    <Field
                                        name="multi_entry"
                                        type="number"
                                        parse={val => (!val ? "" : parseInt(val, 10))}
                                        component={RenderField}
                                        placeholder="Set value here"
                                    />
                                </div>
                                <div>
                                    <label>Cooldown_int</label>
                                    <Field
                                        name="cooldown_int"
                                        type="number"
                                        parse={val => (!val ? "" : parseInt(val, 10))}
                                        component={RenderField}
                                    />
                                </div>
                                <div>
                                    <label>Cooldown_num</label>
                                    <Field
                                        name="cooldown_num"
                                        type="number"
                                        parse={val => (!val ? "" : parseInt(val, 10))}
                                        component={RenderField}
                                    />
                                </div>
                                {/*<div>*/}
                                {/*    <label>Other_rules</label>*/}
                                {/*    <Field name="other_rules" type="text" component={RenderField} placeholder="LATER" isDisabled={true}/>*/}
                                {/*</div>*/}
                            </div>
                            <div className="block_item">
                                <p>Status</p>
                                <div className="switch_block">
                                    <label>
                                        <Field
                                            name="is_stopped"
                                            component={RadioFieldBlue}
                                            arrRadio={[
                                                { value: "off", label: "On" },
                                                { value: "on", label: "Off" }
                                            ]}
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="col_item">
                            <div className="block_item">
                                <p>Trader_setup</p>
                                <div className="testClass">
                                    <Field
                                        name="take_profit_fix_value"
                                        component={RadioFieldGrey}
                                        arrRadio={[{ value: "take_profit_fix" }, { value: "take_profit_factor" }]}
                                        change={this.profitFixHandle}
                                    />
                                    <div className="arrInput">
                                        <Field
                                            name="take_profit_fix"
                                            type="text"
                                            component={RenderField}
                                            isCheck={!take_profit_fix}
                                            placeholder="Set value here"
                                            isDisabled={!take_profit_fix}
                                            normalize={normalizeNumber}
                                        />
                                        <Field
                                            name="take_profit_factor"
                                            type="text"
                                            component={RenderField}
                                            isCheck={take_profit_fix}
                                            placeholder="Set value here"
                                            isDisabled={take_profit_fix}
                                            normalize={normalizeNumber}
                                        />
                                    </div>
                                </div>
                                {/*  <div>
                                    <label>Take_profit_custom</label>
                                    <input type="text" placeholder="LATER"  style={{background: 'white'}} disabled />
                                </div>*/}
                                <div className="testClass">
                                    <Field
                                        name="stop_loss_fix_value"
                                        component={RadioFieldGrey}
                                        arrRadio={[{ value: "stop_loss_fix" }, { value: "stop_loss_factor" }]}
                                        change={this.lossFix}
                                    />
                                    <div className="arrInput">
                                        <Field
                                            name="stop_loss_fix"
                                            type="text"
                                            component={RenderField}
                                            isCheck={!stop_loss_fix}
                                            placeholder="Set value here"
                                            isDisabled={!stop_loss_fix}
                                            normalize={normalizeNumber}
                                        />
                                        <Field
                                            name="stop_loss_factor"
                                            type="text"
                                            component={RenderField}
                                            isCheck={stop_loss_fix}
                                            placeholder="Set value here"
                                            isDisabled={stop_loss_fix}
                                            normalize={normalizeNumber}
                                        />
                                    </div>
                                </div>
                                {/*  <div>
                                    <label>Stop_loss_custom</label>
                                    <Field name="Stop_loss_custom" type="text" component={RenderField} placeholder="LATER" isDisabled={true}/>
                                </div>*/}
                            </div>
                            <div className="block_item">
                                <p>Lags</p>
                                <div>
                                    <label>lag_fund</label>
                                    <Field
                                        name="lag_fund"
                                        type="number"
                                        component={RenderField}
                                        // parse={(val) => !val ? '' : parseInt(val, 10) }
                                        placeholder="Set value here"
                                        normalize={normalizeTextNumber}
                                    />
                                </div>
                                <div>
                                    <label>lag_buy_1</label>
                                    <Field
                                        name="lag_buy_1"
                                        type="number"
                                        component={RenderField}
                                        // parse={(val) => !val ? '' : parseInt(val, 10) }
                                        placeholder="Set value here"
                                        normalize={normalizeTextNumber}
                                    />
                                </div>
                                <div>
                                    <label>lag_buy_2</label>
                                    <Field
                                        name="lag_buy_2"
                                        type="number"
                                        component={RenderField}
                                        // parse={(val) => !val ? '' : parseInt(val, 10) }
                                        placeholder="Set value here"
                                        normalize={normalizeTextNumber}
                                    />
                                </div>
                                <div>
                                    <label>lag_buy_3</label>
                                    <Field
                                        name="lag_buy_3"
                                        type="number"
                                        component={RenderField}
                                        // parse={(val) => !val ? '' : parseInt(val, 10) }
                                        placeholder="Set value here"
                                        normalize={normalizeTextNumber}
                                    />
                                </div>
                                <div>
                                    <label>lag_sell_1</label>
                                    <Field
                                        name="lag_sell_1"
                                        type="number"
                                        component={RenderField}
                                        // parse={(val) => !val ? '' : parseInt(val, 10) }
                                        placeholder="Set value here"
                                        normalize={normalizeTextNumber}
                                    />
                                </div>
                                <div>
                                    <label>lag_sell_2</label>
                                    <Field
                                        name="lag_sell_2"
                                        type="number"
                                        component={RenderField}
                                        // parse={(val) => !val ? '' : parseInt(val, 10) }
                                        placeholder="Set value here"
                                        normalize={normalizeTextNumber}
                                    />
                                </div>
                                <div>
                                    <label>lag_sell_3</label>
                                    <Field
                                        name="lag_sell_3"
                                        type="number"
                                        component={RenderField}
                                        // parse={(val) => !val ? '' : parseInt(val, 10) }
                                        placeholder="Set value here"
                                        normalize={normalizeTextNumber}
                                    />
                                </div>
                                <div>
                                    <label>lag_sl_1</label>
                                    <Field
                                        name="lag_sl_1"
                                        type="number"
                                        component={RenderField}
                                        // parse={(val) => !val ? '' : parseInt(val, 10) }
                                        placeholder="Set value here"
                                        normalize={normalizeTextNumber}
                                    />
                                </div>
                                <div>
                                    <label>lag_sl_2</label>
                                    <Field
                                        name="lag_sl_2"
                                        type="number"
                                        component={RenderField}
                                        // parse={(val) => !val ? '' : parseInt(val, 10) }
                                        placeholder="Set value here"
                                        normalize={normalizeTextNumber}
                                    />
                                </div>
                                <div>
                                    <label>lag_sl_3</label>
                                    <Field
                                        name="lag_sl_3"
                                        type="number"
                                        component={RenderField}
                                        // parse={(val) => !val ? '' : parseInt(val, 10) }
                                        placeholder="Set value here"
                                        normalize={normalizeTextNumber}
                                    />
                                </div>
                            </div>
                            <div className="block_item">
                                <p>Trader_inputs</p>
                                <div>
                                    <label>Timeframe</label>
                                    <Field
                                        name="time_frame"
                                        type="number"
                                        component={RenderField}
                                        parse={val => (!val ? "" : parseInt(val, 10))}
                                        placeholder="Set value here"
                                        normalize={normalizeTextNumber}
                                    />
                                </div>
                                <div>
                                    <label>Exchange</label>
                                    <Field name="exchange" component={SelectComponent} options={projectsList} />
                                </div>
                                <div>
                                    <label>Pullback_base</label>
                                    <Field
                                        name="pullback_base"
                                        component={SelectComponent}
                                        options={pullbackBaseList}
                                        defaultValue={{ label: "bprice", value: "bprice" }}
                                    />
                                </div>
                                <div>
                                    <label>Pullback</label>
                                    <Field
                                        name="pullback"
                                        type="text"
                                        component={RenderField}
                                        placeholder="Set value here"
                                        normalize={normalizeNumber}
                                    />
                                </div>
                                <div>
                                    <label>comp</label>
                                    <Field
                                        name="comp"
                                        component={SelectComponent}
                                        options={comp}
                                        onChange={e => this.handleChange(e.value)}
                                    />
                                </div>
                                <div>
                                    <label>OrderQty</label>
                                    <Field
                                        name="orderQty"
                                        type="text"
                                        component={RenderField}
                                        placeholder="Set value here"
                                        normalize={normalizeNumber}
                                    />
                                </div>
                                <div>
                                    <label>Side</label>
                                    <Field
                                        name="side"
                                        component={SelectComponent}
                                        options={side}
                                        onChange={e => this.handleChange(e.value)}
                                    />
                                </div>
                                <div>
                                    <label>Net</label>
                                    <Field name="net" component={SelectComponent} options={net} />
                                </div>
                                <div>
                                    <label>Reverse_side</label>
                                    <Field
                                        name="reverse_side"
                                        type="text"
                                        component={RenderField}
                                        placeholder="sell"
                                        defaultValue={
                                            sideChange === "Buy" ? "sell" : sideChange === "Sell" ? "buy" : ""
                                        }
                                        isDisabled={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="btn_wrapper">
                        <button
                            className="create_btn"
                            type="submit"
                            // disabled={submitting || pristine || !valid}
                            //     disabled={!valid}
                        >
                            create
                            <img src={arrow} alt="arrow" />
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}

CreateTrader = reduxForm({
    form: "CreateTraderForm",
    validate
})(CreateTrader);

function mapStateToProps(state) {
    return {
        initialValues: {
            timing: "On",
            max_trades: 0,
            cooldown_int: 0,
            cooldown_num: 0,
            frequency: 1,
            is_stopped: "on",
            stop_loss_fix_value: "stop_loss_fix",
            take_profit_fix_value: "take_profit_fix",
            formula: "On",
            signal_a: "Off",
            signal_b: "Off",
            signal_c: "Off",
            signal_d: "Off",
            pullback_base: "bprice"
        }
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            createTraders,
            getOptions
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateTrader);
