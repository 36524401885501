import * as types from "../actions/constants";

const INITIAL_STATE = {
    server_list: {},
    loading: true,
    error: null
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.GET_SERVERS_SUCCESS:
            return { ...state, server_list: action.payload.data };
        default:
            return state;
    }
}
