import React from "react";
import { Field } from "redux-form";

import RadioFieldBlue from "../HelperComponents/RadioField/RadioFieldBlue/RadioFieldBlue";
import RadioFieldGrey from "../HelperComponents/RadioField/RadioFieldGrey/RadioFieldGrey";
import SelectComponent from "../HelperComponents/SelectComponent/SelectComponent";
import RenderField from "../HelperComponents/RenderField/RenderField";
import { normalizeNumber, normalizeTextNumber } from "../HelperComponents/functions";

import "../Pages/M/CreateMTrader/CreateMTrader.scss";

const customReturnDeultValue = (option, value) => {
    let defaultValue = null;
    for (let i = 0; i < option.length; i++) {
        if (typeof value === "boolean") {
            if (value === true) {
                defaultValue = option[0];
            } else {
                defaultValue = option[1];
            }
        } else if (option[i].label.toLowerCase() === value.toLowerCase()) {
            defaultValue = option[i];
        }
    }
    return defaultValue;
};

export const Fields = ({
    symbol,
    account,
    changeSignalACheck,
    signal_a_check,
    changeSignalCCheck,
    signal_c_check,
    side,
    handleChangeRadio,
    isEdit,
    m_trader_info
}) => {
    const mAccount = account.find(el => el.value === m_trader_info["account"]);
    return (
        <div className="col_item">
            <div className="block_item">
                <p>Strategy</p>
                <div>
                    <label>Symbol</label>
                    {isEdit ? (
                        <Field
                            name="symbol"
                            component={SelectComponent}
                            options={symbol}
                            defaultValue={
                                m_trader_info["symbol"] && customReturnDeultValue(symbol, m_trader_info["symbol"])
                            }
                        />
                    ) : (
                        <p className="non_edit_value width_145">{m_trader_info["symbol"]}</p>
                    )}
                </div>
                <div>
                    <label>Account</label>
                    {isEdit ? (
                        <Field
                            name="account"
                            component={SelectComponent}
                            options={account}
                            defaultValue={mAccount && customReturnDeultValue(account, mAccount["label"])}
                        />
                    ) : (
                        <p className="non_edit_value width_145">{mAccount && mAccount["label"]}</p>
                    )}
                </div>
                <div>
                    <label>Trader_name</label>
                    <Field name="name" component={RenderField} placeholder="Type here" isDisabled={!isEdit} />
                </div>
            </div>
            <div className="block_item">
                <p>Status</p>
                <div className="switch_block">
                    <label>
                        <Field
                            name="is_stopped"
                            component={RadioFieldBlue}
                            arrRadio={[
                                { value: "off", label: "On" },
                                { value: "on", label: "Off" }
                            ]}
                            change={handleChangeRadio}
                        />
                    </label>
                </div>
            </div>
            <div className="block_item">
                <div className="switch_signal_wrapper">
                    <p>SIGNAL_A</p>
                    {isEdit ? (
                        <div className="switch_signal">
                            <Field
                                name="signal_a"
                                component={RadioFieldGrey}
                                arrRadio={[{ value: "On" }, { value: "Off" }]}
                                change={changeSignalACheck}
                            />
                        </div>
                    ) : (
                        <div className="non_edit_signal">{!signal_a_check ? "On" : "Off"}</div>
                    )}
                </div>
                <div>
                    <label>Upper</label>
                    <div className="group_input">
                        <Field
                            name="a_upper_from"
                            type="text"
                            component={RenderField}
                            placeholder="from"
                            normalize={normalizeNumber}
                            isDisabled={!!signal_a_check || !isEdit}
                        />
                        <Field
                            name="a_upper_to"
                            type="text"
                            component={RenderField}
                            placeholder="to"
                            normalize={normalizeNumber}
                            isDisabled={!!signal_a_check || !isEdit}
                        />
                    </div>
                </div>
                <div>
                    <label>Real</label>
                    <div className="group_input">
                        <Field
                            name="a_real_from"
                            type="text"
                            component={RenderField}
                            placeholder="from"
                            normalize={normalizeNumber}
                            isDisabled={!!signal_a_check || !isEdit}
                        />
                        <Field
                            name="a_real_to"
                            type="text"
                            component={RenderField}
                            placeholder="to"
                            normalize={normalizeNumber}
                            isDisabled={!!signal_a_check || !isEdit}
                        />
                    </div>
                </div>
                <div>
                    <label>Lower</label>
                    <div className="group_input">
                        <Field
                            name="a_lower_from"
                            type="text"
                            component={RenderField}
                            placeholder="from"
                            normalize={normalizeNumber}
                            isDisabled={!!signal_a_check || !isEdit}
                        />
                        <Field
                            name="a_lower_to"
                            type="text"
                            component={RenderField}
                            placeholder="to"
                            normalize={normalizeNumber}
                            isDisabled={!!signal_a_check || !isEdit}
                        />
                    </div>
                </div>
                <div>
                    <label>Hourly</label>
                    <div className="group_input">
                        <Field
                            name="a_hourly_from"
                            type="text"
                            component={RenderField}
                            placeholder="from"
                            normalize={normalizeNumber}
                            isDisabled={!!signal_a_check || !isEdit}
                        />
                        <Field
                            name="a_hourly_to"
                            type="text"
                            component={RenderField}
                            placeholder="to"
                            normalize={normalizeNumber}
                            isDisabled={!!signal_a_check || !isEdit}
                        />
                    </div>
                </div>
                <div>
                    <label>Daily</label>
                    <div className="group_input">
                        <Field
                            name="a_daily_from"
                            type="text"
                            component={RenderField}
                            placeholder="from"
                            normalize={normalizeNumber}
                            isDisabled={!!signal_a_check || !isEdit}
                        />
                        <Field
                            name="a_daily_to"
                            type="text"
                            component={RenderField}
                            placeholder="to"
                            normalize={normalizeNumber}
                            isDisabled={!!signal_a_check || !isEdit}
                        />
                    </div>
                </div>
            </div>
            <div className="block_item">
                <div className="switch_signal_wrapper">
                    <p>SIGNAL_C</p>
                    {isEdit ? (
                        <div className="switch_signal">
                            <Field
                                name="signal_c"
                                component={RadioFieldGrey}
                                arrRadio={[{ value: "On" }, { value: "Off" }]}
                                change={changeSignalCCheck}
                            />
                        </div>
                    ) : (
                        <div className="non_edit_signal">{!signal_c_check ? "On" : "Off"}</div>
                    )}
                </div>
                <div>
                    <label>Upper</label>
                    <div className="group_input">
                        <Field
                            name="c_upper_from"
                            type="text"
                            component={RenderField}
                            placeholder="from"
                            normalize={normalizeNumber}
                            isDisabled={!!signal_c_check || !isEdit}
                        />
                        <Field
                            name="c_upper_to"
                            type="text"
                            component={RenderField}
                            placeholder="to"
                            normalize={normalizeNumber}
                            isDisabled={!!signal_c_check || !isEdit}
                        />
                    </div>
                </div>
                <div>
                    <label>Real</label>
                    <div className="group_input">
                        <Field
                            name="c_real_from"
                            type="text"
                            component={RenderField}
                            placeholder="from"
                            normalize={normalizeNumber}
                            isDisabled={!!signal_c_check || !isEdit}
                        />
                        <Field
                            name="c_real_to"
                            type="text"
                            component={RenderField}
                            placeholder="to"
                            normalize={normalizeNumber}
                            isDisabled={!!signal_c_check || !isEdit}
                        />
                    </div>
                </div>
                <div>
                    <label>Lower</label>
                    <div className="group_input">
                        <Field
                            name="c_lower_from"
                            type="text"
                            component={RenderField}
                            placeholder="from"
                            normalize={normalizeNumber}
                            isDisabled={!!signal_c_check || !isEdit}
                        />
                        <Field
                            name="c_lower_to"
                            type="text"
                            component={RenderField}
                            placeholder="to"
                            normalize={normalizeNumber}
                            isDisabled={!!signal_c_check || !isEdit}
                        />
                    </div>
                </div>
                <div>
                    <label>Hourly</label>
                    <div className="group_input">
                        <Field
                            name="c_hourly_from"
                            type="text"
                            component={RenderField}
                            placeholder="from"
                            normalize={normalizeNumber}
                            isDisabled={!!signal_c_check || !isEdit}
                        />
                        <Field
                            name="c_hourly_to"
                            type="text"
                            component={RenderField}
                            placeholder="to"
                            normalize={normalizeNumber}
                            isDisabled={!!signal_c_check || !isEdit}
                        />
                    </div>
                </div>
                <div>
                    <label>Daily</label>
                    <div className="group_input">
                        <Field
                            name="c_daily_from"
                            type="text"
                            component={RenderField}
                            placeholder="from"
                            normalize={normalizeNumber}
                            isDisabled={!!signal_c_check || !isEdit}
                        />
                        <Field
                            name="c_daily_to"
                            type="text"
                            component={RenderField}
                            placeholder="to"
                            normalize={normalizeNumber}
                            isDisabled={!!signal_c_check || !isEdit}
                        />
                    </div>
                </div>
            </div>
            <div className="block_item">
                <p>Lags</p>
                <div>
                    <label>lag_buy_1</label>
                    <Field
                        name="lag_buy_1"
                        type="number"
                        component={RenderField}
                        placeholder="Set value here"
                        normalize={normalizeTextNumber}
                        isDisabled={!isEdit}
                    />
                </div>
                <div>
                    <label>lag_buy_2</label>
                    <Field
                        name="lag_buy_2"
                        type="number"
                        component={RenderField}
                        placeholder="Set value here"
                        normalize={normalizeTextNumber}
                        isDisabled={!isEdit}
                    />
                </div>
                <div>
                    <label>lag_buy_3</label>
                    <Field
                        name="lag_buy_3"
                        type="number"
                        component={RenderField}
                        placeholder="Set value here"
                        normalize={normalizeTextNumber}
                        isDisabled={!isEdit}
                    />
                </div>
            </div>
            <div className="block_item">
                <p>Trader_inputs</p>
                <div>
                    <label>Leverage</label>
                    <Field
                        name="leverage"
                        type="text"
                        component={RenderField}
                        placeholder="Set value here"
                        normalize={normalizeNumber}
                        isDisabled={!isEdit}
                    />
                </div>
                <div>
                    <label>Side</label>
                    {isEdit ? (
                        <Field
                            name="side"
                            component={SelectComponent}
                            options={side}
                            defaultValue={m_trader_info["side"] && customReturnDeultValue(side, m_trader_info["side"])}
                        />
                    ) : (
                        <p className="non_edit_value width_145">{m_trader_info["side"]}</p>
                    )}
                </div>
            </div>
        </div>
    );
};
