import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { formatPrice, roundNumber, roundNumberToFixedOne, splitByCommas } from "../../HelperComponents/functions";

import "./styles.scss";

export const TrKTable = props => {
    const { sortTrader, sortBy, trk_list, pathname, location, trk_totals } = props;

    return (
        <div className="table_container transactions_columns trk_one">
            <div className="table_header">
                <div className="table_row trk_table_row">
                    <div className="row_item" onClick={() => sortTrader(sortBy === "name" ? "-name" : "name")}>
                        trader <span className={sortBy === "name" ? "up" : "down"} />
                    </div>
                    <div className="row_item">
                        <div className="block_row">
                            <span>curr_price</span>
                            <p>trade_pnl</p>
                        </div>
                    </div>
                    <div
                        className="row_item"
                        onClick={() =>
                            sortTrader(sortBy === "latest_data__USD" ? "-latest_data__USD" : "latest_data__USD")
                        }
                    >
                        <div className="block_row">
                            <span>
                                pnl <span className="up no_display" />
                            </span>
                            <p>
                                curr_usd <span className={sortBy === "latest_data__USD" ? "up" : "down"} />
                            </p>
                        </div>
                    </div>
                    <div
                        className="row_item"
                        onClick={() =>
                            sortTrader(
                                sortBy === "latest_data__marginLeverage"
                                    ? "-latest_data__marginLeverage"
                                    : "latest_data__marginLeverage"
                            )
                        }
                    >
                        curr_lev
                        <span className={sortBy === "latest_data__marginLeverage" ? "up" : "down"} />
                    </div>
                    <div
                        className="row_item"
                        onClick={() =>
                            sortTrader(
                                sortBy === "latest_trk_data__created_at"
                                    ? "-latest_trk_data__created_at"
                                    : "latest_trk_data__created_at"
                            )
                        }
                    >
                        time
                        <span className={sortBy === "latest_trk_data__created_at" ? "up" : "down"} />
                    </div>
                    <div className="row_item">
                        <div className="block_row">
                            <p>price</p>
                            <span>avg.entry</span>
                        </div>
                    </div>
                    <div
                        className="row_item"
                        onClick={() =>
                            sortTrader(
                                sortBy === "latest_trk_data__USD" ? "-latest_trk_data__USD" : "latest_trk_data__USD"
                            )
                        }
                    >
                        <div className="block_row">
                            <span>
                                btc <span className="up no_display" />
                            </span>
                            <p>
                                usd <span className={sortBy === "latest_trk_data__USD" ? "up" : "down"} />
                            </p>
                        </div>
                    </div>
                    <div className="row_item">
                        <div className="block_row">
                            <span>new_pos</span>
                            <p>old_pos</p>
                        </div>
                    </div>
                    <div className="row_item">
                        <div className="block_row">
                            <span>new_lev</span>
                            <p>old_lev</p>
                        </div>
                    </div>
                    <div className="row_item">tgt_lev</div>
                </div>
            </div>
            <div className="tab_all">
                <div className="table_body">
                    {trk_list.map(
                        ({
                            id,
                            status,
                            name,
                            time,
                            Price,
                            BTC,
                            USD,
                            OldPos,
                            OldLev,
                            NewPos,
                            NewLev,
                            Target_lev,
                            CurrentPrice,
                            CurrentUSD,
                            CurrentLev,
                            avg_entry,
                            pnl,
                            symbol,
                            start_percent
                        }) => (
                            <div className="table_row trk_table_row" key={`trk_${id}`}>
                                <div className="row_item ">
                                    <Link
                                        to={{
                                            pathname: `/main/${pathname}/${id}`,
                                            state: { previousQueries: location.search }
                                        }}
                                        className="trader_status"
                                    >
                                        <span className={`${status}`} />
                                        {name}
                                    </Link>
                                </div>
                                <div className="row_item">
                                    <div className="block_row p_with_opacity">
                                        <span>{formatPrice(CurrentPrice, symbol)}</span>
                                        <p>{pnl || pnl === 0 ? `${roundNumberToFixedOne(pnl * 100)}%` : "-"}</p>
                                    </div>
                                </div>
                                <div className="row_item">
                                    <div className="block_row p_with_opacity">
                                        <span>
                                            {start_percent || start_percent === 0
                                                ? `${roundNumberToFixedOne(start_percent * 100)}%`
                                                : "-"}
                                        </span>
                                        <p>
                                            {CurrentUSD || CurrentUSD === 0
                                                ? `$${splitByCommas((CurrentUSD / 1000).toFixed(1))}k`
                                                : "-"}
                                        </p>
                                    </div>
                                </div>
                                <div className="row_item">
                                    {CurrentLev || CurrentLev === 0 ? `${roundNumber(CurrentLev)}x` : "-"}
                                </div>
                                <div className="row_item">
                                    {time ? (
                                        <div className="block_row_flex_start">
                                            <span>{moment(time).format("DD-MMM HH:mm")}</span>
                                        </div>
                                    ) : (
                                        "-"
                                    )}
                                </div>
                                <div className="row_item">
                                    <div className="row_item">
                                        <div className="block_row p_with_opacity">
                                            <span>{formatPrice(Price, symbol)}</span>
                                            <p>{formatPrice(avg_entry, symbol)}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row_item">
                                    <div className="block_row p_with_opacity">
                                        <span>{BTC || BTC === 0 ? BTC.toFixed(4) : "-"}</span>
                                        <p>{USD || USD === 0 ? `$${splitByCommas((USD / 1000).toFixed(1))}k` : "-"}</p>
                                    </div>
                                </div>
                                <div className="row_item">
                                    <div className="block_row p_with_opacity">
                                        <span>
                                            {NewPos || NewPos === 0
                                                ? `$${splitByCommas((NewPos / 1000).toFixed(1))}k`
                                                : "-"}
                                        </span>
                                        <p>
                                            {OldPos || OldPos === 0
                                                ? `$${splitByCommas((OldPos / 1000).toFixed(1))}k`
                                                : "-"}
                                        </p>
                                    </div>
                                </div>
                                <div className="row_item">
                                    <div className="block_row p_with_opacity">
                                        <span>{NewLev || NewLev === 0 ? `${roundNumber(NewLev)}x` : "-"}</span>
                                        <p>{OldLev || OldLev === 0 ? `${roundNumber(OldLev)}x` : "-"}</p>
                                    </div>
                                </div>
                                <div className="row_item">
                                    {Target_lev || Target_lev === 0 ? `${roundNumber(Target_lev)}x` : "-"}
                                </div>
                            </div>
                        )
                    )}
                </div>
                <div className="table_body table_total">
                    <div className="table_row trk_table_row">
                        <div className="row_item">Total:</div>
                        <div className="row_item">
                            {trk_totals["pnl"] || trk_totals["pnl"] === 0
                                ? `${(trk_totals["pnl"] * 100).toFixed(1)}%`
                                : "-"}
                        </div>
                        <div className="row_item">
                            {trk_totals["CurrentUSD"] || trk_totals["CurrentUSD"] === 0
                                ? `$${splitByCommas((trk_totals["CurrentUSD"] / 1000).toFixed(1))}k`
                                : "-"}
                            {/*<div className="block_row p_with_opacity">*/}
                            {/*    <span>*/}
                            {/*        {trk_totals["CurrentBTC"] || trk_totals["CurrentBTC"] === 0*/}
                            {/*            ? trk_totals["CurrentBTC"].toFixed(4)*/}
                            {/*            : "-"}*/}
                            {/*    </span>*/}
                            {/*    <p>*/}
                            {/*        {trk_totals["CurrentUSD"] || trk_totals["CurrentUSD"] === 0*/}
                            {/*            ? `$${splitByCommas((trk_totals["CurrentUSD"] / 1000).toFixed(1))}k`*/}
                            {/*            : "-"}*/}
                            {/*    </p>*/}
                            {/*</div>*/}
                        </div>
                        <div className="row_item">
                            {trk_totals["CurrentLev"] || trk_totals["CurrentLev"] === 0
                                ? `${roundNumber(trk_totals["CurrentLev"])}x`
                                : "-"}
                        </div>
                        <div className="row_item" />
                        <div className="row_item" />
                        <div className="row_item">
                            <div className="block_row p_with_opacity">
                                <span>
                                    {trk_totals["BTC"] || trk_totals["BTC"] === 0 ? trk_totals["BTC"].toFixed(4) : "-"}
                                </span>
                                <p>
                                    {trk_totals["USD"] || trk_totals["USD"] === 0
                                        ? `$${splitByCommas((trk_totals["USD"] / 1000).toFixed(1))}k`
                                        : "-"}
                                </p>
                            </div>
                        </div>
                        <div className="row_item">
                            <div className="block_row p_with_opacity">
                                <span>
                                    {trk_totals["NewPos"] || trk_totals["NewPos"] === 0
                                        ? `$${splitByCommas((trk_totals["NewPos"] / 1000).toFixed(1))}k`
                                        : "-"}
                                </span>
                                <p>
                                    {trk_totals["OldPos"] || trk_totals["OldPos"] === 0
                                        ? `$${splitByCommas((trk_totals["OldPos"] / 1000).toFixed(1))}k`
                                        : "-"}
                                </p>
                            </div>
                        </div>
                        <div className="row_item">
                            <div className="block_row p_with_opacity">
                                <span>
                                    {trk_totals["NewLev"] || trk_totals["NewLev"] === 0
                                        ? `${roundNumber(trk_totals["NewLev"])}x`
                                        : "-"}
                                </span>
                                <p>
                                    {trk_totals["OldLev"] || trk_totals["OldLev"] === 0
                                        ? `${roundNumber(trk_totals["OldLev"])}x`
                                        : "-"}
                                </p>
                            </div>
                        </div>
                        <div className="row_item" />
                    </div>
                </div>
            </div>
        </div>
    );
};
