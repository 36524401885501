import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import moment from "moment";

import { getTRKTraderDetails } from "../../../actions/trkActions";

import Pagination from "../../HelperComponents/Pagination/Pagination";
import Loader from "../../HelperComponents/Loader/Loader";
import { roundNumber, splitByCommas, formatPrice, roundNumberToFixedOne } from "../../HelperComponents/functions";

import "./TraderDetails.scss";

class TraderDetails extends Component {
    constructor(props) {
        super(props);
        const params = new URLSearchParams(decodeURIComponent(props.location.search.substring(1)));
        this.state = {
            loading: true,
            activePage: params.get("page") || 1
        };
    }

    componentDidMount() {
        this.fetchTraderDetails().then(() => {
            this.setState({ loading: false });
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.activePage !== this.state.activePage) {
            this.fetchTraderDetails().then(() => {
                const {
                    history,
                    location: { pathname }
                } = this.props;
                history.push(`${pathname}?tab=trader-details&page=${this.state.activePage}`);
            });
        }
    }

    fetchTraderDetails = async () => {
        const {
            getTRKTraderDetails,
            match: { params }
        } = this.props;
        const { activePage } = this.state;
        const queries = [];
        queries.push(`page=${activePage}`);
        await getTRKTraderDetails(params.id, queries);
    };

    render() {
        const {
            trader_details: { results, pages },
            trader_creation_info
        } = this.props;
        const { loading, activePage } = this.state;
        if (loading || !trader_creation_info.hasOwnProperty("symbol")) return <Loader />;
        return (
            <div className="trk_trader_details">
                <div className="table_container transactions_columns">
                    <div className="table_header">
                        <div className="table_row">
                            <div className="row_item">time</div>
                            <div className="row_item">price</div>
                            <div className="row_item">
                                <div className="block_row">
                                    <span>btc</span>
                                    <p>usd</p>
                                </div>
                            </div>
                            <div className="row_item">
                                <div className="block_row">
                                    <span>new_pos</span>
                                    <p>old_pos</p>
                                </div>
                            </div>
                            <div className="row_item">
                                <div className="block_row">
                                    <span>new_lev</span>
                                    <p>old_lev</p>
                                </div>
                            </div>
                            <div className="row_item">tgt_lev</div>
                            <div className="row_item">
                                <div className="block_row">
                                    <span>curr_price</span>
                                    <p>trade_pnl</p>
                                </div>
                            </div>
                            <div className="row_item">
                                <div className="block_row">
                                    <span>curr_btc</span>
                                    <p>curr_usd</p>
                                </div>
                            </div>
                            <div className="row_item">curr_lev</div>
                        </div>
                    </div>
                    <div className="tab_all">
                        <div className="table_body">
                            {results.map(
                                ({
                                    date,
                                    price,
                                    BTC,
                                    USD,
                                    OldPos,
                                    OldLev,
                                    NewPos,
                                    NewLev,
                                    Target_lev,
                                    CurrentPrice,
                                    CurrentBTC,
                                    CurrentUSD,
                                    CurrentLev,
                                    pnl
                                }) => (
                                    <div className="table_row" key={`trk_${date}`}>
                                        <div className="row_item">
                                            {date ? (
                                                <div className="block_row_flex_start">
                                                    <span>{moment(date).format("DD-MMM HH:mm")}</span>
                                                </div>
                                            ) : (
                                                "-"
                                            )}
                                        </div>
                                        <div className="row_item">
                                            {formatPrice(price, trader_creation_info.symbol)}
                                        </div>
                                        <div className="row_item">
                                            <div className="block_row p_with_opacity">
                                                <span>{BTC || BTC === 0 ? BTC.toFixed(4) : "-"}</span>
                                                <p>
                                                    {USD || USD === 0
                                                        ? `$${splitByCommas((USD / 1000).toFixed(1))}k`
                                                        : "-"}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row_item">
                                            <div className="block_row p_with_opacity">
                                                <span>
                                                    {NewPos || NewPos === 0
                                                        ? `$${splitByCommas((NewPos / 1000).toFixed(1))}k`
                                                        : "-"}
                                                </span>
                                                <p>
                                                    {OldPos || OldPos === 0
                                                        ? `$${splitByCommas((OldPos / 1000).toFixed(1))}k`
                                                        : "-"}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row_item">
                                            <div className="block_row p_with_opacity">
                                                <span>{NewLev || NewLev === 0 ? `${roundNumber(NewLev)}x` : "-"}</span>
                                                <p>{OldLev || OldLev === 0 ? `${roundNumber(OldLev)}x` : "-"}</p>
                                            </div>
                                        </div>
                                        <div className="row_item">
                                            {Target_lev || Target_lev === 0 ? `${roundNumber(Target_lev)}x` : "-"}
                                        </div>
                                        <div className="row_item">
                                            <div className="block_row p_with_opacity">
                                                <span>{formatPrice(CurrentPrice, trader_creation_info.symbol)}</span>
                                                <p>{pnl || pnl === 0 ? `${roundNumberToFixedOne(pnl * 100)}%` : "-"}</p>
                                            </div>
                                        </div>
                                        <div className="row_item">
                                            <div className="block_row p_with_opacity">
                                                <span>
                                                    {CurrentBTC || CurrentBTC === 0 ? CurrentBTC.toFixed(4) : "-"}
                                                </span>
                                                <p>
                                                    {CurrentUSD || CurrentUSD === 0
                                                        ? `$${splitByCommas((CurrentUSD / 1000).toFixed(1))}k`
                                                        : "-"}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row_item">
                                            {CurrentLev || CurrentLev === 0 ? `${roundNumber(CurrentLev)}x` : "-"}
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div>
                {pages > 1 && (
                    <Pagination
                        forcePage={activePage}
                        pageCount={pages}
                        onChange={e => {
                            this.setState({ activePage: e.selected + 1 });
                            window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                    />
                )}
            </div>
        );
    }
}

function mapStateToProps({ trk }) {
    return {
        trader_details: trk.trader_details,
        trader_creation_info: trk.trader_creation_info
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getTRKTraderDetails
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TraderDetails));
