import * as types from "./constants";

export function postLogin(data) {
    return {
        type: types.LOGIN,
        payload: {
            client: "default",
            request: {
                url: `/login/`,
                method: "post",
                data
            }
        }
    };
}

export function postLogout() {
    return {
        type: types.POST_LOGOUT,
        payload: {
            client: "default",
            request: {
                url: `/logout/`,
                method: "post"
            }
        }
    };
}
