import * as types from "./constants";

export function getMTraders() {
    return {
        type: types.GET_M_TRADERS,
        payload: {
            client: "default",
            request: {
                url: "/m/traders/",
                method: "get"
            }
        }
    };
}

export function postStopMTraders() {
    return {
        type: types.POST_STOP_M_TRADERS,
        payload: {
            client: "default",
            request: {
                url: "/m/traders/stop/",
                method: "post"
            }
        }
    };
}

export function createMTrader(data) {
    return {
        type: types.POST_CREATE_M_TRADER,
        payload: {
            client: "default",
            request: {
                url: `/m/traders/`,
                method: "post",
                data
            }
        }
    };
}

export const getMTrader = id => {
    return {
        type: types.GET_M_TRADER,
        payload: {
            client: "default",
            request: {
                url: `/m/trader/${id}/`,
                method: "get"
            }
        }
    };
};

export const stopMTrader = id => {
    return {
        type: types.STOP_M_TRADER,
        payload: {
            client: "default",
            request: {
                url: `/m/trader/${id}/stop/`,
                method: "post"
            }
        }
    };
};

export const startMTrader = id => {
    return {
        type: types.START_M_TRADER,
        payload: {
            client: "default",
            request: {
                url: `/m/trader/${id}/start/`,
                method: "post"
            }
        }
    };
};

export const patchMTrader = (id, data) => {
    return {
        type: types.PATCH_M_TRADER,
        payload: {
            client: "default",
            request: {
                url: `/m/trader/${id}/`,
                method: "patch",
                data
            }
        }
    };
};
