import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import ErrorIcon from "@material-ui/icons/Error";
import "./RenderField.scss";

class RenderField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: props.type
        };
    }

    changeType = () => {
        this.setState(({ type }) => ({
            type: type
        }));
    };

    updateTextField(event, value) {
        if (value.length <= 10) {
            //Update your state
        } else {
            //Value length is biggest than 12
        }
    }

    render() {
        const {
            input,
            placeholder,
            label,
            password,
            isDisabled,
            autoFocus,
            symbol,
            defaultValue,
            step,
            placement = "end",
            meta: { touched, error }
        } = this.props;
        const { type } = this.state;
        return (
            <TextField
                {...input}
                type={type}
                label={label}
                variant="outlined"
                disabled={isDisabled}
                step={step}
                error={touched && !!error}
                placeholder={placeholder}
                autoComplete="off"
                autoFocus={autoFocus}
                value={defaultValue ? defaultValue : input.value}
                classes={{
                    root: "custom_input_wrapper"
                }}
                InputProps={{
                    startAdornment:
                        symbol !== undefined && placement === "start" ? (
                            <InputAdornment position="start">
                                <span>{symbol}</span>
                            </InputAdornment>
                        ) : (
                            ""
                        ),
                    endAdornment: (
                        <InputAdornment position="end">
                            {password ? (
                                <IconButton
                                    onClick={this.changeType}
                                    classes={{
                                        root: "password_type_btn"
                                    }}
                                ></IconButton>
                            ) : (
                                ""
                            )}
                            {touched && !!error ? <ErrorIcon /> : ""}
                        </InputAdornment>
                    ),
                    classes: {
                        root: "custom_input",
                        focused: "custom_input_focused",
                        disabled: "custom_input_disabled",
                        error: "custom_input_error",
                        adornedEnd: "custom_input_adorned_end",
                        adornedStart: "custom_input_adorned_start",
                        notchedOutline: "custom_input_outline"
                    },
                    inputProps: { step: 1, maxLength: 40 }
                }}
                InputLabelProps={{
                    classes: {
                        root: "custom_input_label",
                        focused: "custom_input_label_focused",
                        shrink: "custom_input_label_active",
                        error: "custom_input_wrapper_error"
                    }
                }}
            />
        );
    }
}

export default RenderField;
