import * as types from "../actions/constants";

const INITIAL_STATE = {
    m_traders: { list: [], totals: {} },
    m_trader_info: {}
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.GET_M_TRADERS_SUCCESS:
            return { ...state, m_traders: action.payload.data };
        case types.GET_M_TRADER_SUCCESS:
            return { ...state, m_trader_info: action.payload.data };
        case types.PATCH_M_TRADER_SUCCESS:
            return { ...state, m_trader_info: { ...state.m_trader_info, ...action.payload.data } };
        default:
            return state;
    }
}
