import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

import { getMTraders, postStopMTraders } from "../../../actions/mActions";
import Loader from "../../HelperComponents/Loader/Loader";
import { MTradersTable } from "./MTradersTable/MTradersTable";
import DialogComponent from "../../HelperComponents/DialogComponent/DialogComponent";
import "../../HelperComponents/SelectComponent/SelectComponent.scss";

import add_icon from "../../../assets/image/add_icon.png";
import stop_icon from "../../../assets/image/stop_icon.png";

import "./M.scss";

class M extends Component {
    constructor(props) {
        super(props);
        const params = new URLSearchParams(decodeURIComponent(props.location.search.substring(1)));
        this.state = {
            // tab: params.get("tab") ? params.get("tab") : "a",
            sortBy: params.get("sort-by") || "",
            loading: true,
            tagsSelectorIsOpen: false,
            sortedByMTable: "trader",
            open: false
        };
    }

    componentDidMount() {
        document.title = "M";
        this.fetchMList();
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    fetchMList = () => {
        const { getMTraders } = this.props;
        getMTraders().then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                this.setState({ loading: false });
            }
        });
    };

    sortTrader = sortBy => {
        this.setState({ sortBy });
    };

    checkStatus = status => {
        switch (status) {
            case "on":
                return "status done";
            case "off":
                return "status awaiting";
            default:
                return "status canceled";
        }
    };

    toggleDialog = () => {
        this.setState(({ open }) => ({
            open: !open
        }));
    };

    stopTrade = () => {
        const { postStopMTraders } = this.props;
        postStopMTraders().then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                this.toggleDialog();
                this.fetchTrKList();
            }
        });
    };

    render() {
        const { location, m_traders } = this.props;
        const { loading, sortedByMTable, open } = this.state;
        if (loading) return <Loader />;
        return (
            <div className="content_block m_wrapper">
                <div className="title_and_tabs">
                    <div className="title">M</div>
                    <div className="menu">
                        <Link
                            to={{
                                pathname: `/main/create-m-trader`,
                                state: { previousQueries: location.search }
                            }}
                            className="add_new"
                        >
                            <img src={add_icon} alt="add_icon" />
                            Create
                        </Link>
                        <button className="stop_block" onClick={this.toggleDialog}>
                            <img src={stop_icon} alt="stop_icon" />
                            Stop all
                        </button>
                    </div>
                </div>
                <MTradersTable
                    sortMTable={() =>
                        this.setState({ sortedByMTable: sortedByMTable === "trader" ? "-trader" : "trader" })
                    }
                    sortedByMTable={sortedByMTable}
                    checkStatus={this.checkStatus}
                    m_traders={m_traders.sort((trader1, trader2) =>
                        sortedByMTable === "trader"
                            ? trader1.name.localeCompare(trader2.name)
                            : trader2.name.localeCompare(trader1.name)
                    )}
                    location={location}
                />
                <DialogComponent open={open} onClose={this.toggleDialog}>
                    <div className="m_stop_traders">
                        <div className="title">Stop all traders</div>
                        <div className="descriptions">Are you sure you want to stop all traders?</div>
                        <hr />
                        <div className="dialog_brn_wrapper">
                            <button className="btn_grey" onClick={this.toggleDialog}>
                                Cancel
                            </button>
                            <button className="btn_blue" onClick={this.stopTrade}>
                                Stop
                            </button>
                        </div>
                    </div>
                </DialogComponent>
            </div>
        );
    }
}

const mapStateToProps = ({ m }) => {
    return {
        m_traders: m.m_traders
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getMTraders,
            postStopMTraders
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(M);
