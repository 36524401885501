import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { getTRKTraderCreationInfo } from "../../../../actions/trkActions";

import CreationInfo from "../../../TrKTraderBlocks/CreationInfo/CreationInfo";
import TraderDetails from "../../../TrKTraderBlocks/TraderDetails/TraderDetails";

import back_arrow from "../../../../assets/image/back_arrow.png";

import "./MTrkTrader.scss";

class MTrkTrader extends Component {
    constructor(props) {
        super(props);
        const params = new URLSearchParams(decodeURIComponent(props.location.search.substring(1)));
        this.state = {
            tab: params.get("tab") === "creation-info" ? "creation-info" : "trader-details",
            isEdit: false,
            trader_name: "",
            previousQueries: props.location.state ? props.location.state.previousQueries : ""
        };
    }

    componentDidMount() {
        const {
            getTRKTraderCreationInfo,
            match: { params },
            history,
            location
        } = this.props;
        getTRKTraderCreationInfo(params.id).then(res => {
            if (res && res.payload) {
                document.title = `${res.payload.data.name}`;
                this.setState({ trader_name: res.payload.data.name });
            } else {
                history.push(`/main/m${location.state ? location.state.previousQueries : ""}`);
            }
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.search !== this.props.location.search) {
            if (prevState.tab === this.state.tab) {
                const params = new URLSearchParams(decodeURIComponent(this.props.location.search.substring(1)));
                this.setState({ tab: params.get("tab") || "trader-details" });
            }
        }
    }

    toggleEdit = isEdit => this.setState({ isEdit });

    changeTab = tab => {
        const {
            history,
            match: { params },
            location
        } = this.props;
        const page = new URLSearchParams(decodeURIComponent(location.search.substring(1))).get("page");
        this.setState({ tab });
        history.push(`/main/m-trk-trader/${params.id}?tab=${tab}${page ? `&page=${page}` : ""}`);
    };

    render() {
        const { tab, isEdit, trader_name, previousQueries } = this.state;
        return (
            <div className="content_block m_trader_wrapper">
                <Link to={`/main/m${previousQueries}`} className="go_back">
                    <img src={back_arrow} alt="back_arrow" />
                    back
                </Link>
                {trader_name ? (
                    <div className="title">{!isEdit ? trader_name : "Edit trader"}</div>
                ) : (
                    <div className="title">
                        <div className="loading_word">Loading</div>
                    </div>
                )}
                {!isEdit ? (
                    <div className="tab_item_trader">
                        <button
                            className={tab === "trader-details" ? "active" : ""}
                            onClick={() => this.changeTab("trader-details")}
                        >
                            Trader details
                        </button>
                        <button
                            className={tab === "creation-info" ? "active" : ""}
                            onClick={() => this.changeTab("creation-info")}
                        >
                            Creation info
                        </button>
                    </div>
                ) : null}

                {tab === "trader-details" && <TraderDetails />}
                {tab === "creation-info" && <CreationInfo toggleEdit={this.toggleEdit} />}
            </div>
        );
    }
}

const mapDispatchToProps = {
    getTRKTraderCreationInfo
};

export default connect(null, mapDispatchToProps)(MTrkTrader);
